import { AutoComplete, AutoCompleteCloseEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { observer } from 'mobx-react-lite'
import { cloneElement, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useStore } from '../../app/stores/store';

interface PolicySearchBarProps {
    placeholder: string,            // this is the search box placeholder
    suggestedField?: string,        // the field shown in the suggestion box, defaults to memberName
    searchOnInit?: boolean,         // set to true if you need to show initial list
    focusOnLoad?: boolean,
    disableSuggestion?: boolean
}

const PolicySearchBar = ({placeholder, ...props}: PolicySearchBarProps) => {
    const {searchPolicyStore} = useStore();
    const {initSearch, resetSearch, suggestionList, searchSuggestion, getDataGridResults} = searchPolicyStore;
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const searchBar = useRef<AutoComplete>(null);

    useEffect(() => {
        if(props.focusOnLoad && !!searchBar.current) {
            if(searchBar.current) searchBar.current.focus();
        }

        if(props.searchOnInit) {
            initSearch();
        }

        return () => {
            resetSearch()
        }

    }, [initSearch, props.focusOnLoad, props.searchOnInit, resetSearch])

    const onChange = async (event: any) => {
        const inputValue = event.value;
        setSearchKeyword(inputValue);
        
        if(event.syntheticEvent.type === 'click' ||
         (event.syntheticEvent.type === 'keydown' && event.syntheticEvent.keyCode === 13)){
            setShowSuggestion(false);
            await getDataGridResults(inputValue);
        }
    
        if(event.syntheticEvent.type === 'change'){
            // only trigger search when keyword length is > 2 
            // or keyword length === 0
            if( inputValue.length >= 2 ){
                setIsLoading(true);
                
                if(!props.disableSuggestion) {
                    await searchSuggestion(inputValue).then(()=>{
                        setIsLoading(false);
                        
                        if(inputValue.length >= 2){
                            setShowSuggestion(true);
                        }
                    })
                }else{
                    await getDataGridResults(inputValue).then(()=>{
                        setIsLoading(false);
                    })
                }
            }

            if(inputValue.length === 0){
                initSearch();
            }
        }
    }

    const onClose = (event: AutoCompleteCloseEvent) => {
        setShowSuggestion(false)
    }

    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
          <div className="autocomplete-suggestion group-suggestion w-100 ps-3 pe-3 pt-1 pb-1">
            {li.props.children} 
                <small className='text-muted ms-2'>
                    {itemProps.dataItem.policyNumber}
                </small>
          </div>
        );

        const datasetAttr = {
            ...li.props,
            'data-groupid': itemProps.dataItem.id,
            'data-groupname': itemProps.dataItem.groupName,
            'data-policynumber': itemProps.dataItem.policyNumber
        }
    
        return cloneElement(li, datasetAttr, itemChildren);
    };

    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        return cloneElement(element, { ...element.props }, 'No results found');
    };
    
    return (
        <>
            <Row className='mt-4'>
                <Col>
                    <AutoComplete
                        ref={searchBar}
                        data={suggestionList}
                        textField='groupName'
                        onChange={onChange}
                        onClose={onClose}
                        placeholder={placeholder}
                        value={searchKeyword}
                        opened={ !props.disableSuggestion && showSuggestion}
                        loading={isLoading}
                        itemRender={itemRender}
                        listNoDataRender={listNoDataRender}
                        popupSettings={{
                            className: 'autocomplete-suggestions'
                        }}
                        className="form-control form-control-lg w-100 search-box"
                    />
                </Col>
            </Row>
        </>
    )
}

export default observer(PolicySearchBar)