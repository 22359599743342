import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { memberSearchResult } from '../../../models/memberSearch';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Skeleton } from '@progress/kendo-react-indicators';

interface MemberSearchDataGridProps {
    results?: memberSearchResult[],
    sort: SortDescriptor[],
    sortHandler: (e: GridSortChangeEvent) => void
}

const MemberTopLevelSearchDataGrid = ({results, sort, sortHandler}: MemberSearchDataGridProps) => {
    const history = useHistory();

    function handleRowClick(event: any) {
        history.push(`/member/${event.dataItem.memberInfo.id}`);
    }

    const parsedData = results?.map((m) => {
        const parsedMemberInfo = {...m.memberInfo, 
            dob: new Date(m.memberInfo.dob),
            joinedDate: new Date(m.memberInfo.joinedDate)
        }
        const parsedRow = {...m, 
                memberInfo: parsedMemberInfo
            }
        return parsedRow;
    });

    // TODO: Implement columnInterface
    return ( 
        <>
        <Grid onRowClick={handleRowClick} 
            data={ orderBy(parsedData || [], sort)}
            sortable={true}
            sort={sort}
            onSortChange={sortHandler}>
            <Column field="memberInfo.policyNumber" title="Policy No." />
            <Column field="memberInfo.employeeID" title="Employee ID" />
            <Column field="memberInfo.name" title="Name"  width='300'/>
            <Column field="memberInfo.dob" title="DOB" filter="date" format="{0:dd/MM/yyyy}"/>
            <Column field="memberInfo.joinedDate" title="Joined" filter="date" format="{0:dd/MM/yyyy}" />
            <Column field="memberInfo.productType" title="Type"/>
            <Column field="groupMasterInfo.name" title="Group"   width='300' />
            <GridNoRecords >
                { (!results) 
                    ?   <div className='text-start'>
                            <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                            
                        </div>
                    : 'No results found.' }
            </GridNoRecords>
        </Grid>
    </>
    );
}


export default observer(MemberTopLevelSearchDataGrid)