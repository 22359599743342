import { Skeleton } from '@progress/kendo-react-indicators'
import React from 'react'
import { Accordion } from 'react-bootstrap'
import { PolicyDetails } from '../../models/policyHeader'
import PolicyCategoryDetails from '../policies/PolicyCategoryDetails'

interface PolicyAccordionItemProps {
    policy?: PolicyDetails,
    eventKey?: string
}
const PolicyAccordionItem = ({policy, eventKey}: PolicyAccordionItemProps) => {
    const itemKey = (eventKey) ? eventKey : `${policy?.coverType}-${policy?.categoryNo}`

    return (
        <Accordion.Item eventKey={itemKey}>
            <Accordion.Header>
            { !policy && 
                <Skeleton shape={"text"} style={{ width: "35%", height:"33px" }} />
            }
            { policy && 
                <h3 className='mb-0'>{policy.coverType} - Category {policy.categoryNo} </h3>
            }
            </Accordion.Header>
            <Accordion.Body>
                <PolicyCategoryDetails policy={policy}/>
            </Accordion.Body>
        </Accordion.Item>
    ) 
}

export default PolicyAccordionItem