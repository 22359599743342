import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import ActionButton from "../../app/common/form/ActionButton";
import { useStore } from "../../app/stores/store";
import { IdTypes, SubLinks } from "../../app/stores/subMenuStore";
import { UserHasPermission } from "../../app/utils/utils";
import { PolicyHeaderFiles } from "../../models/files";
import FileListAccordion from "./FileListAccordion";
import {Helmet} from 'react-helmet'

function PolicyHeaderFilesComponent(){ 
    const {subMenuStore, policyHeaderStore, userStore} = useStore();
    const {policyHeaderGuid} = useParams<{policyHeaderGuid: string}>();
    const {getFiles} = policyHeaderStore;
    const {userClaims} = userStore;
    const [policyHeaderFiles, setPolicyHeaderFiles] = useState<PolicyHeaderFiles>();
    const isBasicUser = UserHasPermission(['CerteBasicUser'], userClaims?.role);

    const getPolicyFiles = useCallback(async () => {
                const data = await getFiles(policyHeaderGuid);
                setPolicyHeaderFiles(data);
            }, [getFiles, policyHeaderGuid]);
   
    useEffect(() => {
            // show submenu 
            subMenuStore.initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
            subMenuStore.setActivelink(SubLinks.files);
            getPolicyFiles()
      
        return () => {
        
        }
    }, [getPolicyFiles, policyHeaderGuid, subMenuStore])

    const history = useHistory();
    const handleUpload = () => {
        history.push(`/policy-headers/${policyHeaderGuid}/upload`);
    }

    return (
        <>
            <Helmet title={`Policy files - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`}/>
            <Row className="mb-4">
                <Col>
                    <h1 className='header-title'>Policy files</h1>
                </Col>
            </Row>
            <Row >
                <Col className="col-8 m-0">
                    {isBasicUser ? '' 
                    : <>
                        <FileListAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={'Policy'} fileList={policyHeaderFiles?.policyFiles}/>
                        <FileListAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={'Review reports'} fileList={policyHeaderFiles?.reviewReportsFiles} />
                        <FileListAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={'Review data'} fileList={policyHeaderFiles?.reviewDataFiles}/>
                        <FileListAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={'Claim brochures & forms'} fileList={policyHeaderFiles?.claimsFiles} />
                    </>}
                    <FileListAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={'Member movement data'} fileList={policyHeaderFiles?.memberMovementDataFiles} />
                </Col>
                <Col className="col-4">
                    <Row className="bg-white m-0 border rounded">
                        <Col className="col-10 p-3">
                            <h3 className="mb-0 p-1">Upload file</h3>
                        </Col>
                        <Col className="col-2 p-0 border-start">
                            <ActionButton icon="icon-upload" clickHandler={handleUpload} />
                        </Col>
                    </Row>

                    {!isBasicUser && <Row className="mt-4">
                        <Col>
                            <ListGroup>
                                <ListGroup.Item className='p-4'>
                                    <h3 className='m-0'>Information</h3>
                                </ListGroup.Item>
                    
                                <ListGroup.Item className='p-4'>
                                    <strong className="text-muted">Accordions</strong>
                                    <p className='m-0 text-muted'>Files on this page are grouped into the following accordions: Policy, Review reports, Review data, Claims and Movement data.</p>
                                </ListGroup.Item>

                                <ListGroup.Item className='p-4'>
                                    <strong className="text-muted">Data collection Insurer Request</strong>
                                    <p className='m-0 text-muted'>A data request in Excel, which accompanied with an email that would have been issued to you, in order to commence the renewal process of your group policy. On receipt of the email, please kindly update in the Excel as per instruction included and upload the completed file when it is ready.</p>
                                </ListGroup.Item>

                                <ListGroup.Item className='p-4'>
                                    <strong className="text-muted">Data query Insurer Request</strong>
                                    <p className='m-0 text-muted'>A data clarification request in Excel, which accompanied with an email that would have been issued to you, during the renewal process of your policy. On receipt of the email, please kindly update in the Excel as per instruction included and upload the completed file when all questions are answered.</p>
                                </ListGroup.Item>

                                <ListGroup.Item className='p-4'>
                                    <strong className="text-muted">Movement data template</strong>
                                    <p className='m-0 text-muted'>An Excel template for ad hoc member movement data between annual review periods. Instruction on how to add new members and/or remove members are included inside the template.</p>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </>
    )
}


export default observer(PolicyHeaderFilesComponent)