import { makeAutoObservable } from "mobx";

export enum NoticeStates {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
    none = 'none'
}

export interface Notice {
  noticeState: NoticeStates | undefined,
  message: string,
  errors?: string[],
  statusCode?: number,
  flashMessage?: boolean
}

export default class NoticeStore {
    closable: boolean = true;
    notice: Notice | null = null;
    flashMessage: boolean = false;
    timeoutDelay: number = 5000;

    constructor(){
        makeAutoObservable(this);
    }

    setNotice = (notice: Notice | null ) => {
        this.notice = notice;
    }

    handleClose = () => {
        this.notice = null;
    }

    setFlashMessage = (status: boolean) => {
        this.flashMessage = status;
    }

    setTimeoutDelay = (delay: number) => {
        this.timeoutDelay = delay;
    }

    closeFlashMessage = () => {
        setTimeout(() => {
            this.handleClose();
        }, this.timeoutDelay)
    }
}