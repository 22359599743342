import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { InView } from "react-intersection-observer";
import { useHistory, useParams } from "react-router-dom";
import ActionButton from "../../app/common/form/ActionButton";
import { useStore } from "../../app/stores/store";
import { IdTypes, SubLinks } from "../../app/stores/subMenuStore";
import ClaimsMemberSearchBar from "./ClaimsSearchBar";
import ClaimsSearchDatagrid from "./ClaimsSearchDatagrid";
import plur from 'plur';
import agent from "../../app/api/agent";
import fileDownload from "js-file-download";
import { HashLink } from 'react-router-hash-link';
import {Helmet} from 'react-helmet'

interface PolicyHeaderSearchClaimsUrlParams {
    policyHeaderGuid: string
}

const ClaimsSearchComponent = () => {
    const {subMenuStore, userStore, searchClaimStore} = useStore();
    const history = useHistory();
    const {policyHeaderGuid} = useParams<PolicyHeaderSearchClaimsUrlParams>();
    const {groupMasterId} = userStore;
    const {results, pagination, currentPage, loadMore, resetSearch} = searchClaimStore;

    useEffect(() => {
        // show submenu 
        subMenuStore.initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        subMenuStore.setActivelink(SubLinks.claims);
        return () => {
            //subMenuStore.resetPolicyHeader()
            resetSearch()
        }
    }, [policyHeaderGuid, subMenuStore, resetSearch])

    const handleLoadMore = () => {
        if(pagination && currentPage < pagination.totalPages){
            loadMore();
        }
    }

    const handleAddClaim = () => {
        // check user role
        history.push(`/policy-headers/${policyHeaderGuid}/claims/new`);
    }

    const handleExportAll = () => {
        agent.DownloadReports.policyHeaderClaims(policyHeaderGuid).then((blob: Blob) => {
            fileDownload(blob, `${subMenuStore.policyHeader?.policyNumber}-policy-claims.xlsx`);
        });
    }
    
    return (
        <>
            <Helmet title={`Policy claims - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`}/>
            <Row className="mt-4 mb-4">
                <Col>
                    <h1 className='header-title'>Policy claims</h1>
                </Col>
                <Col className='text-end'>
                    {groupMasterId === '0' && <HashLink className="btn me-2 btn-outline-black-border-yellow-bg" to={`/policy-headers/${policyHeaderGuid}/files#claims-accordion`}><i className='icon icon-file' /> Claims Forms</HashLink>}
                    <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={handleExportAll}> <i className='icon icon-download' /> Export all</Button>
                </Col>
            </Row>
            
            <ClaimsMemberSearchBar placeholder="Search claims" policyHeader={policyHeaderGuid} />
            
            <Row className='mt-5 border m-0'>
                <Col className='col-lg-11 col-md-10 p-4 align-middle '>
                    <h2 className='m-0'>Claims</h2>
                </Col>
                <Col className='col-lg-1 col-md-2 p-0 border-start'>
                    <ActionButton clickHandler={handleAddClaim} icon="icon-plus" />
                </Col>
            </Row>
            <Row >
                <Col>
                    <ClaimsSearchDatagrid results={results} />
                </Col>
            </Row>              

            { results &&  
                <Row className='mt-4'>
                    <Col className='text-end'>
                       {pagination?.totalResults} { plur('Result', pagination?.totalResults) }
                    </Col>
                </Row>
            }
                
            {pagination && results && pagination.pageSize <= results.length &&
            <Row>
                <Col className='text-center mt-4'>
                    
                    <InView as="div" onChange={handleLoadMore}>
                        { currentPage < pagination.totalPages  && 
                         <span className='text-muted'>loading...</span>
                        }
                    </InView>
                </Col>
            </Row>
            }
        </>
    )
}

export default observer(ClaimsSearchComponent);