import { makeAutoObservable, observable } from "mobx";
import {ClaimDetails} from "../../models/claim";
import Member, { MemberDetails } from "../../models/member";
import Note from "../../models/note";
import { UnderwritingDecision } from "../../models/underwritingDecisions";
import agent from "../api/agent";

export default class MemberStore {
    member?: MemberDetails;
    claims?: ClaimDetails[] = [];
    notes?: Note[] = [];
    underwritingDecisions?: UnderwritingDecision[] = []; 

    searchFilter: string | null = null;
    searchResults: Member[] = [];
 
    isLoading: boolean = false;
    
    constructor(){
        makeAutoObservable(this, {
            member : observable,
            notes : observable,
            claims : observable,
            underwritingDecisions : observable
        });
    }

    setSearchFilter = (filter: string) => this.searchFilter = filter;
    setSearchResults = (result: Member[]) => this.searchResults = result;

    getMemberDetails = async (memberId: string) => {
        return await agent.Members.details(memberId);
    }

    setMember = (member?: MemberDetails) => {
        this.member = member;
    }

    /** Methods below should have a backend validation. Validate user role before processing the request.  */
    memberClaims = async (memberId: string) => {
        return await agent.Claims.getByMemberId(memberId);
    }

    policyHeaderClaims = async (policyHeaderId: string) => {
        return await agent.Claims.getByPolicyHeaderId(policyHeaderId);
    }

    setClaims(claims: ClaimDetails[]){
        this.claims = claims;
    }
   
    getMemberNotes = async (memberId: string) => {
        return await agent.Notes.getMemberNotes(memberId);
    }

    setNotes(claims: Note[]){
        this.notes = claims;
    }

    getMemberUnderwritingDecisions = async (memberId: string) => {
        return await agent.UnderwritingDecisions.getMemberUnderwritingDecision(memberId);
    }

    setUnderwritingDecisions(claims: UnderwritingDecision[]){
        this.underwritingDecisions = claims;
    }

    downloadStatement = async (memberId: string) => {
        return await agent.DownloadReports.memberPolicyStatement(memberId);
    }
}