import { makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { DataGridSorting, MemberDataGridColumn, memberSearchResponse, memberSearchResult } from "../../models/memberSearch";
import { Pagination } from "../../models/pagination";
import agent from "../api/agent";

export default class SearchMemberStore {
    /** Filters */
    keyword?: string = '';
    policyHeaderId?: string;
    reviewDate?: Date;
    currentPage: number = 1;
    sortField: MemberDataGridColumn = MemberDataGridColumn.MemberName;
    sortOrder: DataGridSorting = DataGridSorting.Ascending;

    /** Response */
    response?: memberSearchResponse;
    results?: memberSearchResult[];
    pagination?: Pagination;

    /** Rendering component */
    suggestionList?: memberSearchResult[];
    dataGrid?: memberSearchResult[];

    /** Current State */
    suggestions?: memberSearchResult[];
    selectedMember?: memberSearchResult;

    /** Axios abort controller */
    abortController?: AbortController;
    
    constructor(){
        makeAutoObservable(this, {
            keyword: observable,
            policyHeaderId: observable,
            reviewDate: observable,
            response: observable,
            results: observable,
            pagination: observable
        });

    }

    private setResponse = (response?: memberSearchResponse) => {
        this.response = response
    }

    private setResults = (results?: memberSearchResult[]) => {
        this.results = results;
    }

    setSuggetstionList = (response?: memberSearchResult[]) => {
        this.suggestionList = response;
    }

    setDataGrid = (response?: memberSearchResult[]) => {
        this.dataGrid = response;
    }

    private prepResultData = (resultData: memberSearchResult[]) => {
        return resultData.map((m) => {
                            const parsedRow = {...m, 
                                    memberInfo: {
                                        ...m.memberInfo,
                                        dob: moment(`${m.memberInfo.dob}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate(),
                                        joinedDate: moment(`${m.memberInfo.joinedDate}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate(),
                                        reviewDate: moment(`${m.memberInfo.reviewDate}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate(),
                                        startDate: moment(`${m.memberInfo.startDate}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate()
                                    }
                                }
                            return parsedRow;
                        });
    }

    private appendResults = (results?: memberSearchResult[]) => {
        this.results = [...this.results!, ...results!];
    }

    private appendDataGrid = (results?: memberSearchResult[]) => {
        this.dataGrid = [...this.dataGrid!, ...results!];
    }

    private setPagination = (pagination?: Pagination) => {
        this.pagination = pagination;
    }

    setKeyword = (keyword: string) => {
        this.keyword = keyword;
    }

    setSuggestions = (suggestions: memberSearchResult[]) => {
        this.suggestions = suggestions;
    }

    setPolicyHeaderId = (policyHeaderId?: string) => {
        this.policyHeaderId = policyHeaderId;
    }

    setReviewDate = (reviewDate?: Date) => {
        this.reviewDate = reviewDate;
    }

    setCurrentPage = (pageNumber: number) => {
        this.currentPage = pageNumber;
    } 

    setSelectedMember = (member?: memberSearchResult) => {
        this.selectedMember = member;
    }

    setSortField = (field?: MemberDataGridColumn) =>{
        this.sortField = field || MemberDataGridColumn.MemberName;
    }

    setSortOrder = (order?: DataGridSorting ) =>{
        this.sortOrder = order || DataGridSorting.Ascending;
    }

    /**
     * Search Member - on search, set search filters
     * @param keyword string - member name
     * @param currentPage number - page number
     * @param policyHeaderId GUID - policy header Guid
     * @param reviewDate Date - Policy Header Start Date
     * @returns MemberSearchResponse - this contains pagination and result object.
     */

    // TODO: implement named parameters
    search = async (keyword?: string, currentPage?: number, policyHeaderId?: string, reviewDate?: Date, sortField?: MemberDataGridColumn, sortOrder?: DataGridSorting ) => {

        this.abortController = new AbortController();
        
        const tokenParam = {  
            signal: this.abortController.signal
        }

        this.setResults(undefined); // reset to prevent wonky suggestion box
        this.keyword = keyword || '';
        this.currentPage = currentPage || 1;
        this.policyHeaderId = policyHeaderId || '';
        this.reviewDate = reviewDate;
        this.sortField = sortField || MemberDataGridColumn.MemberName;
        this.sortOrder = sortOrder || DataGridSorting.Ascending;

        return await agent.Search.member(this.keyword, this.currentPage, this.policyHeaderId , this.reviewDate, this.sortField, this.sortOrder, tokenParam);
    } 

    searchSuggestion = async (keyword?: string) => {
        this.abortController?.abort();
        return await this.search(keyword, 1, this.policyHeaderId, this.reviewDate).then(response => {
            this.setSuggetstionList(response.results);
            return this.suggestionList;
        })
    }

    getDataGridResults = async (keyword?: string, pageNumber?: number, policyHeaderId?: string, reviewDate?: Date) => {
        this.abortController?.abort();

        return await this.search(keyword, pageNumber, policyHeaderId, reviewDate, this.sortField, this.sortOrder).then(response => {

            if(response && response?.pagination?.currentPage > 1){
                this.appendDataGrid(this.prepResultData(response.results));
            }else{
                this.setDataGrid(this.prepResultData(response.results));
            }

            this.setPagination(response?.pagination);
            return this.dataGrid;
        })
    }


    loadMore = async () => {
        this.currentPage += 1;
        return await this.getDataGridResults(this.keyword, this.currentPage, this.policyHeaderId , this.reviewDate); 
    }

    initSearch = (keyword?: string, policyHeaderId?: string, reviewDate?: Date) => {
        this.getDataGridResults(keyword || '', 1, policyHeaderId, reviewDate);
    }

    sortSearch = async (field?: MemberDataGridColumn, order?: DataGridSorting) => {
        this.setDataGrid();
        this.setSortField(field || MemberDataGridColumn.MemberName);
        this.setSortOrder(order || DataGridSorting.Ascending);

        return await this.search(this.keyword, 1, this.policyHeaderId, this.reviewDate, field, order).then(response => {
            this.setDataGrid(this.prepResultData(response.results));
            this.setPagination(response?.pagination);
            return this.dataGrid;
        })
    }

    resetSearch = () => {
        this.setCurrentPage(1);
        this.setKeyword('');
        this.setResponse();
        this.setPolicyHeaderId();
        this.setReviewDate();
        this.setPagination();
        this.setSuggetstionList();
        this.setDataGrid();
        this.setSortField();
        this.setSortOrder();
        this.setSelectedMember();
    }
}