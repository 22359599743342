import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import agent from "../../app/api/agent";
import LoaderComponent from "../../app/layout/LoaderComponent";
import CardSummary, { CardSummaryProps } from "./CardSummary";
import { Stats } from "../../models/stats";
import ReRate from "../../models/reRate";
import ReRateDataGrid from "./ReRateDataGrid";
import axios from "axios";
import { DashboardMessage } from "../../models/dashboardMessage";
import AlertMessages from "./AlertMessages";
import { useStore } from "../../app/stores/store";
import fileDownload from "js-file-download";
import { useHistory } from "react-router-dom";
import { UserHasPermission } from "../../app/utils/utils";
import { Helmet } from "react-helmet";
import { AxiosError } from "axios";

export default function DashboardComponent() {
    const { policyHeaderStore, userStore, subMenuStore, groupMasterStore } = useStore();
    const { getFollowUpReminder, getFollowUpList, getUpcomingRerates } = policyHeaderStore;
    const { groupMasterId, userClaims } = userStore;
    const { resetPolicyHeader } = subMenuStore;
    const [dashboardMessages, setDashboardMessages] = useState<DashboardMessage[]>();
    const [policyStats, setPolicyStats] = useState<CardSummaryProps>();
    const [memberStats, setMemberStats] = useState<CardSummaryProps>();
    const [claimsStats, setClaimsStats] = useState<CardSummaryProps>();
    const [reRatesData, setReRatesData] = useState<ReRate[]>();
    const history = useHistory();
    const {groupMaster, getGroupMasterDetails} = groupMasterStore;
    const [isBasicUser, setBasicUser] = useState(false);
    const accessLevel = userClaims?.accessLevelId;

    const handleClaimsReportDownload = () => {
        if(groupMasterId){
            let GMId = (groupMasterId === '0') ? '00000000-0000-0000-0000-000000000000' : groupMasterId; 
            agent.DownloadReports.groupClaimsReport(GMId).then((blob: Blob) => {
                fileDownload(blob, `claims-report.xlsx`);
            });
        }
    }

    useEffect(() => {
        resetPolicyHeader();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setBasicUser(UserHasPermission(['CerteBasicUser'], userClaims?.role));
        // Basic User Redirect from Dashboard(landing page) to Files Page
        if(isBasicUser && groupMasterId) {
            getGroupMasterDetails(groupMasterId).catch((err: AxiosError) => {
                if(err.response?.status === 400) history.push("/error/not-found");
                if(err.response?.status === 403) history.push("/error/forbidden");
            })
            
            const phGUID = groupMaster?.activePolicyHeaders[0].policyHeaderGuid;
            phGUID && history.push('/policy-headers/'+phGUID+'/files')       
        }

        if(UserHasPermission(['PortalAdmin'], userClaims?.role)) {
            // Follow up 
            getFollowUpReminder().then( (messages: DashboardMessage[]) => {
                setDashboardMessages(messages);
            })
        }

        // Statistics
        agent.Statistics.list().then((data: Stats) => {
            setPolicyStats({
                icon: 'shield',
                headerTitle: 'Policies',
                cardInfoSummary: {
                    key: data.policies.subTitle,
                    value: data.policies.subValue
                }, 
                cardInfoBreakdown: data.policies.breakDown,
                buttonLabel: 'View Policies',
                buttonOnClick: () => {
                    if(groupMasterId === '0'){
                        history.push('/policies/search')
                    }else{
                        history.push('/group-master/'+groupMasterId)
                    }
                }
            })

            setMemberStats({
                icon: 'inward-arrow',
                headerTitle: 'Members',
                cardInfoSummary: {
                    key: data.members.subTitle,
                    value: data.members.subValue
                }, 
                cardInfoBreakdown:  data.members.breakDown,
                buttonLabel: 'View Members',
                buttonLink: '/members/search'
            })

            setClaimsStats({
                icon: 'heart',
                headerTitle: 'Claims',
                cardInfoSummary: {
                    key: data.claims.subTitle,
                    value: data.claims.subValue
                }, 
                cardInfoBreakdown: data.claims.breakDown,
                // buttonLabel: accessLevel === 0 ? 'All Claims Report' : '_',
                buttonLabel: 'All Claims Report',
                buttonIcon: 'icon-download',
                buttonOnClick: accessLevel === 0 ? handleClaimsReportDownload : undefined
            })
        })

        // Re-rates
        getUpcomingRerates().then((data: ReRate[]) => {
            const reRateData = data.map((p) => {
                const parsedRow = {...p, reRateDate: new Date(p.reRateDate) }
                return parsedRow;
            })
            setReRatesData(reRateData);
        })

        return () => {
            //console.log("cleaning");
            source.cancel();
        };
    }, [getFollowUpReminder, getFollowUpList, getUpcomingRerates, groupMasterId, resetPolicyHeader, history, userClaims?.role,
        getGroupMasterDetails, groupMaster, isBasicUser, accessLevel, 
    ])


    return (
        isBasicUser ? <LoaderComponent content='Loading application...' /> 
        : <>
            <Helmet titleTemplate="Certe Portal | %s" title="Dashboard" />
            <Row className="mb-4 mt-4">
                <Col>
                    <h1 className="header-title">Dashboard</h1>
                    <AlertMessages messages={dashboardMessages} />
                </Col>
            </Row>
            
            <Row className="align-items-stretch mb-4">
                <Col className="col-12 col-md-4">
                    <CardSummary {...policyStats}  /> 
                </Col>
                <Col className="col-12 col-md-4">
                    <CardSummary {...memberStats}  /> 
                </Col>
                <Col className="col-12 col-md-4">
                    <CardSummary {...claimsStats}  /> 
                </Col>
            </Row>  

            { !reRatesData && <LoaderComponent content="Checking upcoming re-rates..." />}
            { reRatesData &&
                <>
                <Row>
                    <Col className='mb-3'>
                        <h3>Upcoming re-rate</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <ReRateDataGrid data={reRatesData} /> 
                    </Col>
                </Row>
                </>
            }
        </>
    )
}