export default interface Note {
    id: string
    username: string
    notes: string
    createDate: Date
}

export enum NoteTypes {
    GroupMaster = 'GroupMaster',
    PolicyHeader = 'PolicyHeader',
    Member = 'Member'
}

export interface NewNote {
    username: string
    notes: string,
    notesType: NoteTypes,
    notesOf: string
}