import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Redirect } from "react-router";
import LoaderComponent from "../../app/layout/LoaderComponent";
import { useStore } from "../../app/stores/store";

const SignInCallback = () => {
    const { userStore } = useStore();
    const { user, signInRedirectCallback } = userStore;

    useEffect(() => {
        signInRedirectCallback();
    }, [signInRedirectCallback])

    return (
        <>
            <Container fluid>
                <Row className="d-flex align-items-stretch bg-white" style={{ height: "100vh" }} >
                    <Col className="d-flex align-self-center bg-light text-dark" style={{ height: "100%" }}>
                        {!user && 
                            <LoaderComponent content='Authenticating user...' />
                        }

                        {user && <Redirect to={'/dashboard'} /> }
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default observer(SignInCallback)