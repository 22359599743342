import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface InfoToolTipProps {
    id: string,
    toolTipMessage: string
} 

const InfoToolTip = ({id,toolTipMessage} : InfoToolTipProps) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={id}>
                    {toolTipMessage}
                </Tooltip>
            }
        >
        <small className='text-muted ms-1'><i className='icon icon-info'/> </small>
        </OverlayTrigger>
    );
}

export default InfoToolTip;