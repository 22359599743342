import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ToastContainer } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { usePageVisibility } from 'react-page-visibility';
import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg';
import '../../stylesheets/main.scss';

import NavMainMenu from './NavMainMenu';
import Footer from './Footer';

import DashboardComponent from '../../features/dashboard/DashboardComponent';
import MemberSearchComponent from '../../features/members/MemberSearchComponent';

import LoginComponent from '../../features/auth/LoginComponent';
import KendoComponents from '../../features/kendo-components/KendoComponents';
import NoticeComponent from '../common/notifications/NoticeComponent';
import NotFound from '../../features/errors/NotFound';
import ServerError from '../../features/errors/ServerError';
import ForgotPasswordComponent from '../../features/auth/ForgotPasswordComponent';
import DataAnalyticComponent from "../../features/static/DataAnalyticComponent";
import DisclaimerComponent from "../../features/static/DisclaimerComponent";
import TermsAndConditionComponent from "../../features/static/TermsAndConditionComponent";
import PrivacyPolicyComponent from "../../features/static/PrivacyPolicyComponent";
import FinancialServicesGuideComponent from "../../features/static/FinancialServicesGuideComponent";
import FollowUpComponent from "../../features/follow-up/FollowUpComponent";


import { Logout } from "../../features/auth/Logout";

import SignInCallback from "../../features/auth/SignInCallback";
import LogoutCallback from "../../features/auth/LogoutCallback";
import Unauthorized from "../../features/errors/Unauthorized";
import SilentRenew from "../../features/auth/SilentRenew";
import ProtectedRoute from "../common/routes/ProtectedRoute";
import SearchPolicyComponent from "../../features/policies/PolicySearchComponent";
import MemberDetailsComponent from "../../features/members/MemberDetailsComponent";
// import ClaimsSearchComponent from "../../features/claims/ClaimsSearchComponent";
// import ClaimsDetailsComponent from "../../features/claims/ClaimsDetailsComponent";
import PolicyHeaderFilesComponent from "../../features/files/PolicyHeaderFilesComponent";
import StateTest from "../../features/StateTest";
import GroupDetailsComponent from "../../features/group-master/GroupMasterDetailsComponent";
import Login from "../../features/ids/login";
import ForgotPassword from "../../features/ids/forgotPassword";
import PageNotFoundError from "../../features/errors/PageNotFoundError";
import ForbiddenError from "../../features/errors/ForbiddenError";
import PolicyHeaderDetailsComponent from "../../features/policy-header/PolicyHeaderDetailsComponent";
import PolicyMembersComponent from "../../features/policies/PolicyMembersComponent";
import UnderConstruction from "../../features/errors/UnderConstruction";
import ClaimsSearchComponent from "../../features/claims/ClaimsSearchComponent";
import NewClaimComponent from "../../features/claims/NewClaimComponent";
import ClaimsDetailsComponent from "../../features/claims/ClaimsDetailsComponent";
import FileUploadComponent from "../../features/files/FileUploadComponent";
import { useEffect, useLayoutEffect, useState } from "react";
import { useStore } from "../stores/store";
import PolicyCampaignComponent from "../../features/campaign/PolicyCampaignComponent";
import DownloadUWStatement from "../../features/public/DownloadUWStatement";
import { Helmet } from "react-helmet";
import { UserHasPermission } from "../utils/utils";

// TODO: implement lazy loading and suspense, if necessary
function useWindowWidth() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function App() {
  const location = useLocation();
  const { userStore } = useStore();
  // check if app has been loaded, show loader component while waiting 
  const { user, getUser, getUserSid, userClaims } = userStore;
  const [requireReload, setRequireReload] = useState<Boolean>(false)
  const accessLevel = userClaims?.accessLevelId;
  
  const isVisible = usePageVisibility();
  const width = useWindowWidth();
  useEffect(() => {
      if(user && isVisible && (user?.profile.sid !== getUserSid())){
          setRequireReload(true)
      }
  }, [getUser, getUserSid, isVisible, user])

  const isBasicUser = UserHasPermission(['CerteBasicUser'], userClaims?.role);

  if(requireReload) return (
    <>
      <h4 className="text-center p-5">Your session has timed out. Please <a href="#reload" onClick={() => window.location.reload()}>refresh</a> the page.</h4>;
    </>
  )

  return (width<1200 ? 
      <>
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pe-4 ps-4 text-center">
          <LogoSvG className='logo' height={80} width={200}/>
          <h3 className="mt-4 mb-4">This experience is optimised for screens larger than 1200px wide.</h3>
          <p>Please enlarge your browser window or use a larger device.</p>
        </div>
      </> :
      <>
        <Helmet titleTemplate="Certe Portal | %s" />
        <Switch>
        {/** This should be removed once the template has been integrated */}
        <Route exact path='/ids/login' component={Login}></Route>
        <Route exact path='/ids/forgot-password' component={ForgotPassword}></Route>
        
        <Route path='/state-test/:id' component={StateTest}></Route>


          {/** OIDC */}
          <Route exact={true} path="/signin-oidc" component={SignInCallback} />
          <Route exact={true} path="/logout" component={Logout} />
          <Route exact={true} path="/signout-callback-oidc" component={LogoutCallback} />
          <Route exact={true} path="/silentrenew" component={SilentRenew} />

          {/** UNAUTHENTICATED ROUTES */}
          <Route exact path='/login' component={LoginComponent}></Route>
          <Route exact path='/forgot' component={ForgotPasswordComponent}></Route>
          <Route exact path='/kendo' component={KendoComponents}></Route>
          
          <Route exact={true} path='/page-not-found' component={NotFound}></Route>
          <Route exact={true} path='/error/unauthorized' component={Unauthorized}></Route>

          {/** Public Download Page*/}
          <Route exact={true} path='/download/uw-statement/:token' component={DownloadUWStatement}></Route>
         
          {/** AUTHENTICATED ROUTES */}

          <ProtectedRoute  
            path={'(.*)'}
            render={() => (
              <div className='d-flex flex-column min-vh-100 justify-content-between'>
                <div>
                  <header className='mb-4 sticky-top'>
                    <NavMainMenu />
                  </header>

                  {/* BODY */}
                  <Container>
                    <NoticeComponent key={location.key} />
                    <Switch>
                      {/** DASHBOARD ROUTES */}
                      <Route exact path={['/dashboard','/']} component={DashboardComponent}></Route>   

                      {/** GROUP ROUTES */}
                      {!isBasicUser && <Route exact path={['/group-master', '/group-master/:groupMasterGuid']} component={GroupDetailsComponent}></Route>}

                      {/** POLICY HEADERS ROUTES */}
                      {!isBasicUser && <Route exact path={'/policy-headers'} component={GroupDetailsComponent}></Route>}
                      {!isBasicUser && <Route exact path={'/policy-headers/follow-up'} component={FollowUpComponent}></Route>}
                      {!isBasicUser && <Route key={location.key} exact path='/policy-headers/:policyHeaderGuid' component={PolicyHeaderDetailsComponent}></Route>}
                      {!isBasicUser && <Route exact path={'/policy-headers/:policyHeaderGuid/members'} component={PolicyMembersComponent}></Route>}
                      {
                      // accessLevel === 0 && 
                      <Route exact path={'/policy-headers/:policyHeaderGuid/files'} component={PolicyHeaderFilesComponent}></Route>}
                      <Route exact path={'/policy-headers/:policyHeaderGuid/upload/:actionType?'} component={FileUploadComponent}></Route>
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route exact path={'/policy-headers/:policyHeaderGuid/claims'} component={ClaimsSearchComponent}></Route>}
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route exact path={'/policy-headers/:policyHeaderGuid/claims/new/:memberGuid?'} component={NewClaimComponent}></Route>}
                     
                      {/** Campaign */}
                      {!isBasicUser && <Route exact path={'/policy-headers/:policyHeaderGuid/campaign'} component={PolicyCampaignComponent}></Route>}

                      {/** POLICY ROUTES */}
                      {!isBasicUser && <Route exact path='/policies/search' component={SearchPolicyComponent}></Route>}
                      
                      {/** MEMBER ROUTES */}
                      {!isBasicUser && <Route exact path='/members/search' component={MemberSearchComponent}></Route> } 
                      {/* <Route exact path='/member/:memberId/claims/new' component={ClaimsForm}></Route> */}
                      {!isBasicUser && <Route key={location.key} exact path='/member/:memberId' component={MemberDetailsComponent}></Route>}

                      {/** CLAIMS ROUTES */}
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route path='/claims/:claimsGuid' component={ClaimsDetailsComponent}></Route>   }                   
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route path='/claims/member/' component={UnderConstruction}></Route>}
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route exact path='/claims/search' component={ClaimsSearchComponent}></Route>}
                      {!isBasicUser && 
                      accessLevel === 0 && 
                      <Route key={location.key} exact path='/claims/:claimsId' component={UnderConstruction}></Route>}
                      
                      {/** FILES ROUTES */}
                      {
                      // accessLevel === 0 && 
                      <Route path='/policy-headers/:id/files' component={PolicyHeaderFilesComponent}></Route>
                      }
                      
                      {/** AUTHENTICATED PAGES ERRORS */}
                      <Route exact path='/error/forbidden' component={ForbiddenError}></Route>
                      <Route exact path='/error/not-found' component={PageNotFoundError}></Route>
                      <Route exact path='/error/server-error' component={ServerError}></Route>
                      
                      {/* STATIC PAGES */}
                      {!isBasicUser && <Route exact path='/data-analytic' component={DataAnalyticComponent}></Route>}
                      {!isBasicUser && <Route exact path='/disclaimer' component={DisclaimerComponent}></Route>}
                      {!isBasicUser && <Route exact path='/terms-and-conditions' component={TermsAndConditionComponent}></Route>}
                      {!isBasicUser && <Route exact path='/privacy-policy' component={PrivacyPolicyComponent}></Route>}
                      {!isBasicUser && <Route exact path='/financial-services-guide' component={FinancialServicesGuideComponent}></Route>}
                      <Redirect from='*' to="/page-not-found" />
                    </Switch>
                  </Container>
                </div>
                
                <footer className='mt-5'>
                  <Footer />
                </footer>
              </div>
              )
            } />
        </Switch>

        {/** GLOBAL COMPONENTS */}
        <ToastContainer  hideProgressBar theme='colored'/>
        
      </>
  );
}

export default observer(App);
