import { Skeleton } from '@progress/kendo-react-indicators'
import classNames from 'classnames'
import fileDownload from 'js-file-download'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import ConfirmationModal from '../../app/common/form/ConfirmModal'
import { useStore } from '../../app/stores/store'
import { DisplayIcon, ExtToIcon, FormatDate, ToSpineCase, UserHasPermission } from '../../app/utils/utils'
import { FileTypes, PolicyFile } from '../../models/files'
import mime from 'mime-types'
import { AxiosError } from 'axios'
import { Notice, NoticeStates } from '../../app/stores/noticeStore'

interface FileAccordionProps {
    policyHeaderGuid: string
    headerTitle: string,
    fileList?: PolicyFile[],
    subAccordion?: boolean,
    open?: boolean
}

const FileListAccordion = ({policyHeaderGuid, headerTitle, fileList, subAccordion, open}: FileAccordionProps) => {
    const [selectedFile, setSelectedFile] = useState<PolicyFile|undefined>();
    const [modalShow, setModalShow] = useState(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');
    const {policyHeaderStore, noticeStore, userStore, fileStore} = useStore();
    const {userClaims} = userStore;
    const {downloadStaticFile} = fileStore;
    const {downloadFile, deleteFile} = policyHeaderStore;
    const [files, setfiles] = useState(fileList)
    
    const confirmDelete = (file: PolicyFile) => {
        setSelectedFile(file);
        setModalBodyMessage(`Please confirm you would like to delete the file ${file?.title} uploaded ${file.createdDate}. This action is permanent.`);
        setModalShow(true);
    }

    const handleCancel = () => {
        setModalShow(false);
        setSelectedFile(undefined);
    }

    const handleDelete = () => {
        deleteFile(policyHeaderGuid, selectedFile?.id!).then((response) => {
            const updatedList = files?.filter(f => f.id !== selectedFile?.id!);
            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: `Successfully deleted ${selectedFile?.title}.`,
                flashMessage: true,
                statusCode: 200,
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);

            setfiles(updatedList);
            setSelectedFile(undefined);
            setModalShow(false);
        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to delete file. Please try again.`,
                statusCode: 500,
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
            setModalShow(false);
        })
    }

    const handleDownload = async (file: PolicyFile) => {
        if(file.fileType === FileTypes.StaticFile) {
            await downloadStaticFile(file.templateName!)
            .then(blob => {
                fileDownload(blob, `${file.title}.${mime.extension(blob.type)}`)
            })
            .catch((err: AxiosError) => {
                const notice: Notice = {
                    noticeState: NoticeStates.error,
                    message: `Unable to download file. Please try again.`,
                    statusCode: 500,
                };
    
                noticeStore.setNotice(notice);
                window.scrollTo(0, 0);
            })
        }else{
            await downloadFile(policyHeaderGuid, file.id!)
            .then(blob => {
                fileDownload(blob, `${file.title}.${mime.extension(blob.type)}`)
            })
            .catch((err: AxiosError) => {
                const notice: Notice = {
                    noticeState: NoticeStates.error,
                    message: `Unable to download file. Please try again.`,
                    statusCode: 500,
                };
    
                noticeStore.setNotice(notice);
                window.scrollTo(0, 0);
            })
        } 
    }

    useEffect(() => {
        setfiles(fileList)
    
    }, [fileList, headerTitle])
    
    return (
        <>
            <Accordion
                id={`${ToSpineCase(headerTitle)}-accordion`}
                defaultActiveKey={ (!subAccordion || open) ? headerTitle : ''} 
                className={classNames({
                    'sub-accordion': subAccordion,
                    'mb-4': !subAccordion
                })}>
                <Accordion.Item eventKey={headerTitle}>
                    <Accordion.Header>
                    { 
                        (subAccordion)
                            ? (
                                <>
                                    <strong>
                                        <i className={`icon ${DisplayIcon(FileTypes.Folder)} me-2`}/> 
                                        {headerTitle}
                                    </strong>
                                </>
                            ) : ( <h3 className='mb-0'>{headerTitle}</h3> )
                    }
                    </Accordion.Header>
                    <Accordion.Body className='p-0'>
                        { !files && 
                            <Skeleton shape={"text"} style={{ width: "35%", height:"33px", margin: "20px" }} />
                        }

                        { files && files.map(
                                (file, index) => (file.subFiles && file.subFiles.length > 0) 
                                ? <FileListAccordion subAccordion policyHeaderGuid={policyHeaderGuid} headerTitle={`${file.title}`} fileList={file.subFiles} key={index} />
                                : (
                                    <Row className='border-bottom m-0 clickable' key={index} onClick={() => handleDownload(file)}>
                                        <Col className='ps-4 pt-3 pb-3 col-7'>
                                            <i 
                                            className={classNames(`icon ${ExtToIcon(file.ext || '.dir')} me-2`, {
                                                'ms-4': subAccordion
                                            })}/> {file.title}
                                        </Col>
                                        <Col className='p-3 col-2'>
                                            <small className='text-muted'>{ FormatDate(file.createdDate)}</small>
                                        </Col>
                                        <Col className='p-3 pe-4 col-2 text-end'>
                                            { UserHasPermission(['PortalAdmin'], userClaims?.role) && file.deletable &&  <i className='icon icon-trash clickable me-4' onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmDelete(file)
                                            }}/> }
                                            {file.fileType !== FileTypes.Folder && <i className='icon icon-download clickable' onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDownload(file)
                                                }
                                            }/>}
                                        </Col>
                                        <Col className='col-1'>&nbsp;</Col>
                                    </Row>
                                )
                            )
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <ConfirmationModal 
                show={modalShow} 
                headerTitle='Delete file'
                bodyMessage={modalBodyMessage}
                onConfirm={handleDelete}
                onHide={handleCancel}/>
        </>
    )
}

export default observer(FileListAccordion)