import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { PolicyHeaderListItem } from '../../models/subMenu'
import ClaimNotificationForm from './forms/ClaimNotificationForm'
import ManualClaimNotificationForm from './forms/ManualClaimNotificationForm'

interface ClaimNotificationProps {
    policyHeader: PolicyHeaderListItem,
    memberGuid?: string
}

const ClaimNotification = ({policyHeader}: ClaimNotificationProps ) => {
    const [manualEntry, setManualEntry] = useState<boolean>(false);
    const handleManualEntry = (status: boolean) => {
        setManualEntry(status);
    }

    return (
        <>
            {!manualEntry &&<ClaimNotificationForm policyHeader={policyHeader} manualEntry={handleManualEntry} />}
            {manualEntry && <ManualClaimNotificationForm policyHeader={policyHeader} manualEntry={handleManualEntry} />}
        </>
    )
}

export default observer(ClaimNotification)