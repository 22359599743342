import { makeAutoObservable } from "mobx";
import { FileUploadPayload, PolicyHeaderFiles } from "../../models/files";
import agent from "../api/agent";

export default class FileStore {
    policyHeaderFiles?: PolicyHeaderFiles;
    uploadProgress?: number;
    downloadProgress?: number;

    constructor(){
        makeAutoObservable(this);
    }

    uploadFile = async (payload: FileUploadPayload) => {
        return await agent.PolicyHeaders.uploadFile(payload);
    } 

    setUploadProgress = (progress: number) => {
        this.uploadProgress = progress;
    }

    downloadFile = async (policyHeader: string, fileGuid: string) => {
        return await agent.PolicyHeaders.downloadFile(policyHeader, fileGuid);
    }  

    setDownloadProgress = (progress: number) => {
        this.downloadProgress = progress;
    }

    deleteFile = async (policyHeader: string, fileGuid: string) => {
        return await agent.PolicyHeaders.deleteFile(policyHeader, fileGuid);
    }
    
    downloadStaticFile = async (templateName: string) => {
        return await agent.StaticFiles.downloadTemplate(templateName);
    }  
}
