import { AutoComplete, ListItemProps } from '@progress/kendo-react-dropdowns'
import { Skeleton } from '@progress/kendo-react-indicators'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { cloneElement, useEffect, useRef, useState } from 'react'
import { Col, NavDropdown, Row } from 'react-bootstrap'
import { PolicyHeaderStartDate, PolicyHeaderStatus } from '../../../models/policyHeader'
import { useStore } from '../../stores/store'
import { FormatDate } from '../../utils/utils'

/**
 *  TODO:  refactor component to be reusable in any member search circumstances (claims, policy, etc...)
 *   - delete or replace other member search component
 *   - implement toggleable filters
 *   - instance preset filters (reviewdate, policyheaderid, memberId)
 *   - option to preselect the member - requires memberID
 */

interface MemberSearchBarProps {
    placeholder: string,                    // this is the search box placeholder
    suggestedField?: string,                // the field shown in the suggestion box, defaults to memberName
    searchOnInit?: boolean,                 // set to true if you need to show initial list
    policyHeaderId?: string,                // policy header Id filter
    policyHeaderReviewDate?: Date,          // policy header date filter
    policyHeaderStatus?: PolicyHeaderStatus,
    reviewDates?: PolicyHeaderStartDate[],  // optional, if set the review date filter will be displayed
    memberID?: string                       // default to selected memberId,
    selectOnEnter?: boolean,                
    isInvalid?: boolean,                    
    focusOnLoad?: boolean,                  // focus search bar on load
    keyword?: string,                       
    label?: string,   
    noResultRender?: (element: React.ReactElement<HTMLDivElement>) => React.ReactNode,
    itemRender?: (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => React.ReactNode,
    footer?: React.ReactNode,
    
    // Suggestion item
    suggestDob?: boolean,
    suggestDjp?: boolean,
    suggestPolicyNum?: boolean,

    withDataGrid?:boolean,
    disableSuggestion?: boolean
}

const MemberSearchBar = ({ ...props }: MemberSearchBarProps) => {
    const {searchMemberStore, preSelectStore} = useStore();
    const { initSearch, 
            resetSearch, 
            searchSuggestion, 
            getDataGridResults,
            suggestionList,
            setSelectedMember, selectedMember, setReviewDate} = searchMemberStore;
    const [isCurrentReviewDate, setIsCurrentReviewDate] = useState<boolean>((props.policyHeaderStatus !== PolicyHeaderStatus.CURRENT));
    const [selectedReviewDate, setSelectedReviewDate] = useState(props.policyHeaderReviewDate);
    const AutoCompleteTextField = props.suggestedField || 'memberInfo.name';
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState<string>(props.keyword || '');
    const searchBar = useRef<AutoComplete>(null);

    useEffect(() => {
        if(props.focusOnLoad && !!searchBar.current) {
            if(searchBar.current) searchBar.current.focus();
        }

        initSearch(searchBar.current?.value || '', props.policyHeaderId, props.policyHeaderReviewDate)
        setSelectedReviewDate(props.policyHeaderReviewDate)

        return () => {
            resetSearch();
            setSearchKeyword('');
            setIsCurrentReviewDate(true);
        }
    },[initSearch, resetSearch, props.focusOnLoad, props.policyHeaderId, props.policyHeaderReviewDate, props.searchOnInit])

    const onChange = async (event: any) => {
        const inputValue = event.value;
        setSearchKeyword(inputValue);

        // disable select when pressing enter...
        if(!props.selectOnEnter && event.syntheticEvent.keyCode === 13) {
            return;
        }

        if(event.syntheticEvent.type === 'click'){
            setShowSuggestion(false);
            const selectedSuggestionGuid = event.syntheticEvent.currentTarget.dataset.memberguid;
            if(selectedSuggestionGuid) {

                let memberMatch = suggestionList?.find(m => m.memberInfo.id === selectedSuggestionGuid);
                setSelectedMember(memberMatch);
                preSelectStore.setMember({
                        id: selectedSuggestionGuid,
                        label: inputValue
                    });
            }else{
                setSelectedMember(undefined);
                preSelectStore.setMember(undefined);
            }
        }

        if(event.syntheticEvent.type === 'change'){
            if(inputValue.length >= 2){
                setIsLoading(true);

                if(!props.disableSuggestion) {
                    await searchSuggestion(inputValue).then(() => {
                        setIsLoading(false);
                        setShowSuggestion(true);
                    })
                }else{
                    if( (inputValue.length >= 2 || inputValue.length === 0) && props.withDataGrid) {
                        getDataGridResults(inputValue, 1, props.policyHeaderId, selectedReviewDate).then(() => {
                            setIsLoading(false);
                            setShowSuggestion(true);
                        });
                    }
                }
            }else{
                setShowSuggestion(false);
            }
        }    
        
        if(inputValue.length === 0){
            initSearch('', props.policyHeaderId, selectedReviewDate)
        }
    }

    const onClose = (event: any) => {
        const inputValue = event.value;
        setShowSuggestion(false);

        if( (inputValue.length >= 2 || inputValue.length === 0) && props.withDataGrid) {
            getDataGridResults(inputValue, 1, props.policyHeaderId, selectedReviewDate);
        }
    }

    const selectReviewDate = async (reviewDate: PolicyHeaderStartDate) => {
        setSelectedReviewDate(reviewDate.startDate)
        setReviewDate(reviewDate.startDate)
        setIsCurrentReviewDate(reviewDate.status === PolicyHeaderStatus.CURRENT);
        getDataGridResults(searchKeyword, 1, props.policyHeaderId, reviewDate.startDate);
    }

    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
          <div className="autocomplete-suggestion member-suggestion w-100 ps-3 pe-3 pt-1 pb-1"  >
            {li.props.children}  
            <>
                <small className='text-muted ms-2'>
                    {props.suggestDob && `(DOB: ${ FormatDate(itemProps.dataItem.memberInfo.dob)}) `}
                    {props.suggestDjp && `(Joined: ${ FormatDate(itemProps.dataItem.memberInfo.joinedDate)}) ` }
                    {props.suggestPolicyNum && `[${itemProps.dataItem.memberInfo.policyNumber}] ` }
                </small>
            </>
          </div>
        );

        const datasetAttr = {
            ...li.props,
            'data-memberguid': itemProps.dataItem.memberInfo.id,
            'data-policyheader': itemProps.dataItem.memberInfo.policyHeaderGuid,
            'data-employeeid': itemProps.dataItem.memberInfo.employeeID
        }

        return cloneElement(li, datasetAttr, itemChildren);
    };

    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        return cloneElement(element, { ...element.props }, 'No results found');
    };

    return (
        <>
            <Row>
                <Col className={classNames({
                    'col-10' : props.reviewDates,
                    'col-12' : !props.reviewDates
                })}>
                    { props.label && 
                        <label className='text-bold mb-3' style={{fontWeight: 500}}>{props.label}</label>
                    }
                    <AutoComplete
                        ref={searchBar}
                        data={suggestionList}
                        textField={AutoCompleteTextField}
                        onChange={onChange}
                        onClose={onClose}
                        placeholder={props.placeholder}
                        value={searchKeyword}
                        opened={!props.disableSuggestion && showSuggestion}
                        loading={isLoading}
                        itemRender={props.itemRender || itemRender}
                        listNoDataRender={props.noResultRender || listNoDataRender}
                        footer={props.footer}
                        valid={!!!props.isInvalid}
                        popupSettings={{
                            className: 'autocomplete-suggestions'
                        }}
                        className="form-control form-control-lg w-100 search-box"
                    />
                    <input type={'hidden'} value={selectedMember?.memberInfo.id || ''} name='selectedMemberId' />
                </Col>

                { props.reviewDates && props.policyHeaderReviewDate &&
                    <Col className='col-2'>
                        <div className={classNames('review-date-filter-container p-2',{
                                'bg-light-yellow':  isCurrentReviewDate,
                                'bg-light-gray':  !isCurrentReviewDate,
                            })} >
                            <small className='text-muted p-3'>Review date</small>
                            { !selectedReviewDate && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            
                            { selectedReviewDate && 
                            <NavDropdown className='review-date-filter' id="group-headers-list" title={FormatDate(selectedReviewDate)}>
                                { props.reviewDates.map((p, index) => 
                                        <NavDropdown.Item key={index} onClick={() => selectReviewDate(p)}>
                                            {FormatDate(p.startDate)}
                                        </NavDropdown.Item>
                                    )
                                } 
                            </NavDropdown>
                            }
                        </div>
                    </Col>
                }
            </Row>
        </>
    )
}

export default observer(MemberSearchBar) 