import { useState } from 'react'
import { 
    Grid,
    GridColumn,
    GridRowClickEvent,
    GridSortChangeEvent
} from '@progress/kendo-react-grid'
import { useHistory } from 'react-router-dom';
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Col, Row } from 'react-bootstrap';
import LoaderComponent from '../../app/layout/LoaderComponent';
import { PolicyHeader } from '../../models/policyHeader';
import { observer } from 'mobx-react-lite';
import pluralize from 'pluralize';

interface Props {
    dataGrid: PolicyHeader[] | []
}

const FollowUpDataGrid = ({dataGrid}: Props) => {
    const history = useHistory();
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const handlerRowClick = (e: GridRowClickEvent) => {
        history.push(`/policy-headers/${e.dataItem.policyHeaderGuid}/upload/${e.dataItem.fileRequired}`);
    }

    if(!dataGrid) return <LoaderComponent content='Loading policies...'/>

    const parsedData = dataGrid?.map((p) => {
        const parsedRow = {...p, renewalDate: new Date(p.reviewDate) }
        return parsedRow;
    });

    return (
        <>
        <Row>
            <Col>
                <Grid 
                    onRowClick={handlerRowClick}
                    data={orderBy(parsedData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => {
                        setSort(e.sort);
                    }}> 
                    <GridColumn 
                        field="policyNumber" 
                        title="Policy"/>
                    <GridColumn 
                        field="groupName" 
                        title="Group" width={300}/>
                    <GridColumn 
                        field="productType" 
                        title="Type" width={80}/>
                    <GridColumn 
                        field="insurerName" 
                        title="Insurer"/>
                    <GridColumn 
                        field="memberCount" 
                        title="Members"  width={100}/>
                    <GridColumn 
                        field="totalPremium" 
                        title="Premium" 
                        filter="numeric"
                        format="{0:c}"/>
                    <GridColumn 
                        field="renewalDate" 
                        title="Renewal" 
                        filter="date"
                        format="{0:dd/MM/yyyy}"
                    />
                    <GridColumn 
                        field="fileRequired" 
                        title="File Required"/>
                </Grid>
            </Col>
        </Row>

        <Row className='mt-4 text-end'>
            <Col>
                <small className='text-muted'>
                    {dataGrid.length}  { pluralize('Result', dataGrid.length)}
                </small>
            </Col>
        </Row>
        </>
    )
}

export default observer(FollowUpDataGrid)