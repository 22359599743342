import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import { IdTypes, SubLinks } from '../../app/stores/subMenuStore';
import { NoteTypes } from '../../models/note';
import NotesListComponent from '../notes/NotesListComponent';
import PolicyCoverDetailsComponent from '../policies/PolicyCoverDetailsComponent';
import MemberClaimsListComponent from './MemberClaimsListComponent';
import MemberInfo from './MemberInfo';
import MemberUnderwritingDecisions from './MemberUnderwritingDecisions';
import { Helmet } from "react-helmet"
import { AxiosError } from 'axios';

interface MemberUrlParams {
    memberId: string
}

const MemberDetailsComponent = () => {
    const {memberStore, userStore, subMenuStore} = useStore();
    const {member, getMemberDetails, setMember} = memberStore;
    const {userClaims} = userStore;
    const {memberId} = useParams<MemberUrlParams>();
    const {initPolicyHeader, setActivelink} = subMenuStore;
    const history = useHistory();
    const accessLevel = userClaims?.accessLevelId;

    useEffect(()=>{
        // scroll to top
        window.scrollTo(0, 0);

        // show submenu 
        initPolicyHeader(IdTypes.memberId, memberId);
        setActivelink(SubLinks.members);

        getMemberDetails(memberId).then(memberDetails => {
            setMember(memberDetails) 
        }).catch((err: AxiosError) => {
            if(err.response?.status === 400) history.push("/error/not-found");
            if(err.response?.status === 403) history.push("/error/forbidden");
        })

        return () => {
          //  subMenuStore.resetPolicyHeader();
            setMember(undefined);
        }
       
    }, [memberId, getMemberDetails, setMember, initPolicyHeader, setActivelink, history]);

    // Layout swiitches to full width 
    
    const isPortalAdmin = userClaims?.role.includes('PortalAdmin');
    
    return (
        <>
            <Helmet title={`Member: ${member?.memberInfo.name} - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`} />
            <Row className='mt-4 mb-4'>
                <Col className='p-0'>
                    <h1 className='header-title'>Member details</h1>
                </Col>
            </Row>
            <Row className='gx-5'>
                <Col className={classNames( {
                                        'col-12': !isPortalAdmin,
                                        'col-8': isPortalAdmin
                                    })}>
                                        
                    <MemberInfo member={member?.memberInfo} />
                    
                    {/** loading state */}
                    { !member && <PolicyCoverDetailsComponent /> }
                    { member && member.activePolicies.length > 0 &&
                        <>
                            <Row>
                                <Col className='p-0'>
                                    <h2 className='mt-4'>Cover as of review date</h2>
                                </Col>
                            </Row>
                            {member.activePolicies.map( (p,index) => ( <PolicyCoverDetailsComponent member={member} policy={p} key={index} /> ))}
                        </>
                    }

                    { member && 
                    accessLevel === 0 && 
                    <MemberClaimsListComponent member={member} /> }
                    <MemberUnderwritingDecisions memberId={memberId} policyHeaderGuid={subMenuStore.policyHeader?.id!}  />
                    
                    {/** loading state */}
                    { !member && <PolicyCoverDetailsComponent /> }
                    { member && member.inactivePolicies.length > 0 &&
                        <>
                        <Row className='mt-5 mb-4'>
                            <Col className='p-0'>
                                <h2>Previous</h2>
                            </Col>
                        </Row>

                        { member.inactivePolicies.map((p,index) => ( <PolicyCoverDetailsComponent policy={p} key={index} /> )) }
                        </>
                    }
                </Col>

                {isPortalAdmin &&
                <Col className={'col-4'}>
                    <NotesListComponent 
                        listTitle={'Member notes'} 
                        notesType={NoteTypes.Member} 
                        notesOf={memberId} />
                </Col>
                }
            </Row>
        </>
    )
}

export default observer(MemberDetailsComponent)