import { observer } from "mobx-react-lite";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ActionButton from '../../../app/common/form/ActionButton';
import { FormikAmountInput, FormikDateInput, FormikFileDropzone } from '../../../app/common/form/FormikForm';
import { ClaimDetails, InsurerPayment } from '../../../models/claim';
import * as Yup from 'yup';
import { useStore } from '../../../app/stores/store';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import { AxiosError } from 'axios';
import moment from 'moment';

interface InsurerPaymentFormProps {
    claimsGuid: string,
    selectedClaim: ClaimDetails | undefined,
}

const InsurerPaymentForm = ({claimsGuid, selectedClaim}: InsurerPaymentFormProps) => {
    const {claimsStore, noticeStore} = useStore();
    const {paymentTypes, getPaymentTypes} = claimsStore;

    const initial = {
        claimGuid: claimsGuid,
        paymentDate: new Date(),
        startDate: null, 
        endDate:  null,
        paymentType: 'Ancillary',
        paymentAmount: 0,
        superAmount: 0,
    }

    const [openInsurerPaymentForm, setOpenInsurerPaymentForm] = useState<boolean>(false);

    useEffect(() => {       
        selectedClaim?.policyHeaderId && getPaymentTypes(selectedClaim?.policyHeaderId);
    }, [getPaymentTypes, selectedClaim?.policyHeaderId]);

    const ValidationScheme = Yup.object().shape({
        paymentDate: Yup.date().required('Required'),
        paymentType: Yup.string().required('Required'),
        startDate: Yup.date().nullable()
                    .when("paymentType", {
                        is: (val: string) => val === 'Ongoing full' || val === 'Ongoing partial' ,
                        then: Yup.date().typeError("Invalid date").max(Yup.ref('endDate'),"Must be before end period").required("Required"),
                        otherwise: Yup.date().nullable()
                    }),
        endDate: Yup.date().nullable()
                .when("paymentType", {
                    is: (val: string) => val === 'Ongoing full' || val === 'Ongoing partial' ,
                    then: () =>  Yup.date().typeError("Invalid date").min(Yup.ref('startDate'),"Must be after start period").required("Required"),
                    otherwise: Yup.date().nullable()
                })
                .when(
                    'startDate',
                    (startDate, schema) => (moment(startDate).isValid()  ? schema.min(startDate + 86400000, "Must be after start period") : schema),
                ),
        paymentAmount: Yup.number().max(9999999,'Payment amount must be less than or equal to 9,999,999'),
        superAmount: Yup.number().max(9999999,'Super must be less than or equal to 9,999,999'),
        file: Yup.mixed()
    });

    const handleSubmit = (values: InsurerPayment, actions: any) => {

        // check start and end date values
        if(!values.startDate) values.startDate = null; 
        if(!values.endDate) values.endDate = null;

        claimsStore.newInsurerPayment(values).then(() => {
            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: "New insurer payment added",
                flashMessage: true,
                statusCode: 200,
            };

            noticeStore.setNotice(notice);
            setOpenInsurerPaymentForm(false);
            selectedClaim!.cumulativePayment += values.paymentAmount + values.superAmount;
            window.scrollTo(0, 0);
        }).catch((err: AxiosError)=>{
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: JSON.stringify(err.response?.data, null, 2),
                statusCode: 403,
            };

            noticeStore.setNotice(notice);
        })  
    }   

    const handleCancel = () => {
        setOpenInsurerPaymentForm(false);
    }

    const handleFormOpen = () => {
        setOpenInsurerPaymentForm(!!!openInsurerPaymentForm);
    }

    return (
        <>
            <div className="claim-form mt-5"> 
                <Row className='m-0'>
                    <Col className='col-lg-11 col-md-10 p-3 align-middle '>
                        <h3 className='m-0'>Insurer payment</h3>
                    </Col>
                    <Col className='col-lg-1 col-md-2 p-0 border-start'>
                        <ActionButton clickHandler={handleFormOpen} icon={openInsurerPaymentForm?"icon-cross":"icon-plus"} />
                    </Col>
                </Row>

                { openInsurerPaymentForm &&
                    <Formik
                        initialValues={initial}
                        validationSchema={ValidationScheme}
                        onSubmit={handleSubmit}>

                        { props => (
                        <Form >
                            <Row className='border-top border-bottom m-0'>
                                <Col className='col-3 p-3 border-end'>
                                    <FormikDateInput name="paymentDate" id="paymentDate" label={'Date paid'} />      
                                </Col>
                                <Col className='col-9 p-3'>
                                    <label className='text-muted'>Payment type</label>
                                    <Field as="select" name="paymentType" className="form-select">
                                        {
                                            paymentTypes.map((key, index) => (
                                                <option value={key} key={index}>{key}</option>))
                                        }
                                    </Field>
                                    <ErrorMessage name="paymentType" render={ msg => 
                                        <small className="text-warning">{msg}</small>
                                    } />        
                                </Col>
                            </Row>
                            <Row className='border-bottom m-0'>
                                <Col className='col-3 p-3 border-end'>
                                    <FormikDateInput name="startDate" label={'Start period'} />      
                                </Col>
                                <Col className='col-3 p-3 border-end'>
                                    <FormikDateInput name="endDate" label={'End period'} />      
                                </Col>
                                <Col className='col-3 p-3 border-end'>
                                    <FormikAmountInput name="paymentAmount" format="c2" label={'Payment pre-tax'} />      
                                </Col>
                                <Col className='col-3 p-3'>
                                    <FormikAmountInput name="superAmount" format="c2" label={'Super'} />      
                                </Col>
                            </Row>
                            <Row className='border-bottom m-0'>
                                <Col className='p-3'>
                                    <FormikFileDropzone name='file'/>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-4 pe-3 text-end'>
                                <button className='btn btn-stale me-2' onClick={handleCancel}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>Save</button>
                                </Col>
                            </Row>
                        </Form>
                        )}
                    </Formik>
                }
            </div>
        </>
    )
}

export default observer(InsurerPaymentForm)