import { Skeleton } from '@progress/kendo-react-indicators';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { observer } from 'mobx-react-lite';
import { Row, Col } from 'react-bootstrap';
import ActionButton from '../../../app/common/form/ActionButton';
import { FormikDateInput, FormikInput, FormikAmountInput } from '../../../app/common/form/FormikForm';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import { useStore } from '../../../app/stores/store';
import { ClaimDetails, EditClaim } from '../../../models/claim';
import { ClaimStatus } from '../../../models/claimSearch';
import * as Yup from 'yup';
import { PolicyHeaderListItem } from '../../../models/subMenu';
import { UserHasPermission, removeNulls, removeTime } from '../../../app/utils/utils';
import { CoverType } from '../../../models/policyHeader';

interface EditClaimFormProps {
    claim: ClaimDetails,
    policyHeader: PolicyHeaderListItem,
    editMode: any
}

const EditClaimForm = ({claim, editMode, policyHeader}: EditClaimFormProps ) => {
    const {claimsStore, noticeStore, userStore} = useStore();
    const {userClaims} = userStore;

    const initialValue: EditClaim = {
        id: claim.id,
        policyHeaderGuid: claim.policyHeaderId,
        notificationDate: removeTime(new Date(claim.notificationDate)),
        coverType: claim.coverType,
        decisionDate: claim.decisionDate ? removeTime(new Date(claim.decisionDate)) : undefined,
        insurerClaimNo: claim.insurerClaimNo,
        waitingPeriod: claim.waitingPeriod,
        benefitPeriod: claim.waitingPeriod,
        disablementDate: claim.disablementDate ? removeTime(new Date(claim.disablementDate)) : undefined,
        assessedBenefit: claim.assessedBenefit,
        decision:   Object.keys(ClaimStatus)[Object.values(ClaimStatus).indexOf(Object.values(ClaimStatus)[parseInt(claim.decision)])]  ,
        benefitStartDate: claim.benefitStartDate ? removeTime(new Date(claim.benefitStartDate)) : undefined,
        closeDate: claim.closeDate ? removeTime(new Date(claim.closeDate)) : undefined,
        notes: claim.notes
    }
    
    const ValidationScheme = Yup.object().shape({
        notificationDate: Yup.date().nullable().typeError('Notification must be formatted as dd/mm/yyyy').required('Notification is required'),
        coverType: Yup.string().required('Required'),
        decisionDate: Yup.date()
            .nullable()
            .when(
                'closeDate',
                (closeDate, schema) => removeNulls(closeDate) && schema.max(closeDate, "Decision date cannot be after Close date.")
            ),
        insurerClaimNo: Yup.string().nullable().max(20, "Maximum 20 characters"),
        waitingPeriod: Yup.string().nullable().max(50, "Maximum 50 characters"),
        benefitPeriod: Yup.string().nullable().max(50, "Maximum 50 characters"),
        disablementDate: Yup.date().nullable().max(
            Yup.ref('notificationDate'),
            "Disablement date cannot be after Notification date.",
        ),
        assessedBenefit: Yup.number().typeError('Required').min(0).max(9999999.99),
        decision: Yup.string().required(),
        benefitStartDate: Yup.date()
            .nullable()
            .when(
                'disablementDate',
                (disablementDate, schema) => removeNulls(disablementDate) && schema.min(disablementDate, "Benefit start date cannot be before Disablement date.")
            ),
        closeDate: Yup.date()
            .nullable()
            .when(
                'benefitStartDate',
                (benefitStartDate, schema) => removeNulls(benefitStartDate) && schema.min(benefitStartDate, "Close date cannot be before Benefit start date.")
            ),
        notes: Yup.string(),
    })


    const handleSubmit = async (values: EditClaim, actions: any) => {
        await claimsStore.editClaim(values).then((response) => {          
            claimsStore.setSelectedClaim(response)
            editMode(false);

        }).catch(() => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: "An error occured, please try again.",
                statusCode: 200,
            };

            noticeStore.setNotice(notice);
        })
        actions.setSubmitting(false);
    }

    const handleCancel = () => {
        editMode(false);
    }

    return (
        <>
            <Formik
                initialValues={initialValue}
                validationSchema={ValidationScheme}
                onSubmit={handleSubmit}>
            { props => (
                <Form >    
                    <div className='claim-form'>
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 '>
                                <label className='text-muted'>Employee ID</label>
                                <div>
                                    {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                    <strong>{claim.employeeId}</strong>
                                </div>
                            </Col>
                            <Col className='col-3 p-3 '>
                                <label className='text-muted'>Surname</label>
                                <div>
                                    {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                    <strong>{claim.surname}</strong>
                                </div>
                            </Col>
                            <Col className='col-3 p-3 '>
                                <label className='text-muted'>First name</label>
                                <div>
                                    {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                    <strong>{claim.firstname}</strong>
                                </div>
                            </Col>
                            <Col className='col-2 p-3' />
                            { UserHasPermission(['PortalAdmin'], userClaims?.role) &&
                                <Col className='col-1 p-0 border-start'>
                                    <ActionButton clickHandler={handleCancel} icon='icon-cross' />
                                </Col>
                            }
                        </Row>
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="notificationDate" label={'Notification'} />      
                            </Col>

                            <Col className='col-3 p-3 border-end'>
                                <label className='text-muted'>Type</label>
                                <Field as='select' name="coverType"  className='form-control form-select'>                                    
                                    {  policyHeader.coverTypes?.length <= 1 && <option value=''>Select</option> }
                                    {
                                        policyHeader && policyHeader.coverTypes?.map( (coverType, index) => (
                                            <option value={coverType} key={index}>{coverType}</option>
                                        ))
                                    }
                                </Field>     
                                <ErrorMessage name="coverType" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                } />       
                            </Col>

                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="decisionDate" label={'Decision date'} />      
                            </Col>
                            
                            <Col className='col-3 p-3'>
                                <FormikInput name="insurerClaimNo" label='Insurer claim #' />
                            </Col>
                        </Row>
                        
                        {claim?.coverType === CoverType.GSC &&
                        <Row className='border-bottom m-0'>
                            <Col className='col-6 p-3 border-end'>
                                <FormikInput name="waitingPeriod" label='Waiting period' />       
                            </Col>
                            <Col className='col-6 p-3'>
                                <FormikInput name="benefitPeriod" label='Benefit period' />
                            </Col>
                        </Row>
                        }

                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="disablementDate" label={'Disablement date'} />      
                            </Col>
                            <Col className='col-3 p-3 border-end'>
                                <FormikAmountInput name="assessedBenefit" label='Assessed benefit' format="c0" placeholder={`$`} /> 
                            </Col>
                            <Col className='col-6 p-3'>
                                <label className='text-muted'>Decision</label>
                                <Field as="select" name="decision" className="form-select" onChange={ (e: Event) => {
                                    const target = e.target as HTMLSelectElement;
                                    if(target.value !== ClaimStatus.Approved){
                                        // reset and disable the benefit startdate
                                        props.setFieldValue('benefitStartDate', null);
                                    }      
                                    props.handleChange(e);
                                }}>
                                    <option value=''>Select</option>
                                    {
                                        Object.keys(ClaimStatus).map((key, index) => (
                                            <option value={key} key={index}>{(ClaimStatus as any)[key]}</option>))
                                    }
                                </Field>
                                <ErrorMessage name="decision" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                    } />            
                            </Col>
                        </Row>
                        
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput name="benefitStartDate" label={'Benefit start'} disabled={ props.values.decision !== ClaimStatus.Approved } /> 
                            </Col>
                            <Col className='col-6 p-3 border-end' />
                            <Col className='col-3 p-3'>
                                <FormikDateInput  name="closeDate" label={'Close date'} />        
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-3'>
                                <label className='text-muted'>Notes</label>
                                <Field as="textarea" row='4' name="notes" className='form-control mb-3'/>
                                <ErrorMessage name="notes" render={ msg => 
                                        <small className="text-warning">{msg}</small>
                                    } />   
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-4 pe-3 text-end'>
                            <button className='btn btn-stale me-2' onClick={handleCancel}>Cancel</button>
                            <button type='submit' className='btn btn-primary'>Save</button>
                            </Col>
                        </Row>

                    </div>
                </Form>
            )}
            </Formik>
        </>
    )
}

export default observer(EditClaimForm) 