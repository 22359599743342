import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import { useStore } from '../../../app/stores/store'
import {Helmet} from 'react-helmet'

interface UnderwritingDecisionFormProps {
    policyHeader?: PolicyHeaderListItem,
    memberGuid?: string,
    memberName?: string
}

const UnderwritingDecisionForm = ({policyHeader, memberGuid, memberName}: UnderwritingDecisionFormProps) => {
    const {preSelectStore} = useStore();
    const {setMember} = preSelectStore;
    const headerTitle = 'Underwriting decision letter';
    const headerDescription = (
            <>
               <p>You are about to upload an underwriting decision letter issued by insurer for below member.</p>
            </>
        )
        
    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.UnderwritingDecision,
        file: undefined,
        memberGuid: memberGuid,
        requireMemberGuid: true
    }

    useEffect(() => {
      return () => {
        setMember();
      }
    }, [setMember])
    

    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                            policyHeader={policyHeader} 
                            headerTitle={headerTitle} 
                            headerDescription={headerDescription} 
                            fileUploadType={FileUploadType.UnderwritingDecision} 
                            initialValues={initialValues}
                            enableSearchMember
                            memberName={memberName}
                            memberGuid={memberGuid}
                        />
            }
        </>
    )
}

export default observer(UnderwritingDecisionForm)