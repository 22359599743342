import { Skeleton } from '@progress/kendo-react-indicators'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import ActionButton from '../../app/common/form/ActionButton'
import { FormatAmount, FormatDate } from '../../app/utils/utils'
import { PolicyHeader } from '../../models/policyHeader'

interface PolicyHeaderItemProps {
    policyHeader?: PolicyHeader
}

const PolicyHeaderListItem = ({policyHeader}: PolicyHeaderItemProps) => {
    
    const history = useHistory();
    const handleClick = () => {
        if(policyHeader) {
            history.push(`/policy-headers/${policyHeader.policyHeaderGuid}`);
        }
    }

    return (
        <Row className='policy-header-item border mt-4'>
            <Col className='p-3 border-end'>
                <small className='text-muted'>Number</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{policyHeader?.policyNumber}</div>
            </Col>
            <Col className='p-3 border-end'>
                <small className='text-muted'>Type</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{policyHeader?.productType}</div>
            </Col>
            <Col className='p-3 border-end'>
                <small className='text-muted'>Insurer</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{policyHeader?.insurerName}</div>
            </Col>

            <Col className='p-3 border-end'>
                <small className='text-muted'>Member</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{policyHeader?.memberCount}</div>
            </Col>
            <Col className='p-3 border-end'>
                <small className='text-muted'>Premium</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{ FormatAmount(policyHeader?.totalPremium) }</div>
            </Col>
            <Col className='p-3 border-end'>
                <small className='text-muted'>Review date</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div>{ policyHeader && FormatDate(policyHeader?.reviewDate)}</div>
            </Col>
            <Col className='p-0'>
                <ActionButton clickHandler={handleClick} icon="icon-arrow-chevron-right" />
            </Col>
        </Row>
    )
}

export default PolicyHeaderListItem