import { Skeleton } from '@progress/kendo-react-indicators';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ActionButton from '../../app/common/form/ActionButton';
import InfoToolTip from '../../app/common/form/InfoToolTip';
import { useStore } from '../../app/stores/store';
import { FormatAmount, FormatDate, UserHasPermission } from '../../app/utils/utils';
import { ClaimDetails } from '../../models/claim';
import { ClaimStatus } from '../../models/claimSearch';
import { CoverType } from '../../models/policyHeader';

interface ClaimsInfoProps {
    claim?: ClaimDetails,
    editMode: any
}

const ClaimsInfo = ({claim, editMode}: ClaimsInfoProps ) => {

    const {userStore} = useStore();
    const {userClaims} = userStore;
    
    const handleEditMode = () => {
        editMode(true)
    }

    return (
        <>
            <div className='claim-form'>
                <Row className='border-bottom m-0'>
                    <Col className='col-3 p-3 '>
                        <label className='text-muted'>Employee ID</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            <strong>{claim?.employeeId}</strong>
                        </div>
                    </Col>
                    <Col className='col-3 p-3 '>
                        <label className='text-muted'>Surname</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            <strong>{claim?.surname}</strong>
                        </div>
                    </Col>
                    <Col className='col-3 p-3 '>
                        <label className='text-muted'>First name</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            <strong>{claim?.firstname}</strong>
                        </div>
                    </Col>
                    <Col className='col-2 p-3' />
                    
                    { UserHasPermission(['PortalAdmin'], userClaims?.role) &&
                    <Col className='col-1 p-0 border-start'>
                        <ActionButton clickHandler={handleEditMode} icon='icon-pencil' />
                    </Col>
                    }
                </Row>
                <Row className='border-bottom m-0'>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Notification</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatDate(claim?.notificationDate) }
                        </div>
                    </Col>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Type</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            {claim?.coverType}
                        </div>
                    </Col>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Decision date</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatDate(claim?.decisionDate) }
                        </div>
                    </Col>
                    <Col className='col-3 p-3'>
                        <label className='text-muted'>Insurer claim #</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            {claim?.insurerClaimNo} &nbsp;
                        </div>
                    </Col>
                </Row>

                {claim?.coverType === CoverType.GSC &&
                <Row className='border-bottom m-0'>
                    <Col className='col-6 p-3 border-end'>
                        <label className='text-muted'>Waiting period</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            {claim?.waitingPeriod} &nbsp;
                        </div>  
                    </Col>
                    <Col className='col-6 p-3'>
                        <label className='text-muted'>Benefit period</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            {claim?.benefitPeriod} &nbsp;
                        </div>
                    </Col>
                </Row>
                }

                <Row className='border-bottom m-0'>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Disablement date</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatDate(claim?.disablementDate) } &nbsp;
                        </div>    
                    </Col>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Assessed benefit</label>&nbsp;
                        <InfoToolTip id='aba-info' toolTipMessage='Claim benefit which has been accepted by insurer, please enter the lump sum for Death or TPD claim; or the monthly benefit for GSC claim.' />
                            
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatAmount(claim?.assessedBenefit)} &nbsp;
                        </div>  
                    </Col>
                    <Col className='col-6 p-3'>
                        <label className='text-muted'>Decision</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { claim && Object.values(ClaimStatus)[parseInt(claim.decision)] } 
                        </div> 
                    </Col>
                </Row>
                
                <Row className='m-0'>
                    <Col className='col-3 p-3 border-end'>
                        <label className='text-muted'>Benefit start</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatDate(claim?.benefitStartDate) } &nbsp;
                        </div>
                    </Col>
                    <Col className='col-6 p-3 border-end'>
                        <label className='text-muted'>Cumulative payment pre-tax</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatAmount(claim?.cumulativePayment) }  &nbsp;
                        </div>
                    </Col>
                    <Col className='col-3 p-3'>
                        <label className='text-muted'>Close date</label>
                        <div>
                            {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { FormatDate(claim?.closeDate) }  &nbsp;
                        </div>
                    </Col>
                </Row>

                { UserHasPermission(['PortalAdmin'], userClaims?.role) &&
                    <Row className='border-top m-0'>
                        <Col className='p-3'>
                            <label className='text-muted'>Notes</label>
                            <div  style={{whiteSpace: "pre-line"}} >
                                {!claim && <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                {claim?.notes}   &nbsp;
                            </div>
                        </Col>
                    </Row>
                }

            </div>
        </>
    )
}

export default observer(ClaimsInfo) 