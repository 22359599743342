import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg'
import MenuLinkItem from "../common/nav/MenuLinkItem";
import {Button, Container, Nav, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import NavSubMenuComponent from './NavSubMenuComponent';
import { useHistory } from 'react-router-dom';
import { IsEnvDev, UserHasPermission } from '../utils/utils';

const NavMainMenu = () => {
    const { userStore } = useStore();
    const history = useHistory();
    const { user, userClaims, groupMasterId, signoutRedirect } = userStore;

    const allPoliciesLink = () => {
        return (userClaims?.role.includes('PortalAdmin')) ? '/policies/search': `/group-master/${groupMasterId}`;
    }
    
    const allPoliciesLabel = () => {
        return (userClaims?.role.includes('PortalAdmin')) ? 'All policies': `Policies`;
    }

    const isBasicUser = UserHasPermission(['CerteBasicUser'], userClaims?.role);
        
    return (
        <>
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand onClick={() => history.push('/')}>
                    <LogoSvG className='logo' />
                </Navbar.Brand>
                { user && 
                    <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {isBasicUser ? '' 
                                    : <>
                                        <Nav.Link as={MenuLinkItem} to="/dashboard" label='Dashboard' icon='icon-dashboard'/>
                                        <Nav.Link as={MenuLinkItem} to={allPoliciesLink()} label={allPoliciesLabel()} icon='icon-policy'/>
                                        <Nav.Link as={MenuLinkItem} to="/members/search" label='All Members' icon='icon-member' />
                                    </>
                                }                               
                            </Nav>
                            <Nav>
                                <Navbar.Text className='me-3'>
                                { (IsEnvDev()) 
                                    ? 
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id='username'>
                                                    Group ID: {userClaims?.groupId } <br/>
                                                    Group Master: {groupMasterId}    
                                                </Tooltip>
                                            }>
                                            <span>{ user?.profile.name }</span>
                                        </OverlayTrigger>
                                    : <span>{ user?.profile.name }</span> 
                                }
                                    
                                </Navbar.Text>
                                <Button variant="link" onClick={ () => signoutRedirect() }>LOG OUT</Button>
                            </Nav>
                        </Navbar.Collapse>
                    </>
                }
            </Container>
        </Navbar>
        <NavSubMenuComponent />
        </>
    )
}

export default observer(NavMainMenu)