import { getter } from "@progress/kendo-react-common";
import { 
  GRID_COL_INDEX_ATTRIBUTE,
    GridColumn as Column,
    getSelectedState, 
    Grid, 
    GridHeaderSelectionChangeEvent, 
    GridSelectionChangeEvent, 
    GridCellProps,
    GridNoRecords,
    GridSortChangeEvent} from "@progress/kendo-react-grid";
import { useCallback, useEffect, useState } from "react";
import { CampaignMemberItem } from "../../models/campaign";

import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Skeleton } from "@progress/kendo-react-indicators";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { isEmailValid } from "../../app/utils/utils";

export interface PolicyCampaignDatagridProps {
  members?: CampaignMemberItem[] 
}

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const PolicyCampaignDatagrid = ({members}: PolicyCampaignDatagridProps) => {
  const [sort, setSort] = useState<SortDescriptor[]>([]);
    const {campaignStore} = useStore();
    const {updateDatagridState, selectableMembers} = campaignStore;

    const [dataState, setDataState] = useState<CampaignMemberItem[] | undefined>(
        members?.map((dataItem: CampaignMemberItem) =>
          Object.assign({ selected: false }, dataItem)
        ) || []
      );

    useEffect(() => {
      setDataState(members?.map((dataItem: CampaignMemberItem) =>
        Object.assign({ selected: false }, dataItem)
      ))
    }, [members])
    
    
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
      }>({});

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {

          if(selectableMembers()?.length === 0) return;
          
          // get currently selected row
          const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
          });

          // get the clicked row id. When a user clicks on the checkbox, get the value from dataItem.
          const selectedMemberId = (event.dataItem) ? event.dataItem[DATA_ITEM_KEY] : Object.keys(newSelectedState)[0];
          
          // check state of selected ite in dataItems list
          const memberDataItem = event.dataItems.find(x => x.id === selectedMemberId)
          const selectedMemberIdState = memberDataItem?.selected || false;
          
          if(!isEmailValid(memberDataItem?.email)) return;

          // toggle status
          const updatedState = {
            ...selectedState, 
            [selectedMemberId]: !selectedMemberIdState
          }

          setSelectedState(updatedState);
          updateDatagridState(updatedState);
        },
        [selectableMembers, selectedState, updateDatagridState]
      );
  
    const onHeaderSelectionChange = useCallback(
      (event: GridHeaderSelectionChangeEvent) => {
        const checkboxElement: any = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState: any = {};
        const newDataGridState: any = {};

        if(checked) {
          checkboxElement.classList.add('k-state-unselectable')
        }else{
          checkboxElement.classList.remove('k-state-unselectable')
        }

        if(selectableMembers()?.length > 0) {
  
          event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
            if(isEmailValid(item.email)) {
              newDataGridState[idGetter(item)] = checked;
            }
          });
          setSelectedState(newSelectedState);
          updateDatagridState(newDataGridState);
        }
      },
      [selectableMembers, updateDatagridState]
    );

    const CustomCell = (props: GridCellProps) => {
      const field = props.field || "";
      const value = props.dataItem[field];
      const navigationAttributes = useTableKeyboardNavigation(props.id);
      const hasEmail = isEmailValid(props.dataItem.email);

      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
        >
          <Checkbox value={(hasEmail) ? value : null} disabled={!hasEmail} />
        </td>
      )
    }

    return (
        <>
          <Grid
            data={  orderBy(dataState?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })) || [], sort)
            }
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}

            selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            className='campaign-table'>
              <Column
                field={SELECTED_FIELD}
                width="50px"
                headerSelectionValue={
                    dataState?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                } 
                cell={CustomCell} />
              <Column field="email" title="Email" />
              <Column field="employeeId" title="Employee Id" width={130} />
              <Column field="name" title="Name" width={250} />
              <Column field="state" title="State"  width={80} />
              <Column field="category" title="Category"  width={100} />
              <Column field="salary" title="Salary" filter="numeric" format="{0:c}" />
              <Column field="benefit" title="Current Benefit" filter="numeric" format="{0:c}" width={145} />
              <Column field="maxDefaultBenefit" title="Potential Benefit" filter="numeric" format="{0:c}" width={155} />
              <GridNoRecords >
                { (!members) 
                      ? <div className='text-start'>
                              <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                          </div>
                      : 'No results found.' }
              </GridNoRecords>
          </Grid>
        </>
    )
}

export default observer(PolicyCampaignDatagrid);