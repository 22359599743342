import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import {Helmet} from 'react-helmet'

interface ClaimBrochuresFormProps {
    policyHeader?: PolicyHeaderListItem
}

const ClaimBrochuresForm = ({policyHeader}: ClaimBrochuresFormProps) => {
    const headerTitle = 'Claim brochures & forms';
    const headerDescription = (
            <>
               <p>You will use this upload function if you would like a new claim material to be viewed and downloadable by your client under their online portal.</p> 
               <p>These are:</p>
                <ul>
                    <li>insurer claim forms available under this policy</li>
                    <li>general claim information brochure that you have prepared for your client</li>
                    <li>claim process overview for this policy that you have prepared for your client</li>
                </ul>
            </>
        )
        
    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.ClaimBrochureForms,
        file: undefined,
        enableCustomFilename: true,
        inputFileName: ''
    }
    
    return (
        <>  

            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.DataCollection} 
                        initialValues={initialValues} 
                        enableCustomFilename      
                    />
            }
        </>
    )
}

export default observer(ClaimBrochuresForm)