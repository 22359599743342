export enum DashboardMessageType {
    info = 'info',
    danger = 'danger',
    warning = 'warning',
}

export interface DashboardMessage {
    messageType: DashboardMessageType,
    messageBody: string,
    messageLink: string
}