import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../app/stores/store";

function ServerError() {
    const {commonStore} = useStore();

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h1>Server Error</h1>
                    <h5>{commonStore.error?.message}</h5>
                    <small>Stack Trace:</small>
                   
                    <pre className='bg-light p-3 text-muted'>
                    <code className='mt-3'>
                        <small>{commonStore.error?.details}</small>
                    </code>
                    </pre>
                 
                </div>
            </div>
        </>
    )
}

export default observer(ServerError);