import { ListItemProps, AutoComplete } from '@progress/kendo-react-dropdowns';
import React, { cloneElement, useCallback, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { useStore } from '../../app/stores/store';

interface ClaimsMemberSearchBarProps {
    policyHeader: string,
    placeholder: string,
    selectOnEnter?: boolean
}


// TODO: create a search bar that accepts generic T 
const ClaimsMemberSearchBar = ({policyHeader, placeholder, selectOnEnter}: ClaimsMemberSearchBarProps) => {

    const {searchClaimStore} = useStore();
    const { results, search, setPolicyHeader, resetSearch} = searchClaimStore;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    const searchClaims = useCallback( async () => {
        if(searchKeyword.length > 0 && searchKeyword.length < 2) {
            setIsLoading(false)   
            return;
        }else{
            setIsLoading(true);
            return await search(searchKeyword, 1).then(()=>{
                setIsLoading(false);
            })
        }
    },[search, searchKeyword]);

    useEffect(()=>{
        setPolicyHeader(policyHeader);
        return resetSearch();
    }, [setPolicyHeader, policyHeader, resetSearch]);

    // on keyword or review date handler
    useEffect(() => {
        searchClaims();
        
        return () => {
            ///nothing ib here...
        }
    },[policyHeader, searchKeyword, searchClaims])

    const onChange = (event: any) => {
        if (!selectOnEnter && event.nativeEvent.keyCode === 13) {
            return;
        }

        setShowSuggestion(false);
        setSearchKeyword(event.value);
    }

    const onClose = () => {
        setShowSuggestion(false)
    }

    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
          <div className="autocomplete-suggestion member-suggestion">
            {li.props.children} 
          </div>
        );
    
        return cloneElement(li, li.props, itemChildren);
    };

    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        return cloneElement(element, { ...element.props }, 'No results found');
    };

    return (
        <>
            <Row>
                <Col>
                    <AutoComplete
                        data={results}
                        textField={'memberName'}
                        onChange={onChange}
                        onClose={onClose}
                        placeholder={placeholder}
                        value={searchKeyword}
                        opened={showSuggestion}
                        loading={isLoading}
                        itemRender={itemRender}
                        listNoDataRender={listNoDataRender}
                        popupSettings={{
                            className: 'autocomplete-suggestions'
                        }}
                        className="form-control form-control-lg w-100 search-box "
                    />
                </Col>
            </Row>
        </>
    )
}

export default ClaimsMemberSearchBar