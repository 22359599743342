import { Skeleton } from '@progress/kendo-react-indicators';
import { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap'
import { useStore } from '../../app/stores/store';

interface InsuredBenefitsProps {
    policyHeaderGuid?: string,
}

const InsuredBenefits = ({policyHeaderGuid}: InsuredBenefitsProps) => {

    const {policyHeaderStore, userStore} = useStore();
    const {userClaims} = userStore;
    const {getInsuredBenefits} = policyHeaderStore;
    const [insuredBenefits, setInsuredBenefits] = useState<string[]>([]);

    useEffect(() => {
        if(policyHeaderGuid) {
            getInsuredBenefits(policyHeaderGuid).then(benefits => {
                setInsuredBenefits(benefits);
            })
        }
    },[getInsuredBenefits, policyHeaderGuid, userClaims?.role])

    return (
        <>
            <ListGroup>
                <ListGroup.Item className='p-4'>
                    <h3 className='m-0'>Insured Benefits</h3>
                </ListGroup.Item>
                { !insuredBenefits &&
                    <>
                        <ListGroup.Item className='p-4 insured-benefits' key='s1'>
                            <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                        </ListGroup.Item>
                        <ListGroup.Item className='p-4 insured-benefits' key='s2'>
                            <Skeleton shape={"text"} style={{ width: "60%" }} /> 
                        </ListGroup.Item>
                    </>
                }
                
                { insuredBenefits && 
                    <>
                        <ListGroup.Item className='p-4 pt-0 insured-benefits'>
                            { insuredBenefits?.map( (benefit, index) => {
                                return (
                                        <div className='mt-3' key={index}>
                                            <strong > 
                                                <span dangerouslySetInnerHTML={{__html: benefit}}  />
                                            </strong>
                                        </div>
                                    );
                                })    
                            }
                            <div className='mt-3'> Please refer to the policy document for details of the insured benefits.</div>
                        </ListGroup.Item>
                    </>
                }
            </ListGroup>
        </>
    )
}

export default InsuredBenefits