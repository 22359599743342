import { useEffect, useRef } from 'react';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PolicyHeaderListItem } from '../../../models/subMenu';
import LoaderComponent from '../../../app/layout/LoaderComponent'
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Row, Col} from 'react-bootstrap';
import { ClaimStatus } from '../../../models/claimSearch';
import { NewClaim } from '../../../models/claim';
import { FormikAmountInput, FormikDateInput, FormikInput, FormikMemberSelect } from '../../../app/common/form/FormikForm';
import { useHistory } from 'react-router-dom';
import { removeNulls } from '../../../app/utils/utils';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import { AxiosError } from 'axios';
import { ProductType } from '../../../models/policyHeader';

interface ClaimsFormProps {
    policyHeader: PolicyHeaderListItem
} 

const NewClaimForm = ({policyHeader}: ClaimsFormProps) => {
    const {searchMemberStore, claimsStore, noticeStore, preSelectStore} = useStore();
    const {member, setMember} = preSelectStore;
    const {selectedMember} = searchMemberStore;
    const history = useHistory();

    //type FormValues = {};
    const formRef = useRef<FormikProps<NewClaim>>(null);
    
    const ValidationScheme = Yup.object().shape({
        memberGuid: Yup.string().required('Select a member'),
        NotificationDate: Yup.date().nullable().typeError('Notification must be formatted as dd/mm/yyyy').required('Notification is required'),
        coverType: Yup.string().required('Required'),
        decisionDate: Yup.date()
            .nullable()
            .when(
                'closeDate',
                (closeDate, schema) => removeNulls(closeDate) && schema.max(closeDate, "Decision date cannot be after Close date.")
            ),
        insurerClaimNo: Yup.string().nullable().max(20, "Maximum 20 characters"),
        waitingPeriod: Yup.string().nullable().max(50, "Maximum 50 characters"),
        benefitPeriod: Yup.string().nullable().max(50, "Maximum 50 characters"),
        disablementDate: Yup.date().max(
            Yup.ref('NotificationDate'),
            "Disablement date cannot be after Notification date.",
          ).nullable(),
          //.transform((_, val) => val === val ? val : '') ,
        assessedBenefit: Yup.number().typeError('Required').min(0).max(9999999.99),
        decision: Yup.string().required(),
        benefitStartDate: Yup.date()
            .nullable()
            .when(
                'disablementDate',
                (disablementDate, schema) => removeNulls(disablementDate) && schema.min(disablementDate, "Benefit start date cannot be before Disablement date.")
            ),
        closeDate: Yup.date()
            .nullable()
            .when(
              'benefitStartDate',
              (benefitStartDate, schema) => removeNulls(benefitStartDate) && schema.min(benefitStartDate, "Close date cannot be before Benefit start date.")
            ),
        notes: Yup.string(),
    })

    useEffect(() => {
        formRef.current?.setFieldValue('memberGuid', selectedMember?.memberInfo.id || member?.id  || '')

        return () => {
            setMember()
        }
    },[formRef, member?.id, selectedMember, setMember]);


    if(!policyHeader) return <LoaderComponent content='loading claim form...'/>
    
    const NewClaimInitialValues: NewClaim = {
        policyHeaderGuid: policyHeader.id, 
        memberGuid: member?.id || '',
        NotificationDate: new Date(),
        coverType: policyHeader.coverTypes.length === 1 ? policyHeader.coverTypes[0] : '',
        decisionDate: undefined,
        insurerClaimNo: '',
        waitingPeriod: '',
        benefitPeriod: '',
        disablementDate: undefined,
        assessedBenefit: undefined,
        decision:  Object.keys(ClaimStatus)[Object.values(ClaimStatus).indexOf(ClaimStatus.InitialAssessment)],
        benefitStartDate: undefined,
        closeDate: undefined,
        notes: ''
    }    

    const handleSubmit = async (values: NewClaim, actions: any) => {
        await claimsStore.newClaim(values).then((response) => {          
            history.push(`/claims/${response?.id}`);

        }).catch( (error: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: "An error occured, please try again.",
                statusCode: 200,
            };

            noticeStore.setNotice(notice);
        })
        actions.setSubmitting(false);
    }

    const handleCancel = () => {
        history.push(`/policy-headers/${policyHeader.id}/claims`)
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={NewClaimInitialValues}
                validationSchema={ValidationScheme}
                onSubmit={handleSubmit}>

                { props => (
                <Form >
                    <FormikMemberSelect 
                        memberName={member?.label}
                        memberGuid={member?.id}
                        policyHeaderGuid={policyHeader.id}
                        focusOnLoad
                        suggestDjp
                        suggestDob
                        suggestPolicyNum
                        />
                    <div className='claim-form border'>
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="NotificationDate" label={'Notification'} />      
                            </Col>

                            <Col className='col-3 p-3 border-end'>
                                <label className='text-muted'>Type</label>
                                <Field as='select' name="coverType" className='form-control form-select'>
                                    {/** only show dropdown when there's multiple covertype available */}
                                    { policyHeader.coverTypes.length > 1 && <option value=''>Select</option> }
                                    {
                                        policyHeader.coverTypes.map( (coverType, index) => (
                                            <option value={coverType} key={index}>{coverType}</option>
                                        ))
                                    }
                                </Field>     
                                <ErrorMessage name="coverType" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                } />       
                            </Col>

                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="decisionDate" label={'Decision date'} />      
                            </Col>
                            
                            <Col className='col-3 p-3 border-end'>
                                <FormikInput name="insurerClaimNo" label='Insurer claim #' />
                            </Col>
                        </Row>

                        {policyHeader.productType === ProductType.GSC &&
                        <Row className='border-bottom m-0'>
                            <Col className='col-6 p-3 border-end'>
                                <FormikInput name="waitingPeriod" label='Waiting period' />       
                            </Col>
                            <Col className='col-6 p-3'>
                                <FormikInput name="benefitPeriod" label='Benefit period' />
                            </Col>
                        </Row>
                        }

                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="disablementDate" label={'Disablement date'} />      
                            </Col>
                            <Col className='col-3 p-3 border-end'>
                                <FormikAmountInput name="assessedBenefit" label='Assessed benefit' format="c0"  placeholder={`$`}  /> 
                            </Col>
                            <Col className='col-6 p-3'>
                                <label className='text-muted'>Decision</label>
                                <Field as="select" name="decision" className="form-select" onChange={ (e: Event) => {
                                    const target = e.target as HTMLSelectElement;
                                    if(target.value !== ClaimStatus.Approved){
                                        // reset and disable the benefit startdate
                                        props.setFieldValue('benefitStartDate', null);
                                    }      
                                    props.handleChange(e);
                                }}>
                                    <option value=''>Select</option>
                                    {
                                        Object.keys(ClaimStatus).map((key, index) => (
                                            <option value={key} key={index}>{(ClaimStatus as any)[key]}</option>))
                                    }
                                </Field>
                                <ErrorMessage name="decision" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                    } />            
                            </Col>
                        </Row>
                        
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikDateInput  name="benefitStartDate" label={'Benefit start'} disabled={ props.values.decision !== ClaimStatus.Approved } /> 
                            </Col>
                            <Col className='col-6 p-3 border-end' />
                            <Col className='col-3 p-3'>
                                <FormikDateInput  name="closeDate" label={'Close date'} />        
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-3'>
                            <label  className='text-muted'>Notes</label>
                            <Field as="textarea" row='4' name="notes" className='form-control mb-3'/>
                            <ErrorMessage name="notes" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                } />   
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-4 pe-3 text-end'>
                            <button className='btn btn-stale me-2' onClick={handleCancel}>Cancel</button>
                            <button type='submit' className='btn btn-primary'>Save</button>
                            </Col>
                        </Row>

                    </div>
                </Form>
                )}
            </Formik>
        </>
    )
}

export default observer(NewClaimForm)