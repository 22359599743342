import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg'
import { Container } from "react-bootstrap";
import { useStore } from "../../app/stores/store";
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoaderComponent from '../../app/layout/LoaderComponent';

const LoginComponent = () => {
    const history = useHistory();
    const { userStore } = useStore();
    const {getCurrentSession} = userStore;

    const currentUser = useCallback(
      async () => {
        await getCurrentSession().then(user => {
            if(!user){
                userStore.signInRedirect();
            }else{
                history.push('/dashboard');
            }
        })
      },
      [getCurrentSession, history, userStore],
    )
    
    useEffect(()=> {
        currentUser()
    },[currentUser])

    return (
        <Container fluid>
            <div className="row d-flex align-items-stretch bg-white" style={{ height: "100vh" }} >
                <div className="col-4 d-flex align-self-center bg-light text-dark" style={{ height: "100%" }}>
                    <div className="align-self-center p-4">
                        
                        <LogoSvG className='mb-5' style={{ maxWidth: '100%'}} />
                        <h1>
                            <span className="text-muted">Imagine a world where </span>
                            financial worry is diminished...
                        </h1>
                        <div className="fixed-bottom p-4">
                            PART OF THE <br/>
                            AZ NGA GROUP
                        </div>
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <div className='row justify-content-center'>
                        <div className='col-6'>   
                            <LoaderComponent content='Checking user session...'/>
                            <small>If it doesn't redirect in 3 seconds, <a href="#login" onClick={() => userStore.signInRedirect()}>click here</a>.</small>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default LoginComponent;
