import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

const SilentRenew = () => {
    const { userStore } = useStore();
    useEffect(() => {
        userStore.signinSilentCallback();
    }, [userStore])

    return (
        <div>Refreshing token...</div>
    )
};

export default observer(SilentRenew)