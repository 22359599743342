import moment from "moment";
import { FileTypes } from "../../models/files";
import parsePath from 'parse-filepath';

export const FormatDate = (date?: Date | string | null) => {
    if(date){
        const parsedDate = (typeof date === 'string') ? moment(date, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate() : date;
        try {
            return new Intl.DateTimeFormat('en-GB').format(parsedDate);            
        } catch (error) {
            console.log('date error', error, date)
        }
    }
}

export const FormatAmount = (amount: number | string | undefined ) => {
    if(amount){
        const parsedAmount = (typeof amount === 'string') ? parseFloat(amount) : amount;
        return `$${new Intl.NumberFormat('en-GB').format(parsedAmount)}`
    }
}

export const PrettifyJson = (value: string) => {
    return JSON.stringify(value, null, 2);
}

export const IsGuid = (value: string) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(value);
}

export const UserHasPermission = (allowedRoles: string[], userRole?: string | string[]) => {
    
    if(!userRole) return false;
    
    if(Array.isArray(userRole)){        
        return userRole.filter(x => allowedRoles.includes(x)).length > 0;
    }else{
        return allowedRoles.indexOf(userRole) !== -1;
    }
}

export const EmailUsername = (email: string) => {
    return email.split("@",1)[0];
}

export const EncodeDataToURL = (data: {[key: string] : string|number}) => {
    return Object
      .keys(data)
      .map(value => {
          return `${value}=${encodeURIComponent(data[value])}`
      })
      .join('&');
}

export const ExtToIcon = (ext?: string) => {
    switch (ext?.toLocaleLowerCase()) {            
        case '.xls':
        case '.xlsx':
            return 'icon-xls';

        case '.pdf':
        case '.txt':
        case '.doc':
        case '.docx':
            return 'icon-document';

        case '.png':
        case '.jpg':
        case '.svg':
        case '.jpeg':
            return 'icon-image-line';

        case '.dir':
            return 'icon-folder';

        case '.zip':
            return 'icon-zip';

        default:
            return 'icon-file';
    }
}

export const GetFileInfo = (filePath: string) => {
    // fix filepath forward slashes
    filePath = filePath.replace(/\\/g, '/');
    const fileInfo = parsePath(filePath);
   
    return {
        basename: fileInfo.basename,
        name: fileInfo.name,
        extension: fileInfo.ext.toLocaleLowerCase(),
        icon: ExtToIcon(fileInfo.ext)
    }
}

export const DisplayIcon = (fileType: FileTypes) => {
    switch (fileType) {            
        case FileTypes.Excel || FileTypes.StaticFile:
            return 'icon-xls';

        case FileTypes.Folder:
            return 'icon-folder';

        case FileTypes.Image:
            return 'icon-image-line';

        default:
            return 'icon-document';
    }
}

export const ToSpineCase = (str: string) => {
    const spinal = str.replace(/(?!^)([A-Z])/g, ' $1').replace(/[_\s]+(?=[a-zA-Z])/g, '-').toLowerCase();
    return spinal 
}

export const removeNulls = (obj: any) =>  {
    if (obj === null) {
        return undefined;
    }
    return obj;
}

export const removeTime = (date: Date) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
}

export const isEmailValid = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const IsEnvDev = () => {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
}

const Utils = {
    FormatDate,
    FormatAmount,
    PrettifyJson,
    IsGuid,
    UserHasPermission,
    EmailUsername,
    EncodeDataToURL,
    GetFileInfo,
    DisplayIcon,
    ToSpineCase,
    removeNulls,
    removeTime,
    isEmailValid
}

export default Utils 