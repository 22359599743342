import { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import MemberSearchBar from '../../app/common/search-bars/MemberSearchBar';
import { useStore } from '../../app/stores/store';
import { IdTypes, SubLinks } from '../../app/stores/subMenuStore';
import MemberSearchDataGrid from '../../app/common/search-bars/MemberSearchDataGrid';
import agent from '../../app/api/agent';
import fileDownload from 'js-file-download';
import { InView } from 'react-intersection-observer';
import moment from "moment";
import mime from 'mime-types';
import pluralize from 'pluralize';
import { PolicyHeaderStatus } from '../../models/policyHeader';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { DataGridSorting, MemberDataGridColumn } from '../../models/memberSearch';
import { SortDescriptor } from '@progress/kendo-data-query';
import {Helmet} from 'react-helmet'

interface PolicyHeaderDetailsUrlParams {
    policyHeaderGuid: string
}

/**
 * 
 * This needs to be refactored. Policy header review date implementation is convoluted.
 */

const PolicyMembersComponent = () => {
    const history = useHistory();
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const {subMenuStore, searchMemberStore, policyHeaderStore, noticeStore, userStore} = useStore();
    const {policyHeaderGuid} = useParams<PolicyHeaderDetailsUrlParams>();
    const {currentPage, loadMore, pagination, resetSearch, reviewDate, dataGrid, sortSearch} = searchMemberStore; 
    const { groupMasterId } = userStore;
    const [policyReviewDate, setPolicyReviewDate] = useState<Date|undefined>()

    const handleLoadMore = (inView: boolean) => {
        if(pagination && currentPage < pagination.totalPages){
            if(inView ){
                loadMore();
            }
        }
    }

    const {
        policyHeader,
        setPolicyHeader, 
        getPolicyHeaderDetails
    } = policyHeaderStore;

    const {
        initPolicyHeader,
        setActivelink,
        setDisplay
    } = subMenuStore;

    const {
        push
    } = history;

    const {
        setNotice
    } = noticeStore;

    const latestReviewDate = () => {
        if(policyHeader?.policyHeaderInfo.status === PolicyHeaderStatus.PREVIOUS){
            const current = policyHeader?.policyHeaderInfo.otherStartDates.find(x => x.status === 'current')?.startDate;
            if(current) return current;

            return (policyHeader.policyHeaderInfo.otherStartDates.length !== 0) 
                        ? policyHeader.policyHeaderInfo.otherStartDates[0].startDate 
                        : policyHeader.policyHeaderInfo.reviewDate;

        }else{
            return policyHeader?.policyHeaderInfo.reviewDate;
        }
    }

    const onComponentLoad = useCallback(
      async () => {        
        // show submenu 
        setActivelink(SubLinks.members);
        initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        
        await getPolicyHeaderDetails(policyHeaderGuid).then(policyHeader => {
            setPolicyHeader(policyHeader);
            setPolicyReviewDate(policyHeader.policyHeaderInfo.reviewDate)
            
        }).catch((err: AxiosError) => {
            setNotice(null);
            setDisplay(false);
            if(err.response?.status === 400) push("/error/not-found");
            if(err.response?.status === 403) push("/error/forbidden");
        });
      },
      [getPolicyHeaderDetails, initPolicyHeader, policyHeaderGuid, push, setActivelink, setDisplay, setNotice, setPolicyHeader],
    )
    

    useEffect(()=>{
        onComponentLoad();
        return () => {
            resetSearch();
        }       
    }, [policyHeaderGuid, resetSearch, onComponentLoad]);

    const exportAll = () => {
        let formattedReviewDate  = moment(reviewDate || policyHeader?.policyHeaderInfo.reviewDate).format('YYYY-MM-DD');
        agent.DownloadReports.policyHeaderMembers(policyHeaderGuid, formattedReviewDate.toString()).then((blob: Blob) => {
            fileDownload(blob, `Policy Members - ${policyHeader?.policyHeaderInfo.policyNumber} (${formattedReviewDate}).${mime.extension(blob.type)}`);
        });
    }

    const campaignHandler = () => {
        history.push(`/policy-headers/${policyHeaderGuid}/campaign`);
    }

    const sortHandler = (e: GridSortChangeEvent) => {
        const fieldMap = {
            'memberInfo.policyNumber': 'PolicyNumber',
            'memberInfo.employeeID': 'EmployeeId',
            'memberInfo.name': 'MemberName',
            'memberInfo.dob': 'DOB',
            'memberInfo.joinedDate': 'DateJoined',
            'memberInfo.productType': 'GroupName',
            'memberInfo.category': 'Category',
            'memberInfo.benefitAmount': 'BenefitAmount',
            'memberInfo.premiumAmount': 'PremiumAmount'
        }

        if(e.sort.length > 0) {
            const sortField = e.sort[0].field!;
            const sortDir = (e.sort[0].dir! === 'asc') ? DataGridSorting.Ascending : DataGridSorting.Descending;
            const fieldName = fieldMap[sortField  as keyof typeof fieldMap];
            sortSearch(MemberDataGridColumn[fieldName as keyof typeof MemberDataGridColumn], sortDir)
        }else{
            sortSearch();
        }
        setSort(e.sort);
    }

    return (
        <>
            <Helmet title={`Policy members - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`}/>
            <Row className="mb-4">
                <Col>
                    <h1 className='header-title'>Policy members</h1>
                </Col>
                <Col className='text-end'>
                    { (policyHeader && policyHeader?.policyHeaderInfo.status.toLocaleLowerCase() !== PolicyHeaderStatus.PREVIOUS && groupMasterId === '0') && 
                        <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={campaignHandler}><i className='icon icon-envelop me-2' />Member Statements</Button>
                    }
                    <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={exportAll}> <i className='icon icon-download' /> Export all</Button>
                </Col>
            </Row>
            { policyHeader && policyReviewDate && 
                <>
                    <MemberSearchBar 
                        searchOnInit 
                        placeholder='Search policy members' 
                        withDataGrid
                        disableSuggestion
                        focusOnLoad
                        suggestedField='memberInfo.name'
                        policyHeaderReviewDate={latestReviewDate()}
                        policyHeaderId={policyHeaderGuid}
                        policyHeaderStatus={policyHeader?.policyHeaderInfo.status}
                        reviewDates={policyHeader?.policyHeaderInfo.otherStartDates} 
                        />
                
                    <Row className='mt-4'>
                        <Col>
                            <MemberSearchDataGrid results={dataGrid} sort={sort} sortHandler={sortHandler} />
                        </Col>
                    </Row>

                    {dataGrid &&                        
                        <Row className='mt-4'>
                            <Col className='text-end'>
                            {pagination?.totalResults} {pluralize('Result', pagination?.totalResults)} 
                            </Col>
                        </Row>
                    }
                </>
            }
            
            {pagination &&  dataGrid && pagination.pageSize <= dataGrid.length &&
            <Row>
                <Col className='text-center mt-4'>
                    
                    <InView as="div" onChange={handleLoadMore}>
                        { currentPage < pagination.totalPages  && 
                         <span className='text-muted'>loading...</span>
                        }
                    </InView>
                    
                </Col>
            </Row>
            }
        </>
    )
}

export default observer(PolicyMembersComponent)