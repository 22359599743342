import { Skeleton } from '@progress/kendo-react-indicators'
import fileDownload from 'js-file-download';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import InfoToolTip from '../../app/common/form/InfoToolTip';
import { useStore } from '../../app/stores/store';
import { FormatDate } from '../../app/utils/utils';
import { MemberDetails } from '../../models/member';
import {PolicyCoverDetails} from '../../models/policyCoverDetails';
import { PolicyHeaderStatus } from '../../models/policyHeader';
import mime from 'mime-types'
import { useState } from 'react';
import { AxiosError } from 'axios';
import { Notice, NoticeStates } from '../../app/stores/noticeStore';

interface Props {
    policy?: PolicyCoverDetails,
    member?: MemberDetails,
}

const PolicyCoverDetailsComponent = ({policy, member}: Props ) => {
    const {memberStore, noticeStore} = useStore();
    const [downloading, setDownloading] = useState<boolean>(false); 

    const downloadStatement = async () => {
        setDownloading(true);
        await memberStore.downloadStatement(member?.memberInfo.id!).then((blob) => {
                setDownloading(false);
                fileDownload(blob, `${member?.memberInfo.name}-${policy?.policyNumber}-statement.${mime.extension(blob.type)}`);
        }).catch( (err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to download file. Please try again.`,
                statusCode: 500,
            };
            setDownloading(false);
            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
        })
    }

    return (
        <div
            key={policy?.id} 
            className={'mt-4 mb-4 policy-cover-details ' + ( (policy?.status === 'In force') ? 'active' : 'inactive')} >  
            <Row className='border rounded-3 bg-white mt-2'>
                <Col className='d-grid align-content-between flex-wrap col-4 border-end p-3'>
                    <div>
                        <small className='text-muted'>Type</small>
                        { !policy && 
                            <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                        }
                        { policy &&
                            <h1 className='header-title'>{policy?.policyType}</h1>
                        }
                        <small className='text-muted'>Number</small>
                        <div>
                            { !policy && 
                                <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                            { policy &&
                                <>
                                {policy?.policyNumber || '00000'}
                                </>
                            }
                        </div>
                    </div>
                    { member && policy?.status !== PolicyHeaderStatus.PREVIOUS && policy?.policyType !== 'TPD' && !policy?.exitPolicy &&
                        <button className=' btn btn-flat-light-yellow-bg p-3' onClick={downloadStatement} disabled={downloading}>
                            <i className='icon icon-download me-2' /> 
                            { (downloading) ? 'Downloading...' :  'Statement' }
                        </button>
                    }   
                </Col>
                <Col className='col-8'>
                    <Row className='border-bottom'>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Insurer</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                {policy?.dataSource}
                                { policy &&
                                <>
                                {policy?.insurer}
                                </>
                            }
                            </div>
                        </Col>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Joined policy</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                { FormatDate(policy?.joinedPolicy) }
                            </div>
                        </Col>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Exit policy</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                {policy?.exitPolicy && FormatDate(policy?.exitPolicy)}
                            </div>
                        </Col>
                        <Col className='col-3 p-3 '>
                            <small className='text-muted'>Review date</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                { FormatDate(policy?.reviewDate)}
                            </div>
                        </Col>
                    </Row>
                    
                    {policy?.policyType === 'GSC' &&
                        <Row className='border-bottom'>
                            <Col className='col-6 p-3 border-end'>
                                <small className='text-muted'>Waiting period</small>
                                <div>
                                    { !policy && 
                                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                    { policy && `${policy?.waitingPeriod}` }  
                                </div>
                            </Col>
                            <Col className='col-6 p-3'>
                                <small className='text-muted'>Benefit period</small>
                                { policy?.policyType === 'GSC' && 
                                    <InfoToolTip id='benefit-period' toolTipMessage='Please refer to statement for details.' />
                                }
                                <div>
                                    { !policy && 
                                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                    { policy && `${policy?.benefitPeriod}` }  
                                </div>
                            </Col>
                        </Row>
                    }

                    <Row className='border-bottom'>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Cat</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                {policy?.category}
                            </div>
                        </Col>

                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Income</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }

                                <NumberFormat  
                                    value={policy?.income}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </Col>
                        <Col className='col-6 p-3 '>
                            <small className='text-muted'>Premium</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                <NumberFormat  
                                    value={policy?.premium}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>Status</small>
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                {policy?.status}
                            </div>
                        </Col>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted me-1'>Benefit</small>
                            { policy?.policyType === 'GSC' && 
                                <InfoToolTip id='benefit-info' toolTipMessage='This amount is a monthly benefit amount.' />
                            }
                    
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                                <NumberFormat  
                                    value={policy?.benefit}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </Col>
                        <Col className='col-3 p-3 border-end'>
                            <small className='text-muted'>FUL(LIMIT)</small>
                            { policy?.policyType === 'GSC' && 
                                <InfoToolTip id='ful-info' toolTipMessage='FUL - This limit is a monthly benefit limit.' />
                            }
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }

                                <NumberFormat  
                                    value={policy?.ful}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </Col>
                        <Col className='col-3 p-3 '>
                            <small className='text-muted'>ABA</small>
                            <InfoToolTip id='aba-info' toolTipMessage='This indicates the amount of additional benefits you can apply for subject to underwriting. Please contact Certe if you would like to explore more on this option.' />
                            <div>
                                { !policy && 
                                    <Skeleton shape={"text"} style={{ width: "80%" }} /> }

                                {/*TODO: replace with policy info*/ }
                                <NumberFormat  
                                    value={policy?.aba}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </div>
    )
}

export default observer(PolicyCoverDetailsComponent)
