import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { UserHasPermission } from '../../utils/utils';

type Props = {
    allowedRoles?: string[],
    children: React.ReactChild | React.ReactChild[];
  };

const ProtectedComponent = ({allowedRoles, children}: Props) => {
  const {userStore} = useStore();
  const {userClaims} = userStore;

  if(!allowedRoles) return <>{children}</>;
    
  if( allowedRoles && UserHasPermission(allowedRoles, userClaims?.role)) {
    return <>{children}</>;
  }else{
    return <></>
  }
};

export default observer(ProtectedComponent);