import React from "react";
import CerteLogo from '../../assets/img/Logo.png';

const ForgotPasswordComponent = () => {
    return (
            <div className="container-fluid">
            <div className="row d-flex align-items-stretch" style={{height:"100vh"}}>
                <div className="col-4 d-flex align-self-center bg-light text-dark " style={{height:"100%"}}>
                    <div className="align-self-center p-4">
                    <img src={CerteLogo} alt='Certe' />
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas repellat corrupti nemo nulla dolorem iusto ipsa laudantium dolores atque! Optio molestiae enim debitis? Inventore dolor provident ut tenetur aspernatur repudiandae.</p>
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    Redirect to IDS forgot password...
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordComponent;