import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from 'react-router-dom';

const UnderConstruction = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }

    return (
        <Row className='bg-white text-center p-5'>
            <Col>
                <h1>Page under construction</h1>
                <p> &#128679; We are working on it. &#128640; &#128119; </p>
                <Button onClick={handleClick} primary={true} look="flat"> Return to dashboard </Button>
            </Col>
        </Row>
    )
}


export default UnderConstruction