export enum PolicyType {
    Ordinary = "Ordinary",
    Superannuation = "Superannuation"
}

export enum ProductType {
    GSC = "GSC",
    GL = "GL"
}

export enum CoverType {
    GSC = "GSC",
    GL = "GL",
    TPD = "TPD"
}

export enum PolicyHeaderStatus {
    CURRENT = "current",
    PREVIOUS = "previous",
    InForce = "In Force" // this must be deprecated - use CURRENT instead.
}

export interface PolicyHeader {
    id: string,
    groupMasterGuid: string,
    groupGuid: string,
    policyHeaderGuid: string,
    policyNumber: string,
    groupName: string,
    productType: ProductType,
    insurerName: string,
    memberCount: number,
    totalPremium: number,
    reviewDate: Date,
    reRateDate: Date,
    status: PolicyHeaderStatus
}

export interface PolicyHeaderStartDate {
    policyHeaderGuid: string,
    startDate: Date,
    status: PolicyHeaderStatus
}

export interface PolicyHeaderInfo {
    id: string,
    policyNumber: string,
    groupName: string,
    productType: ProductType,
    insurer: string,
    reviewDate: Date,
    status: PolicyHeaderStatus,
    otherStartDates: PolicyHeaderStartDate[]
}


export interface PolicyHeaderDetails {
    policyHeaderInfo: PolicyHeaderInfo,
    categories: PolicyHeaderCategories
}

export interface PolicyHeaderCategories {
    gsc: PolicyDetails[],
    gl: PolicyDetails[],
    tpd: PolicyDetails[]
}

export interface PolicyDetails {
    coverType: CoverType,
    categoryNo: number,
    aal: number,
    eligibleMembers: string,
    benefitDesign: string,
    incomeDefinition: string,
    maxBenefit: number,
    expiryAge: number,
    rateExpiry: Date,
    waitingPeriod: number,
    benefitPeriod: number,
    tpdDefinition: string,
}
