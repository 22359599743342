import { TabStripSelectEventArguments, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { useStore } from "../../app/stores/store";
import { IdTypes, SubLinks } from "../../app/stores/subMenuStore";
import { ToSpineCase, UserHasPermission } from "../../app/utils/utils";
import ClaimBrochuresForm from "./forms/ClaimBrochuresForm";
import DataCollectionForm from "./forms/DataCollectionForm";
import DataQueryForm from "./forms/DataQueryForm";
import MemberMovementDataForm from "./forms/MemberMovementDataForm";
import PolicySummaryForm from "./forms/PolicySummaryForm";
import ServiceLevelAgreementForm from "./forms/ServiceLevelAgreementForm";
import UnderwritingDecisionForm from "./forms/UnderwritingDecisionForm";
import {Helmet} from 'react-helmet'

interface FileUploadComponentProps {
    policyHeaderGuid: string,
    actionType: string,
}

interface TabStripItem {
    title: string,
    component: ReactNode,
    access: string[]
}

function FileUploadComponent(){ 
    const {subMenuStore, userStore, preSelectStore} = useStore();
    const {userClaims} = userStore;
    const {policyHeader} = subMenuStore;
    const {policyHeaderGuid, actionType} = useParams<FileUploadComponentProps>();
    const [activeTab, setActiveTab] = useState<number>(0);

    const tabsList: TabStripItem[] = useMemo(() =>  {
        const tabs = [
            {
                title:"member movement data",
                component: <MemberMovementDataForm policyHeader={policyHeader} />,
                access: ['PortalAdmin','PortalUser', 'CerteBasicUser']
            },
            {
                title:"data collection",
                component: <DataCollectionForm policyHeader={policyHeader} />,
                access: ['PortalAdmin','PortalUser', 'CerteBasicUser']
            },
            {
                title:"data query",
                component: <DataQueryForm policyHeader={policyHeader} />,
                access: ['PortalAdmin','PortalUser', 'CerteBasicUser']
            },
            {
                title:"policy summary",
                component: <PolicySummaryForm policyHeader={policyHeader} />,
                access: ['PortalAdmin']
            },
            {
                title:"service level agreement",
                component: <ServiceLevelAgreementForm policyHeader={policyHeader} />,
                access: ['PortalAdmin']
            },
            {
                title:"underwriting decision letter",
                component:<UnderwritingDecisionForm 
                            policyHeader={policyHeader}
                            memberGuid={ preSelectStore.member?.id }
                            memberName={ preSelectStore.member?.label }
                             />,
                access: ['PortalAdmin']
            },
            {
                title:"claim brochures & forms",
                component: <ClaimBrochuresForm policyHeader={policyHeader}  />,
                access: ['PortalAdmin']
            }
        ];

        const roleTabs = tabs.filter(t => UserHasPermission(t.access, userClaims?.role));

        // if there's an actionType, activate the corresponding tab
        roleTabs.forEach((t, index) => {
            if(t.title.toLocaleLowerCase() === actionType?.toLocaleLowerCase()) {
                setActiveTab(index + 1); // offset first tab
            }
        });

        return roleTabs;
    },[actionType, policyHeader, preSelectStore.member?.id, preSelectStore.member?.label, userClaims?.role]);

    useEffect(() => {
        subMenuStore.initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        subMenuStore.setActivelink(SubLinks.files);

        return () => {
            preSelectStore.setMember();
        }
    }, [policyHeaderGuid, subMenuStore, actionType, preSelectStore])

    const handleSelect = (e: TabStripSelectEventArguments) => {
      setActiveTab(e.selected);
    };

    return (
        <>
            <Helmet title={`Policy upload file - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`}/>
            <Row className="mb-4">
                <Col>
                    <h1 className='header-title'>Upload file</h1>
                </Col>
            </Row>

            <Row>
                <Col className="m-0 p-0 border rounded bg-white">
                    <TabStrip 
                        selected={activeTab}
                        onSelect={handleSelect}
                        tabPosition='left'
                    >
                        <TabStripTab title="File types" key={'tabstrip-header'}>
                            <Row>
                                <Col className="col-8">
                                <h2>
                                    <i className="icon icon-question-mark" />
                                    <div>
                                    What type of file<br/>
                                    are you uploading?
                                    </div>
                                </h2>
                                <p>Please select your file type from the list on the left and follow the prompts to upload your file.</p>
                                </Col>
                            </Row>
                        </TabStripTab>
                        {
                            tabsList.filter(t => UserHasPermission(t.access, userClaims?.role) ).map( (t, index) => (
                                <TabStripTab 
                                    title={t.title} 
                                    key={`tabstrip-${ToSpineCase(t.title)}-${index}`}>
                                    {t.component}
                                </TabStripTab>
                            ))
                        }
                    </TabStrip>
                </Col>
            </Row>
        </>
    )
}


export default observer(FileUploadComponent)