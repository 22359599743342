import { useRef } from 'react';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PolicyHeaderListItem } from '../../../models/subMenu';
import LoaderComponent from '../../../app/layout/LoaderComponent'
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Row, Col} from 'react-bootstrap';
import { ClaimNotification } from '../../../models/claim';
import { FormikDateInput, FormikInput, FormikMaskedInput } from '../../../app/common/form/FormikForm';
import { useHistory } from 'react-router-dom';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import { AxiosError } from 'axios';

interface ManualClaimNotificationFormProps {
    policyHeader?: PolicyHeaderListItem,
    manualEntry?: any
} 

const ManualClaimNotificationForm = ({policyHeader, manualEntry}: ManualClaimNotificationFormProps) => {
    const {claimsStore, noticeStore} = useStore();
    const history = useHistory();
    const formRef = useRef<FormikProps<ClaimNotification>>(null);
    
    const ValidationScheme = Yup.object().shape({
        employeeId: Yup.string().nullable(),
        surname: Yup.string().required(),
        firstname: Yup.string().required(),
        dob: Yup.date().typeError('The date is required and must be in the format dd/mm/yyyy').required(),
        phone: Yup.string().required(),
        email: Yup.string().email().required(),
        notes: Yup.string()
    })
    
    if(!policyHeader) return <LoaderComponent content='loading claim form...'/>
    
    const NewClaimInitialValues: ClaimNotification = {
        policyHeaderGuid: policyHeader.id, 
        employeeId: '',
        surname: '',
        firstname: '',
        dob: undefined,
        phone: '',
        email: '',
        notes: '',
        productType: policyHeader.productType
    }    

    const handleSubmit = async (values: ClaimNotification, actions: any) => {
        await claimsStore.newClaimNotification(values).then((response) => {          
            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: "Notification sent!",
                statusCode: 200,
                flashMessage: true
            };
            
            noticeStore.setNotice(notice);
            history.push(`/policy-headers/${policyHeader.id}/claims`);

        }).catch( (error: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: "An error occured, please try again.",
                statusCode: 200
            };

            noticeStore.setNotice(notice);
        })
        actions.setSubmitting(false);
    }

    const handleCancel = () => {
        manualEntry(false)
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={NewClaimInitialValues}
                validationSchema={ValidationScheme}
                onSubmit={handleSubmit}>
                { props => (
                <Form >    
                    <div className='claim-form border'>
                        <Row className='border-bottom m-0'>
                            <Col className='col-3 p-3 border-end'>
                                <FormikInput name="employeeId" label='Employee ID' />
                            </Col>
                            <Col className='col-3 p-3 '>
                                <FormikInput name="surname" label='Surname*' />
                            </Col>
                            <Col className='col-3 p-3 '>
                                <FormikInput name="firstname" label='First name*' />
                            </Col>
                            <Col className='col-3 p-3 '>
                                <FormikDateInput  name="dob" label={'DOB*'} />   
                            </Col>
                        </Row>
                    
                    
                        <Row className='border-bottom m-0'>
                            <Col className='col-4 p-3 border-end'>
                            <FormikMaskedInput 
                                    name="phone" 
                                    label='Phone number*' 
                                    mask="(+99) 000 000 000"
                                    placeholder="(+61) 000 000 000" />
                            </Col>
                            <Col className='col-8 p-3 '>
                                <FormikInput name="email" label='Email*' />
                            </Col>
                        </Row>
       
                        <Row className='border-bottom m-0'>
                            <Col className='p-3'>
                            <label  className='mb-2 text-muted'>Notes</label>
                            <Field as="textarea" row='4' name="notes" className='form-control mb-3'/>
                            <ErrorMessage name="notes" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                } />   
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-4 pe-3 text-end'>
                            <button className='btn btn-stale me-2' onClick={handleCancel}>Cancel</button>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                            </Col>
                        </Row>

                    </div>
                </Form>
                )}
            </Formik>
        </>
    )
}

export default observer(ManualClaimNotificationForm)