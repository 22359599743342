import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import {Helmet} from 'react-helmet'

interface PolicySummaryFormProps {
    policyHeader?: PolicyHeaderListItem
}

const PolicySummaryForm = ({policyHeader}: PolicySummaryFormProps) => {
    const headerTitle = 'Policy summary';
    const headerDescription = (
            <>
               <p>You are about to upload a policy summary that you have helped your client to highlight key features of their policy. Once uploaded, it will replace the existing summary, if any, and will be displayed under the Employer portal.</p>
            </>
        )
        
    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.PolicySummary,
        file: undefined
    }

    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.PolicySummary} 
                        initialValues={initialValues}          
                    />
            }
        </>
    )
}

export default observer(PolicySummaryForm)