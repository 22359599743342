import { Log, UserManager, WebStorageStateStore } from "oidc-client";

const userStore = new WebStorageStateStore({ prefix:'certe_', store: window.localStorage });

const config = {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    response_type: "id_token token",
    scope: process.env.REACT_APP_SCOPE,
    automaticSilentRenew: false,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
    userStore: userStore
};

const metadata = {
    issuer: process.env.REACT_APP_AUTH_URL,
    jwks_uri: process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
    end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
    check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
    introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect"
};
  
Log.logger = console;
Log.level = parseInt(process.env.REACT_APP_OIDC_LOG_LEVEL || '0');

export class OidcService {
    userManager!: UserManager;
    userStore!: WebStorageStateStore
    
    constructor() {
        this.userManager = new UserManager({
            ...config,
             metadata: {...metadata}
        });
    }
}

export default new OidcService();