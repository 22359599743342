import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import {Helmet} from 'react-helmet'

interface DataCollectionFormProps {
    policyHeader?: PolicyHeaderListItem
}

const DataCollectionForm = ({policyHeader}: DataCollectionFormProps) => {
    const headerTitle = 'Data collection';
    const headerDescription = (
            <>
               <p>When the annual review date is approaching for your policy, a request for data collection to commence renewal process will be issued to you with instructions for data required. You do not need to upload any data here if you have not received the request. </p>
                <p>If you need to upload the completed data collection in Excel, please select from below the policies that the data you are about to upload will apply for, then upload.</p>
            </>
        )
        
    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.DataCollection,
        file: undefined,
        policyHeaderReviewDate: undefined,
    }
    
    return (
        <>  
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.DataCollection} 
                        initialValues={initialValues}         
                        enableReviewDateSelection={policyHeader.isCurrent}
                        // enablePolicySelection={policyHeader.isCurrent}   
                    />
            }
        </>
    )
}

export default observer(DataCollectionForm)