import { makeAutoObservable } from "mobx";

interface PreSelectValue {
    id?: string,
    label?: string
}

/**
 * This store will be used to pass pre-selected values across components.
 */

export default class PreSelectStore {
    member?: PreSelectValue;
    policyHeader?: PreSelectValue;

    constructor(){
        makeAutoObservable(this);
    }

    setMember = (value?: PreSelectValue) => {
        this.member = value;
    }

    setPolicyHeader = (value?: PreSelectValue) => {
        this.policyHeader = value;
    }

    resetPreSelectedValues = () => {
        this.member = undefined;
        this.policyHeader = undefined;
    }
}