import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { memberSearchResult } from '../../../models/memberSearch';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Skeleton } from '@progress/kendo-react-indicators';

interface MemberSearchDataGridProps {
    results?: memberSearchResult[],
    sort: SortDescriptor[],
    sortHandler: (e: GridSortChangeEvent) => void
}

const MemberSearchDataGrid = ({results, sort, sortHandler}: MemberSearchDataGridProps) => {
    const history = useHistory();

    function handleRowClick(event: any) {
        history.push(`/member/${event.dataItem.memberInfo.id}`);
    }

    const parsedData = results?.map((m) => {
        const parsedMemberInfo = {...m.memberInfo, 
            dob: new Date(m.memberInfo.dob),
            joinedDate: new Date(m.memberInfo.joinedDate)
        }
        const parsedRow = {...m, 
                memberInfo: parsedMemberInfo
            }
        return parsedRow;
    });

    // TODO: Implement columnInterface
    return (
        <>
            <Grid onRowClick={handleRowClick} 
                data={orderBy(parsedData|| [], sort)}
                sortable={true}
                sort={sort}
                onSortChange={sortHandler}>
                <Column field="memberInfo.employeeID" title="Employee ID" width={130} />
                <Column field="memberInfo.name" title="Name" width={300} />
                <Column field="memberInfo.dob" title="DOB" filter="date" format="{0:dd/MM/yyyy}"/>
                <Column field="memberInfo.joinedDate" title="Joined" filter="date" format="{0:dd/MM/yyyy}" />
                <Column field="memberInfo.category" title="Cat" width={80} />
                <Column field="memberInfo.benefitAmount" title="Benefit" filter="numeric" format="{0:c}" />
                <Column field="memberInfo.premiumAmount" title="Premium" filter="numeric" format="{0:c}" />
                <GridNoRecords >
                    { (!results) 
                        ?   <div className='text-start'>
                                <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                                
                            </div>
                        : 'No results found.' }
                </GridNoRecords>
            </Grid>
        </>
    );
}

export default observer(MemberSearchDataGrid);
