import { observer } from 'mobx-react-lite'
import React from 'react'
import { Row, Col, Accordion } from 'react-bootstrap'
import { PolicyDetails, PolicyHeaderCategories } from '../../models/policyHeader'
import PolicyAccordionItem from './PolicyAccordionItem'

interface PolicyAccordionProps {
    policies?: PolicyHeaderCategories,
    defaultPolicy?: PolicyDetails,
    isLoading: boolean
}

const PolicyAccordion = ({policies, defaultPolicy, isLoading}: PolicyAccordionProps) => {
    return (
        <>
            { isLoading && 
                <Row className='mt-4' >
                    <Col className='p-0'>
                        <Accordion defaultActiveKey='0'>
                            <PolicyAccordionItem eventKey='0' />
                        </Accordion>
                    </Col>
                </Row>
            }
            
            { !isLoading && policies && defaultPolicy &&
            <Row className='mt-4' >
                <Col className='p-0'>
                    <Accordion defaultActiveKey={[`${defaultPolicy?.coverType}-${defaultPolicy.categoryNo}`]} alwaysOpen>
                        { policies.gl.map((policy,index) => ( 
                                <PolicyAccordionItem policy={policy} key={`${policy.coverType}-${index}`} />
                            ))
                        }
                        { policies.gsc.map((policy,index) => ( 
                                <PolicyAccordionItem policy={policy} key={`${policy.coverType}-${index}`} />
                            ))
                        }
                        { policies.tpd.map((policy,index) => ( 
                                <PolicyAccordionItem policy={policy} key={`${policy.coverType}-${index}`} />
                            ))
                        }
                    </Accordion>
                </Col>
            </Row>
            }
        </>
    )
}

export default observer(PolicyAccordion)