import React from 'react'

interface DumpObjectProps {
    obj: any,
    label?: any
}
const DumpObject = ({obj, label}: DumpObjectProps) => {
    return (
        <div className='bg-dark text-light p-3 border'>
            <h4> {label} -  {typeof obj}</h4>
            <pre>
                <code>
                    { typeof obj !== 'string' && JSON.stringify({...obj}, null, 2) }
                    { typeof obj === 'string' && obj }
                </code>
            </pre>
        </div>
    )
}

export default DumpObject