import React from "react";
import { NavLink, Route } from "react-router-dom";
import classNames from "classnames";

interface Props {
    to: string,
    label: string,
    icon?: string
}

function MenuLinkItem({ to, icon, label, ...rest }: Props) {
    return (
      <Route
        path={to}
        children={({ match }) => (
            <NavLink 
              className={classNames({
                'active': match,
                'nav-item': true
              })}
              to={to} 
              {...rest} > 
              {icon && <span className={`${icon} me-2`}></span> } {label} 
            </NavLink>
        )}
      />
    );
  }
  
export default MenuLinkItem