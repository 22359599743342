import { observer } from 'mobx-react-lite'
import React from 'react'
import AlertComponent from '../../app/common/notifications/AlertComponent'
import ChipComponent from '../../app/common/notifications/ChipComponent'
import { DashboardMessage, DashboardMessageType } from '../../models/dashboardMessage'

interface Props {
    messages?: DashboardMessage[]
}

const AlertMessages = ({messages}: Props) => {
    return (
        <>
            <ChipComponent/>
            { messages &&
                <>
                {
                    messages.map((m: DashboardMessage, index: number) => (
                            <AlertComponent key={index}
                                    variant={Object.values(DashboardMessageType)[parseInt(m.messageType)]}
                                    message={m.messageBody}
                                    link={m.messageLink}
                                />
                        )
                    )
                }
                </>
            }
        </>
    )
}

export default observer(AlertMessages)