import { makeAutoObservable, runInAction } from "mobx";
import Note, {NewNote, NoteTypes} from "../../models/note";
import agent from "../api/agent";

export default class NotesStore {
    notes?: Note[];
    openForm: boolean = false;
    selectedNote?: Note;

    constructor(){
        makeAutoObservable(this);
    }

    setNotes = (notes?: Note[]) => {
        this.notes = notes;
    }

    addNewNote = (note: Note) => {
        this.notes?.unshift(note);
    }

    getNotes = async (noteType: NoteTypes, noteOf: string) => {
        let notes = undefined;

        if(noteType === NoteTypes.GroupMaster){
            notes = await this.groupMasterNotes(noteOf);
        }

        if(noteType === NoteTypes.PolicyHeader){
            notes = await this.policyHeaderNotes(noteOf);
        }

        if(noteType === NoteTypes.Member){
            notes = await this.memberNotes(noteOf);
        }

        this.setNotes(notes);
    }

    groupMasterNotes = async (groupId: string) => {
        return await agent.Notes.getGroupMasterNotes(groupId);
    }

    policyHeaderNotes = async (policyHeaderId: string) => {
        return await agent.Notes.getPolicyHeaderNotes(policyHeaderId);
    }

    memberNotes = async (memberId: string) => {
        return await agent.Notes.getMemberNotes(memberId);
    }

    createNewNote = async (note: NewNote) => {
        try{
            runInAction( async () => {
                    await agent.Notes.createNote(note).then(newNote => {
                        this.addNewNote(newNote);
                    })
            })
        }catch(error){
            console.log(error);
        }
    }

    deleteNote = async (note?: Note) => {

        if (!note) return;

        try{
            await agent.Notes.deleteNote(note).then(() => {
                let updatedNoteList = this.notes?.filter((n) => n.id !== note.id);
                this.setNotes(updatedNoteList);
            })
        }catch(error){
            console.log(error);
        }
    }

    validateNote = (note: string) => {
        return !(!note || note.length > 255);
    }

    selectNote = (note?: Note) => {
        this.selectedNote = note;
    }
}