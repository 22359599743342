import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import LoaderComponent from "../../app/layout/LoaderComponent";
import { useStore } from "../../app/stores/store";
    
export const Logout = () => {
    const { userStore } = useStore();
    const { signoutRedirect } = userStore;

    useEffect(() => {
        signoutRedirect();
    }, [signoutRedirect])

    return (
         <Container fluid>
            <div className="row d-flex align-items-stretch bg-white" style={{ height: "100vh" }} >
                <div className="col-12 d-flex align-self-center bg-light text-dark" style={{ height: "100%" }}>
                    <LoaderComponent content='Logging out user. Please wait...' />
                </div>
            </div>
        </Container>
    )
};