import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface TruncateToolTipProps {
    id: string,
    value: string,
    maxCharLength?: number,
    maxWidth?: number
}

const TruncateToolTip = ({id, value, maxCharLength, maxWidth}: TruncateToolTipProps) => {
    const width = maxWidth || 300;
    const charLength = maxCharLength || 20;
    const label = (value.length > charLength ) ? value.slice(0, charLength) + '...' : value;
    const showToolTip = (value.length > charLength);
    return  showToolTip ? (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={id}>
                        {value} 
                    </Tooltip>
                }>
                <span 
                    className='d-inline-block text-truncate' 
                    style={{maxWidth: `${width}px`}} id={id} 
                    dangerouslySetInnerHTML={{ __html: label }}></span>
                
            </OverlayTrigger>
        ): (
            <span 
            className='d-inline-block text-truncate' 
            style={{maxWidth: `${width}px`}} id={id} 
            dangerouslySetInnerHTML={{ __html: label }}></span>
        )
}

export default TruncateToolTip