import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../stores/store";
import { Chip } from "@progress/kendo-react-buttons";

function ChipComponent(){
    const {noticeStore} = useStore();
    const {notice} = noticeStore;

    useEffect(() => {
        noticeStore.setNotice(null);
    }, [noticeStore]);

    if (notice === null) return null;

    return(    
        <Chip
            text={notice.message}
            value="message"
            type={notice.noticeState}
            look='filled'
            removable={false}
        />
    )
}

export default observer(ChipComponent);