import { makeAutoObservable, observable, reaction } from "mobx";
import { GroupMasterInfo } from "../../models/groupMaster";
import { PolicyHeaderStartDate, PolicyHeaderStatus } from "../../models/policyHeader";
import { PolicyHeaderListItem, SubMenu } from "../../models/subMenu";
import agent from "../api/agent";

export enum SubLinks {
    policy,
    members,
    claims,
    files
}

export enum IdTypes {
    memberId,
    policyHeaderId,
    claimId
}

export default class SubMenuStore {
    /** Display submenu */
    displaySubMenu: boolean = false;
    /** Indicates which submenu link is active */
    activeLink?: SubLinks;
    /** The group shown in the submenu. This also contains the list of ACTIVE policy headers*/
    policyHeaderList?: PolicyHeaderListItem[];
    /** Policy Header Group */
    group?: GroupMasterInfo;
    /** Policy Header Details */
    policyHeader?: PolicyHeaderListItem;
    /** Submenu api response */
    subMenu?: SubMenu;
    /** Policy header previous? this turns the bar color to gray  */
    isPrevious: boolean = false;
    /** To be used in file upload policy selection */
    policyWithSameReviewDate?: PolicyHeaderListItem[];
    /** List review dates for file upload policy selection */
    policyReviewDates?: PolicyHeaderStartDate[] | any;
    
    constructor(){
        makeAutoObservable(this,{
            displaySubMenu: observable,
            activeLink: observable,
            group: observable,
            policyHeader: observable,
            isPrevious: observable
        });

        reaction(
            () => this.subMenu,
            async (subMenu) => {
                if(!subMenu){
                    this.resetPolicyHeader();
                }else{
                    subMenu.policyHeader.isCurrent = subMenu.policyHeader?.status.toLowerCase() === PolicyHeaderStatus.CURRENT.toLowerCase()
                    this.setGroup(subMenu.groupMasterInfo);
                    this.setPolicyHeader(subMenu.policyHeader);
                    this.setPolicyHeaderList(subMenu.activePolicyHeaders);
                    this.setIsPrevious(subMenu.policyHeader.ceaseDate !== null);

                    let policyList = [subMenu.policyHeader];

                    subMenu.policyHeader.relatedPolicyHeaders?.forEach(p => {
                        policyList.push(p);
                    })

                    this.setPolicyWithSameReviewDate(policyList)
                    this.getPolicyReviewDates()
                }
            }
        )
    }

    /** 
     * @param state boolean Show / Hide submenu
     */
    setDisplay = (state: boolean) => {
        this.displaySubMenu = state;
    }

    /**
     * 
     * @param link SubLinks  This indicates the active sublink
     */
    setActivelink = (link: SubLinks) => {
        this.activeLink = link;
    }

    setGroup = (group: GroupMasterInfo) => {
        this.group = group
    }

    setIsPrevious = (state: boolean) => {
        this.isPrevious = state;
    }

    /**
     * 
     * @param submenu SubMenu  This is the response from api request. 
     */
    setSubmenu = (submenu: SubMenu) => {
        this.subMenu = submenu
    }

    /**
     * 
     * @param policyHeader 
     */
    setPolicyHeader = (policyHeader: PolicyHeaderListItem) => {
        this.policyHeader = policyHeader;
    }

    setPolicyHeaderList = (policyHeaders?: PolicyHeaderListItem[]) => {
        this.policyHeaderList = policyHeaders;
    }

    initPolicyHeader = async (idType: IdTypes, id: string) => {
        // show submenu immediately
        this.setDisplay(true);

        if(idType === IdTypes.memberId){
            return await this.getPolicyHeaderByMemberId(id).then((submenu) => {
                this.setSubmenu(submenu);
            })
        }

        if(idType === IdTypes.policyHeaderId){
            return await this.getPolicyHeaderById(id).then((submenu) => {
                this.setSubmenu(submenu);
            });
        }

        if(idType === IdTypes.claimId){
            return await this.getPolicyHeaderByClaimId(id).then((submenu) => {
                this.setSubmenu(submenu);
            });
        }
    }

    /**
     * Reset Policy Header State 
     * Used for useEffect clean up
     */
    resetPolicyHeader = () => {
        this.displaySubMenu = false;
        this.activeLink = undefined;
        this.group = undefined;
        this.policyHeader = undefined;
        this.policyHeaderList = undefined;
        this.isPrevious = false;
    }

    /**
     * 
     * @param policyHeaderGuid 
     * @returns GroupHeaderPolicy
     */
    getPolicyHeaderById = async (policyHeaderGuid: string) => {
        return await agent.SubMenuApi.getById(policyHeaderGuid);
    }

    /**
     * 
     * @param memberGuid Member GUID
     * @returns GroupHeaderPolicy
     */
    getPolicyHeaderByMemberId = async (memberGuid: string) => {
        return await agent.SubMenuApi.getByMemberId(memberGuid);
    }

    /**
     * 
     * @param claimGuid Claim GUID
     * @returns GroupHeaderPolicy
     */
     getPolicyHeaderByClaimId = async (claimGuid: string) => {
        return await agent.SubMenuApi.getByClaimId(claimGuid);
    }
 
    /**
     * This returns the GroupMasterId Policy Headers
     * @param groupGuid Group GUID
     * @returns PolicyHeader List
     */
    getPolicyHeaderList = async (status?: PolicyHeaderStatus) => {
        return await agent.SubMenuApi.getPolicyHeaders(status);
    }

    /**
     * Returns list of the current policy header and related policy with the same review date.
     * @param policyHeaders 
     */
    setPolicyWithSameReviewDate = (policyHeaders?: PolicyHeaderListItem[]) => {
        this.policyWithSameReviewDate = policyHeaders;
    }

    getPolicyReviewDates = () => {
         this.policyReviewDates = [...new Set(
            this.policyWithSameReviewDate?.flatMap((policy: PolicyHeaderListItem) => policy?.otherStartDates)
                .filter((policy: PolicyHeaderStartDate | undefined) => { 
                    if (policy?.policyHeaderGuid === this.policyHeader?.id) return policy?.startDate
                    else return;
                })
                .sort((a: PolicyHeaderStartDate | undefined, b: PolicyHeaderStartDate | undefined) => a && b ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime() : 0)
                .map((id: PolicyHeaderStartDate | undefined) => id && id.startDate.toString().slice(0, 10))
        )]  
    }
}