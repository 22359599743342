import classNames from 'classnames';
import React from 'react'

interface FloatingBarProps {
    position: 'top' | 'bottom',
    visible?: boolean,
    children: JSX.Element
}

const FloatingBar = ( {...props}: FloatingBarProps) => {
    return (
        <>
            <div className={ classNames(`pt-1 pb-1 floating-bar fixed-${props.position}`, {
                'invisible': !props.visible
            })} >
                <div className='container'>
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default FloatingBar;