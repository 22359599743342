import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isArray } from "lodash";
import { toast } from "react-toastify";
import { history } from "../..";
import { CampaignMemberItem } from "../../models/campaign";
import { ClaimDetails, ClaimNotification, EditClaim, InsurerPayment, NewClaim } from "../../models/claim";
import { ClaimSearchResponse } from "../../models/claimSearch";
import { DashboardMessage } from "../../models/dashboardMessage";
import { FileUploadPayload, PolicyFile, PolicyHeaderFiles } from "../../models/files";
import { Group } from "../../models/group";
import { GroupMasterDetails } from "../../models/groupMaster";
import Member, { MemberDetails } from "../../models/member";
import { DataGridSorting, MemberDataGridColumn, memberSearchResponse } from "../../models/memberSearch";
import Note, { NewNote } from "../../models/note";
import { Policy } from "../../models/policy";
import { PolicyHeader, PolicyHeaderDetails, PolicyHeaderStatus } from "../../models/policyHeader";
import { PolicyDataGridColumn, PolicySearchResponse } from "../../models/policySearch";
import ReRate from "../../models/reRate";
import { ServerError } from "../../models/serverError";
import { Stats } from "../../models/stats";
import { SubMenu } from "../../models/subMenu";
import { Notice, NoticeStates } from "../stores/noticeStore";
import { store } from "../stores/store";
import { EncodeDataToURL, FormatDate } from "../utils/utils";

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;

axios.interceptors.request.use(config => {

    if (!config) {
        config = {};
    }

    if (!config.headers) {
        config.headers = {};
    }
    const user = store.userStore.user;
    if (user) config.headers.Authorization = `Bearer ${user?.access_token}`;
    return config;
})

axios.interceptors.response.use(
    async (response) => {
        // place global interceptor logic in here..
        return response;
    },
    (error: AxiosError) => {
        // place global interceptor error logic in here..

        if (axios.isCancel(error)) {            
            return Promise.reject(error);
        } 
        
        if (!error?.response) {
            // unable to connect to the server
            toast.error("Unable to connect to the server. Please try again.");
        } else {
            const { data, status } = error.response!;
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: typeof data === "string" ? data : "",
                errors: [],
                statusCode: status,
            };

            switch (status) {
                // Bad Request - throw this errow when:
                // * user posted incorrect/incomplete data
                case 400:
                    if (data?.errors) {
                        notice.message = "Errors";
                        Object.keys(data.errors).forEach((key) => {
                            notice.errors?.push(data.errors[key]);
                        });
                    }

                    store.noticeStore.setNotice(notice);
                    break;

                // Unauthorized - throw this error when:
                // * token is expired/invalid
                // * user is not yet logged in
                case 401:
                    // notice.message = data.title;
                    // store.noticeStore.setNotice(notice);
                    // history.push("/error/unauthorized");
                    store.userStore.oidcClient.userManager.removeUser()
                    store.userStore.oidcClient.userManager.clearStaleState();
                    store.userStore.signoutRedirect();
                    break;
                
                // Forbidden - throw this error when: 
                // * user doesn't have enough permission to access 
                // * user doesn't have the right role
                case 403:
                    notice.message = `Page Not Found: ${error.request.responseURL}`;
                    store.noticeStore.setNotice(notice);
                    break;

                // Not Found
                case 404:
                    // notice.message = `Page Not Found: ${error.request.responseURL}`;
                    // store.noticeStore.setNotice(notice);
                    // break;
                    store.noticeStore.setNotice(null);
                    history.push("/error/not-found");
                    break;

                // Server error
                case 500:
                    store.noticeStore.setNotice(null);
                    //let serverError: ServerError;
                    // for  file download
                    if(data instanceof Blob) {
                        const reader = new FileReader();
                        // This fires after the blob has been read/loaded.
                        reader.addEventListener('loadend', (e: any) => {
                            const serverError: ServerError = {
                                statusCode: status,
                                message: e.srcElement.result.substring(
                                    e.srcElement.result.indexOf(": ") + 1,
                                    e.srcElement.result.indexOf("at")
                                ),
                                details: e.srcElement.result,
                            };
                            store.commonStore.setServerError(serverError);
                        });

                        reader.readAsText(data)
                    }else{
                        const serverError: ServerError = {
                            statusCode: status,
                            message: data.substring(
                                data.indexOf(": ") + 1,
                                data.indexOf("at")
                            ),
                            details: data,
                        };
                        store.commonStore.setServerError(serverError);
                        //history.push("/error/server-error");
                    }
                    
                   
                    break;
            }

            return Promise.reject(error);
        }
    }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, token?: any) => axios.get<T>(url, token).then(responseBody),
    post:<T>(url: string, body: {}, config?: AxiosRequestConfig) => axios.post<T>(url, body, config).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    download: <T>(url: string) => axios.get<T>(url, {responseType: 'blob'}).then(responseBody),
    upload: async <T>(url: string, body: FormData) => {
        store.fileStore.setUploadProgress(0);
        const config = {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                store.fileStore.setUploadProgress(progress);
            }
        };
       return axios.post<T>(url, body, config).then(responseBody)
    }
};

// Note: these are only place holders, please use it as a reference when implementing API requests
// Note: All queries are filtered by User Group Master ID. It's not necessary to use GMID as parameter

const Claims = {
    getByMemberId: (memberId: string) => requests.get<ClaimDetails[]>(`/members/claims/${memberId}`),
    getByPolicyHeaderId: (policyHeaderId: string) => requests.get<ClaimDetails[]>(`/claims/policy-header/${policyHeaderId}`),
    getClaimById: (id: string) => requests.get<ClaimDetails>(`/claims/${id}`),
    create: (claim: NewClaim) => requests.post<ClaimDetails>("/claims", claim),
    newClaimNotification: (claimNotification: ClaimNotification) => requests.post<ClaimDetails>("/claims/notification", claimNotification),
    update: (id: string, claim: EditClaim) => requests.put<ClaimDetails>(`/claims/${id}`, claim),
    delete: (id: string) => requests.del<void>(`/claims/${id}`),
    getPaymentHistory: (claimGuid: string) => requests.get<InsurerPayment[]>(`/claims/${claimGuid}/payment-history`),
    getPaymentTypes: (policyheaderguid: string) => requests.get<string[]>(`/claims/paymenttype/${policyheaderguid}`),
    createInsurerPayment: (insurerPayment: InsurerPayment) => {
        const formData = new FormData();
        Object.entries(insurerPayment).forEach(([key, value]) => {
           if(key.indexOf('Date') !== -1) value = FormatDate(value);
           if(value) formData.append(key, value);
        });
        return requests.upload<InsurerPayment>(`/claims/${insurerPayment.claimGuid}/insurer-payment`, formData);
    },
    // TODO: remove claim ID parameter, this info is available in payment history
    downloadPaymentHistory: (claimGuid: string, paymentHistoryId: string) => requests.download<Blob>(`/claims/${claimGuid}/payment-history/download/${paymentHistoryId}`),
    // TODO: remove claim ID parameter, this info is available in payment history
    deletePaymentHistory: (claimGuid: string, paymentHistoryId: string) => requests.del(`/claims/${claimGuid}/payment-history/${paymentHistoryId}`) 
};

const Groups = {
    list: () => requests.get<Group[]>(`/groups`), 
    details: (id: string) => requests.get<Group>(`/groups/${id}`)
}

const GroupMasters = {
    details: (id: string) => requests.get<GroupMasterDetails>(`/group-masters/${id}`)
}

const SubMenuApi = {
    getById: (policyHeaderGuid: string) => requests.get<SubMenu>(`/sub-menu/policy-headers/${policyHeaderGuid}`),
    getByMemberId: (memberGuid: string) => requests.get<SubMenu>(`/sub-menu/policy-member/${memberGuid}`), 
    getByClaimId: (claimId: string) => requests.get<SubMenu>(`/sub-menu/policy-claims/${claimId}`), 
    getPolicyHeaders: (status?: PolicyHeaderStatus) => requests.get<PolicyHeader[]>(`/policy-headers/${status}`),
}

const PolicyHeaders = {
    details: (policyHeaderGuid: string, startDate?: Date ) => {
        let url = `/policy-headers/details/${policyHeaderGuid}`;
        if(startDate) url += `/${startDate.toString()}`;
        return requests.get<PolicyHeaderDetails>(url);
    },
    upcomingRerates: () => requests.get<ReRate[]>(`/policy-headers/upcoming-rerates`),
    followUpReminder: () => requests.get<DashboardMessage[]>(`/policy-headers/reminder/follow-up`),
    followUpList: () => requests.get<PolicyHeader[]>(`/policy-headers/follow-up`),
    insuredBenefits: (policyHeaderGuid: string) => requests.get<string[]>(`/policy-headers/${policyHeaderGuid}/insured-benefits`),
    files: (policyHeaderGuid: string) => requests.get<PolicyHeaderFiles>(`/files/policy-headers/${policyHeaderGuid}`),
    downloadFile: (policyHeaderGuid: string, fileGuid: string) => requests.download<Blob>(`/files/policy-headers/${policyHeaderGuid}/file/${fileGuid}`),
    deleteFile: (policyHeaderGuid: string, fileGuid: string) => requests.del(`/files/policy-headers/${policyHeaderGuid}/file/${fileGuid}`),
    uploadFile: (payload: FileUploadPayload) => {
        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            if(key === 'policyHeaderGuids' && isArray(value)){
                value.forEach(id => {
                    formData.append(key, id)
                })
            }else{
                (value && ['file','memberGuid', 'inputFileName','policyHeaderReviewDate'].indexOf(key) === -1)
                ? formData.append(key, JSON.stringify(value))
                : formData.append(key, value)
            }
        });

        formData.delete('currentPolicyHeader');
        return requests.upload<PolicyFile[]>(`/files/policy-headers/${payload.currentPolicyHeader}`, formData);
    },
}

const Policies = {
    list: (id: string) => requests.get<Policy[]>(`/group/getpolicyheaders/${id}`),
    listFilter: (id: string, filter: string) => requests.get<Policy[]>(`/group/getpolicyheadersbyfilter?policyheaderid=${id}&filter=${filter}`),
    details: (datasource: string, id: string) => requests.get<Policy>(`/group/getpolicy?datasource=${datasource}&policyheaderid=${id}`),
    getPolicyById: (id: string) => requests.get<Policy>(`/policy/${id}`)
};

const Statistics = {
    list: () => requests.get<Stats>("/dashboard/stats"),
};

const ReRates = {
    list: () => requests.get<ReRate[]>(`/group/GetPolicyHeadersReRate`)
}

const Members = {
    list: () => requests.get<Member[]>(`/members`),
    search: (filter: string) => requests.get<Member[]>(`/members/search/${filter}`),
    details: (id: string) => requests.get<MemberDetails>(`/members/details/${id}`),
    history: (datasource: string, memberId: number) => requests.get<Member[]>(`/member/GetMemberHistory?datasource=${datasource}&memberid=${memberId}`)
}

const Notes = {
    getMemberNotes: (memberId: string) => requests.get<Note[]>(`/notes/member/${memberId}`),
    getPolicyHeaderNotes: (policyHeaderId: string) => requests.get<Note[]>(`/notes/policy-header/${policyHeaderId}`),
    getGroupMasterNotes: (groupId: string) => requests.get<Note[]>(`/notes/group-master/${groupId}`),
    createNote: (note: NewNote) => requests.post<Note>('/notes', note),
    deleteNote: (note: Note) => requests.del(`/notes/delete/${note.id}`),
}

const UnderwritingDecisions = {
    getMemberUnderwritingDecision: (memberId: string) => requests.get<PolicyFile[]>(`/members/under-writing-decision/${memberId}`)
}

const DownloadReports = {
    groupMembersReport: (groupMasterGuid: string) => requests.download<Blob>(`/reports/group-members/${groupMasterGuid}`),
    groupClaimsReport: (groupMasterGuid: string) => requests.download<Blob>(`/reports/group-claims/${groupMasterGuid}`),
    policyHeaderMembers: (policyHeaderGuid: string, reviewDate: string) => requests.download<Blob>(`/reports/policy-header-members/${policyHeaderGuid}/${reviewDate}`),
    policyHeaderClaims: (policyHeaderGuid: string) => requests.download<Blob>(`/reports/policy-header-claims/${policyHeaderGuid}`),
    memberPolicyStatement: (memberGuid: string) => requests.download<Blob>(`/reports/member-statement/${memberGuid}`),
    allPoliciesReport: () => requests.download<Blob>(`/reports/all-policies`),
}

const StaticFiles = {
    downloadTemplate: (templateName: 'movementdata'| string) => requests.download<Blob>(`/files/templates/file/${templateName}`),
}

/**  Without filter, return first 20 results.*/
const Search = {
    member: (keyword?: string, page?: number, policyHeader?: string, reviewDate?: Date, sortField?:  MemberDataGridColumn, sortOrder?: DataGridSorting, token?: any) => {
        
        let queryData = {
            keyword: keyword || '',
            page: page || 1,
            policyHeader: policyHeader || '',
            reviewDate: FormatDate(reviewDate) || '',
            sortField: sortField || '',
            sortOrder: sortOrder || ''
        }

        return requests.get<memberSearchResponse>(`/members/search?${EncodeDataToURL(queryData)}`, token)
    },
    policy: (keyword?: string, page?: number, sortField?: PolicyDataGridColumn, sortOrder?: DataGridSorting, token?: any) => {

        let queryData = {
            keyword: keyword || '',
            page: page || 1,
            sortField: sortField || '',
            sortOrder: sortOrder || ''
        }

        return requests.get<PolicySearchResponse>(`/policy-headers/search?${EncodeDataToURL(queryData)}`, token)
    },
    claims: (policyHeader: string, keyword?: string, page?: number) => {

        let queryData = {
            policyheader: policyHeader!,
            keyword: keyword || '',
            page: page || 1
        }

        return requests.get<ClaimSearchResponse>(`/claims/search?${EncodeDataToURL(queryData)}`)
    },
}

const User = {
    getGroupMasterId: () => requests.get<string>('/user/group-master')
}

const Campaign = {
    getQualifiedMembers: (policyHeader: string) =>  requests.get<CampaignMemberItem[]>(`/members/uw-campaign/${policyHeader}`),
    sendEmail: (policyHeader: string, memberIds: string[]) =>  requests.post<CampaignMemberItem[]>(`/policy-headers/${policyHeader}/campaign`, memberIds),
    downloadStatement: (token: string) => requests.download<Blob>(`campaign/UWstmt/${token}`)
}

const agent = {
    Claims,
    Groups,
    GroupMasters,
    Policies,
    PolicyHeaders,
    Statistics,
    ReRates,
    Members,
    Notes,
    UnderwritingDecisions,
    SubMenuApi,
    DownloadReports,
    Search,
    User,
    StaticFiles,
    Campaign
};

export default agent;
