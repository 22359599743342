import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

const PageNotFoundError = () => {
    const history = useHistory();
    const {subMenuStore} = useStore();
    const {setDisplay} = subMenuStore;

    const handleClick = () => {
        history.push('/');
    }

    useEffect(() => {
        setDisplay(false)
    }, [setDisplay])
    

    return (
        <Row className='bg-white text-center p-5'>
            <Col>
                <h1>404: Page not found</h1>
                <p>The page you are looking for doesn't exist or other error occured.</p>
                <Button onClick={handleClick} primary={true} look="flat"> Return to dashboard </Button>
            </Col>
        </Row>
    )
}

export default PageNotFoundError