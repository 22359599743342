import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useStore } from "../stores/store";
import { UserHasPermission } from "../utils/utils";

const Footer = () => {
  const { userStore } = useStore();
  const { userClaims } = userStore;

  const isBasicUser = UserHasPermission(['CerteBasicUser'], userClaims?.role)

  return (
    <>
    {!isBasicUser &&
    <Container fluid className='border-top'>
      <Container >
        <Row className='justify-content-center text-center pt-3 pb-3'>
          { UserHasPermission(['PortalAdmin'], userClaims?.role) &&
          <Col>
            <a href="https://app.powerbi.com/Redirect?action=OpenReport&appId=d204370e-05e7-41e9-ba67-03e4b90f7aa5&reportObjectId=35bad7bd-bd23-4163-a930-05d50704ffcb&ctid=95cb27a7-8d84-4734-a79a-b34552ad9c36" target="_blank" rel="noreferrer">Data Analytics</a>
          </Col>
          }
          <Col>
            <a href="https://www.certe.com.au/disclaimer/" target="_blank" rel="noreferrer">Disclaimer</a>
          </Col>
          <Col>
            <a href="https://www.certe.com.au/terms-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
          </Col>
          <Col>
            <a href="https://www.certe.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
          </Col>
          <Col>
            <a href="https://www.certe.com.au/wp-content/uploads/FSG-Certe-Wealth-Protection-v4.0.pdf" target="_blank" rel="noreferrer">Financial Services Guide</a>
          </Col>
        </Row>
      </Container>
    </Container>}
    <Container fluid className='border-top'>
      <Container >
        <Row className='justify-content-center p-3'>
          <Col className='text-center text-muted'>
           &copy; {new Date().getFullYear()} Certe | All rights reserved
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  );
}

export default observer(Footer)
