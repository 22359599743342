import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Button, Col, Row } from "react-bootstrap";
import PolicySearchBar from "./PolicySearchBar";
import PolicySearchDataGrid from "./PolicySearchDataGrid";
import InView from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import pluralize from 'pluralize';
import fileDownload from "js-file-download";
import mime from 'mime-types';
import { FormatDate } from "../../app/utils/utils";
import { GridSortChangeEvent } from "@progress/kendo-react-grid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { DataGridSorting } from "../../models/memberSearch";
import { PolicyDataGridColumn } from "../../models/policySearch";
import { Helmet } from "react-helmet";

function SearchPolicy() {
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const history = useHistory();
    const { searchPolicyStore, userStore, subMenuStore, policyHeaderStore } = useStore();
    const { currentPage, pagination, loadMore, dataGrid, sortSearch } = searchPolicyStore;
    const { groupMasterId } = userStore;
    const { resetPolicyHeader } = subMenuStore;
    const { downloadAllPoliciesReport } = policyHeaderStore;

    useEffect(() => {
        resetPolicyHeader();
        if(groupMasterId && groupMasterId !== '0' ){
            history.push('/group-master/' + groupMasterId)
        }
    }, [groupMasterId, history, resetPolicyHeader])


    const handleLoadMore = (inView: boolean) => {
        if(pagination && currentPage < pagination.totalPages){
            if(inView ){
                loadMore();
            }
        }
    }

    const handlePolicyReport = () => {
        downloadAllPoliciesReport().then(blob => {
            let filename = `Policy Listing Run Date ${FormatDate(new Date())}.${mime.extension(blob.type)}`
            fileDownload(blob, filename);
        })
    }

    const sortHandler = (e: GridSortChangeEvent) => {
        const fieldMap = {
            'groupName': 'GroupName',
            'insurer': 'Insurer',
            'mazxembers': 'MemberCount',
            'policyNumber': 'PolicyNo',
            'premium': 'PremiumAmount',
            'productType': "ProductType",
            'reviewDate': "RenewalDate",
            'status': "PolicyStatust"
        }

        if(e.sort.length > 0) {
            console.log(e.sort[0])
            const sortField = e.sort[0].field!;
            const sortDir = (e.sort[0].dir! === 'asc') ? DataGridSorting.Ascending : DataGridSorting.Descending;
            const fieldName = fieldMap[sortField  as keyof typeof fieldMap];
            sortSearch(PolicyDataGridColumn[fieldName as keyof typeof PolicyDataGridColumn], sortDir)
        }else{
            sortSearch();
        }
        setSort(e.sort);
    }

    return (
        <>
            <Helmet title="All policies" />
            <Row className="mt-4">
                <Col>
                    <h1 className='header-title'>All Policies</h1>
                </Col>
                <Col className='text-end'>                   
                    <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={handlePolicyReport}> <i className='icon icon-download' /> Policies report</Button>
                </Col>
            </Row>
            
            <PolicySearchBar placeholder="Search policy" focusOnLoad searchOnInit disableSuggestion/>
            
            <Row className="mt-4">
                <Col>
                    <PolicySearchDataGrid
                        sort={sort}
                        sortHandler={sortHandler} 
                        results={dataGrid} />
                </Col>
            </Row>

            {dataGrid &&                        
                <Row className='mt-4'>
                    <Col className='text-end'>
                    {pagination?.totalResults} {pluralize('Result', pagination?.totalResults)}
                    </Col>
                </Row>
            }

            {pagination && dataGrid && pagination.pageSize <= dataGrid.length &&
            <Row>
                <Col className='text-center mt-4'>
                    <InView as="div" onChange={handleLoadMore}>
                        { currentPage < pagination.totalPages  && 
                         <span className='text-muted'>loading...</span>
                        }
                    </InView>
                </Col>
            </Row>
            }
        </>
    );
}

export default observer(SearchPolicy);
