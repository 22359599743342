import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import LoaderComponent from '../../app/layout/LoaderComponent'
import { useStore } from '../../app/stores/store'
import { IdTypes, SubLinks } from '../../app/stores/subMenuStore'
import { UserHasPermission } from '../../app/utils/utils'
import ClaimNotification from './ClaimNotification'
import NewClaimForm from './forms/NewClaimForm'
import InsuredBenefits from './InsuredBenefits'
import {Helmet} from 'react-helmet'
import classnames from 'classnames'

interface NewClaimComponentUrlParams {
    policyHeaderGuid: string,
    memberGuid?: string
}

const NewClaimComponent = () => {
    const {subMenuStore, policyHeaderStore, userStore, memberStore} = useStore();
    const {userClaims} = userStore;
    const {initPolicyHeader, setActivelink, resetPolicyHeader} = subMenuStore;
    const {getMemberDetails} = memberStore;
    const {getInsuredBenefits} = policyHeaderStore;
    const {policyHeaderGuid, memberGuid} = useParams<NewClaimComponentUrlParams>();

    const isPortalAdmin = UserHasPermission(['PortalAdmin'], userClaims?.role);

    useEffect(() => {
        // show submenu 
        initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        setActivelink(SubLinks.claims);

        if(memberGuid) {
            getMemberDetails(memberGuid);
        }

        // return () => {
        //     resetPolicyHeader()
        // }
    }, [getInsuredBenefits, getMemberDetails, initPolicyHeader, memberGuid, policyHeaderGuid, resetPolicyHeader, setActivelink]);

    
    if(!subMenuStore.policyHeader) return <LoaderComponent content='loading claim form...'/>

    const headerTitle = isPortalAdmin ? 'New Claim' : 'Claim Notification';

    return (
        <>
            <Helmet title={`Policy - ${headerTitle}`}/>
            <Row className='mt-4 mb-4'>
                <Col>
                    <h1 className='header-title'>
                        { headerTitle }
                    </h1>
                </Col>
            </Row>
            <Row  className='gx-5'>
                <Col className={classnames({
                    'col-8': isPortalAdmin,
                    'col-12': !isPortalAdmin,
                })} >
                    { isPortalAdmin && 
                        <NewClaimForm policyHeader={subMenuStore.policyHeader}/>
                    }

                    { !isPortalAdmin &&  
                        <ClaimNotification policyHeader={subMenuStore.policyHeader} />
                    }

                </Col>
                { isPortalAdmin && 
                <Col className='col-4'>
                    <InsuredBenefits policyHeaderGuid={policyHeaderGuid} />
                </Col>
                }
            </Row>
        </>
    )
}

export default observer(NewClaimComponent)