import { makeAutoObservable, observable } from "mobx";
import { Pagination } from "../../models/pagination";
import { PolicyDataGridColumn, PolicySearchResponse, PolicySearchResult } from "../../models/policySearch";
import agent from "../api/agent";
import moment from 'moment';
import _ from "lodash";
import { DataGridSorting } from "../../models/memberSearch";

export default class SearchPolicyStore {
    /** Filters */
    keyword?: string = '';
    currentPage: number = 1;
    sortField: PolicyDataGridColumn = PolicyDataGridColumn.GroupName;
    sortOrder: DataGridSorting = DataGridSorting.Ascending;

    /** Response */
    response?: PolicySearchResponse;
    pagination?: Pagination;

    /** Rendering component */
    suggestionList?: PolicySearchResult[];
    dataGrid?: PolicySearchResult[];

    /** Axios abort controller */
    abortController?: AbortController;

    constructor(){
        makeAutoObservable(this, {
            keyword: observable,
            response: observable,
            pagination: observable
        });
    }

    setSuggetstionList = (response?: PolicySearchResult[]) => {
        this.suggestionList = response;
    }

    setDataGrid = (response?: PolicySearchResult[]) => {
        this.dataGrid = response;
    }

    private prepResultData = (resultData: PolicySearchResult[]) => {
        return resultData.map((p) => {
                            const parsedRow = {...p, 
                                    reviewDate: moment(`${p.reviewDate}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate(),
                                    ceaseDate: moment(`${p.ceaseDate}`, ['YYYY-MM-DD','DD/MM/YYYY', 'MM/DD/YYYY']).toDate()
                                }
                            return parsedRow;
                        });
    }

    private setResponse = (response?: PolicySearchResponse) => {
        this.response = response
    }

    private appendDataGrid = (results?: PolicySearchResult[]) => {
        this.dataGrid = [...this.dataGrid!, ...results!];
    }

    private setPagination = (pagination?: Pagination) => {
        this.pagination = pagination;
    }

    setKeyword = (keyword: string) => {
        this.keyword = keyword;
    }

    setCurrentPage = (pageNumber: number) => {
        this.currentPage = pageNumber;
    } 

    setSortField = (field?: PolicyDataGridColumn) =>{
        this.sortField = field || PolicyDataGridColumn.GroupName;
    }

    setSortOrder = (order?: DataGridSorting ) =>{
        this.sortOrder = order || DataGridSorting.Ascending;
    }

    // TODO: implement named parameters
    private search = async (keyword?: string, currentPage?: number, sortField?: PolicyDataGridColumn, sortOrder?: DataGridSorting ) => {
        this.keyword = keyword || '';
        this.currentPage = currentPage || 1;
        this.sortField = sortField || PolicyDataGridColumn.GroupName;
        this.sortOrder = sortOrder || DataGridSorting.Ascending;

        this.abortController = new AbortController();
        
        const tokenParam = {  
            signal: this.abortController.signal
        }

        return await agent.Search.policy(this.keyword, this.currentPage, this.sortField, this.sortOrder, tokenParam);
                        // .then((response)=> { 
                        //     this.setResponse(response);
                        // });
    } 

    searchSuggestion = async (keyword?: string) => {
        this.abortController?.abort();
        return await this.search(keyword).then(response => {
            const splicedList = _.take(response.results, 5);
            this.setSuggetstionList(this.prepResultData(splicedList));
            return this.suggestionList;
        })
    }

    getDataGridResults = async (keyword?: string, pageNumber?: number) => {
        this.abortController?.abort();
        return await this.search(keyword, pageNumber, this.sortField, this.sortOrder).then(response => {

            if(response && response?.pagination?.currentPage > 1){
                this.appendDataGrid(this.prepResultData(response.results));
            }else{
                this.setDataGrid(this.prepResultData(response.results));
            }

            this.setPagination(response?.pagination);
            return this.dataGrid;
        })
    }

    loadMore = async () => {
        this.currentPage += 1;
        return await this.getDataGridResults(this.keyword, this.currentPage);
    }

    initSearch = (keyword?: string) => {
        // auto search on invoke
        this.getDataGridResults('', 1);
    }

    sortSearch = async (field?: PolicyDataGridColumn, order?: DataGridSorting) => {
        this.setDataGrid();
        this.setSortField(field || PolicyDataGridColumn.GroupName);
        this.setSortOrder(order || DataGridSorting.Ascending);

        return await this.search(this.keyword, 1, field, order).then(response => {
            this.setDataGrid(this.prepResultData(response.results));
            this.setPagination(response?.pagination);
            return this.dataGrid;
        })
    }

    resetSearch = () => {
        this.setCurrentPage(1);
        this.setKeyword('');
        this.setResponse(undefined);
        this.setPagination(undefined);
    }
}