import { Skeleton } from '@progress/kendo-react-indicators'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'react-bootstrap'
import { FormatDate } from '../../app/utils/utils'
import { PolicyMemberInfo } from '../../models/member'

interface Props {
    member?: PolicyMemberInfo
}

const MemberInfo = ({member}: Props ) => {
    return (
        <>
            <Row className='border rounded-top bg-white'>
                <Col className='col-2 p-3 ps-3 pe-3'>
                    <small className='text-muted'>Employee ID</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        <strong>{member?.employeeId}</strong>
                    </div>
                </Col>
                <Col className='col-10 p-3 ps-3 pe-3'>
                    <small className='text-muted'>Name</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                    <strong>{member?.name}</strong>
                    </div>
                </Col>
            </Row>
            <Row className='border border-top-0 bg-white'>
                <Col className='col-2 p-3 ps-3 pe-3 border-end'>
                    <small className='text-muted'>Gender</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        { member && member?.gender === 'M' ?  'Male' : 'Female' }
                    </div>
                </Col>
                <Col className='col-2 p-3 ps-3 pe-3 border-end'>
                    <small className='text-muted'>DOB</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        { FormatDate(member?.dob) }
                    </div>
                </Col>
                <Col className='col-6 p-3 ps-3 pe-3 border-end'>
                    <small className='text-muted'>Occupation</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        {member?.occupation}
                    </div>
                </Col>
                <Col className='col-2 p-3 ps-3 pe-3'>
                    <small className='text-muted'>Joined company</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                    { member?.joinedDate && FormatDate(member?.joinedDate)}
                    </div>
                </Col>
            </Row>
            <Row className='border border-top-0 rounded-bottom bg-white'>
                <Col className='col-2 p-3 ps-3 pe-3 border-end'>
                    <small className='text-muted'>State</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        {member?.state}
                    </div>
                </Col>
                <Col className='col-2 p-3 ps-3 pe-3 border-end'>
                    <small className='text-muted'>Contact</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        {member?.contact}
                    </div>
                </Col>
                <Col className='col-8 p-3 ps-3 pe-3'>
                    <small className='text-muted'>Email</small>
                    <div>
                    { !member && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> }
                        {member?.email}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default observer(MemberInfo)
