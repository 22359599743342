import { observer } from "mobx-react-lite";
import { Row, Col, NavDropdown, Nav } from "react-bootstrap";
import { useStore } from "../stores/store";
import { SubLinks } from "../stores/subMenuStore";
import classNames from "classnames";
import TruncateToolTip from "../common/format/TrimTooltip";
import { LinkContainer } from "react-router-bootstrap";
import { Skeleton } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserHasPermission } from "../utils/utils";

/**
 * Policy Header Menu 
 * This requires memberId or policyId
 * @returns Policy Header Menu Component
 */
const NavSubMenuComponent = () => {
    const { subMenuStore, searchMemberStore, userStore } = useStore();
    const { setSelectedMember } = searchMemberStore;
    const location = useLocation();
    const history = useHistory();
    const { 
            group,
            isPrevious,
            policyHeader,
            policyHeaderList, 
            displaySubMenu, 
            activeLink,
            resetPolicyHeader
        } = subMenuStore;
    const { userClaims } = userStore;

    const isBasicUser = UserHasPermission(['CerteBasicUser'], userClaims?.role);
    const accessLevel = userClaims?.accessLevelId;

    const pathname = location.pathname.split('/')

    const [isInsurerDifferOnMemberPage, setIsInsurerDifferOnMemberPage] = useState(false)

    const check = policyHeader && policyHeaderList && (pathname && pathname[1] === 'member') 

    useEffect(() => {
        if (typeof check !== 'undefined')
        setIsInsurerDifferOnMemberPage(check)
    }, [policyHeader, policyHeaderList, check])

    useEffect(() => {
        return () => {
            resetPolicyHeader();
            setSelectedMember();
        }
    }, [resetPolicyHeader, setSelectedMember])

    const policyNoLabel = () => {
        return (isPrevious) 
            ? `${policyHeader?.policyNumber!} <small className='text-muted'>Previous</small>`
            : policyHeader?.policyNumber! 
    }

    /**
     * return replaces the group id of the current URL.
     */
    const replacePolicyHeaderId = (groupId: string) => {
        /**
         * 0 - base 
         * 1 - policy header  
         * 2 - policy header ID
         * 3 - sub page path 
         */
        let pathname = location.pathname.split('/');

        // exception for member page details - it will redirect to the policy header details page.
        // if(pathname && pathname[1] === "member") return `/policy-headers/${groupId}/members`;
        // if(pathname && pathname[1] === "claims") return `/policy-headers/${groupId}/claims`;

        if(pathname[1] !== "policy-headers") return `/policy-headers/${groupId}/${pathname[1]}`;

        pathname[2] = groupId;

        return pathname.join('/');
    }

    const handlePolicyChange = (groupId: string) => {
        const url = replacePolicyHeaderId(groupId);
        history.push(url);
    }

    const navGroupName = isBasicUser 
        ?                                 
        <Nav.Item className='d-flex justify-content-center cursor-default'>      
            { !group &&
               <Skeleton shape={"text"} style={{ width: "100%" }} /> 
            }
            { group &&
                <TruncateToolTip id='sub-menu-group-name' value={group.name} maxCharLength={30} maxWidth={300} />
            }
        </Nav.Item>
        :
        <LinkContainer to={`/group-master/${group?.id}`}>
            <Nav.Link className='d-flex justify-content-center'> 
                { !group &&
                <Skeleton shape={"text"} style={{ width: "100%" }} /> 
                }
                { group &&
                    <TruncateToolTip id='sub-menu-group-name' value={group.name} maxCharLength={30} maxWidth={300} />
                }
            </Nav.Link>
        </LinkContainer>

    return (
        <div id="subMenu" 
            className={ classNames('mb-5',{ "inactive-policy": isPrevious })}>
            { displaySubMenu && 
                <div className="container">
                    <Row className="d-flex flex-nowrap">
                        <Col className='border-end p-3 ps-2 pe-2 text-center align-self-center'>
                            <Nav.Item>
                                {navGroupName}
                            </Nav.Item>
                        </Col>
                        <Col className='border-end p-3 ps-2 pe-2 text-center align-self-center'>
                            { (!policyHeaderList) &&
                                <Nav.Item>
                                    <Nav.Link> 
                                       <Skeleton shape={"text"} style={{ width: "100%" }} /> 
                                    </Nav.Link>
                                </Nav.Item>
                            }

                            { policyHeader && policyHeaderList && (policyHeaderList.length === 1 || policyHeaderList?.length === 0 || (policyHeader.insurer.length > 0 && policyHeaderList.length > 1 && isInsurerDifferOnMemberPage === true)) &&
                                <Nav.Item>
                                        <Nav.Link className="cursor-default"> 
                                            <span className='text-nowrap'>{policyHeader.productType} - {policyHeader.insurer}</span>
                                        </Nav.Link>
                                </Nav.Item>
                            }
            
                            {  policyHeader && policyHeaderList  &&  policyHeaderList.length > 1 && isInsurerDifferOnMemberPage === false &&
                                <NavDropdown id="group-headers-list" title={`${policyHeader?.productType} - ${policyHeader?.insurer}`}>
                                    {
                                        policyHeaderList.map((p, index) => {
                                            return (
                                            <LinkContainer onClick={() => handlePolicyChange(p.id)} to="#" key={p.id}>
                                                <NavDropdown.Item key={index}>{p.productType} - {p.insurer}</NavDropdown.Item>
                                            </LinkContainer>
                                            )
                                        })
                                    }
                                </NavDropdown>
                            }
                        </Col>
                        <Col className='p-3 ps-2 pe-2 text-center align-self-center'>
                             <Nav.Item>
                                <Nav.Link className={'pe-none d-flex justify-content-center'}>
                                    { !policyHeader &&
                                       <Skeleton shape={"text"} style={{ width: "100%" }} /> 
                                    }

                                    { policyHeader && 
                                        <TruncateToolTip id='sub-menu-policy-number' value={policyNoLabel()} maxCharLength={(isPrevious) ? 100 : 30} />
                                    }
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col /> 
                        
                        <Col className='p-3 ps-2 pe-2 text-center align-self-center tab-menu'>
                            { policyHeader && !isBasicUser &&
                            <Nav.Item className={(activeLink === SubLinks.policy ) ? 'active' : ''}>
                                <LinkContainer to={`/policy-headers/${policyHeader.id}`}>
                                    <Nav.Link active={(activeLink === SubLinks.policy )}>Policy</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            }
                        </Col>
                        <Col className='p-3 ps-2 pe-2 text-center align-self-center tab-menu'>
                            { policyHeader && !isBasicUser &&
                            <Nav.Item className={(activeLink === SubLinks.members ) ? 'active' : ''}>
                                <LinkContainer to={`/policy-headers/${policyHeader.id}/members`}>
                                    <Nav.Link active={(activeLink === SubLinks.members )}>Members</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            }
                        </Col>
                        { policyHeader && !isBasicUser && 
                        accessLevel === 0 &&
                            <Col className='p-3 ps-2 pe-2 text-center align-self-center tab-menu'>
                                <Nav.Item className={(activeLink === SubLinks.claims ) ? 'active' : ''}>
                                    <LinkContainer to={`/policy-headers/${policyHeader.id}/claims`}>
                                        <Nav.Link active={(activeLink === SubLinks.claims )}>Claims</Nav.Link>
                                    </LinkContainer>
                                </Nav.Item>
                            </Col>
                        }
                        <Col className='p-3 ps-2 pe-2 text-center align-self-center tab-menu'>
                            { policyHeader && 
                            <Nav.Item className={(activeLink === SubLinks.files ) ? 'active' : ''}>
                                {/* {accessLevel === 0 ?  */}
                                <LinkContainer to={`/policy-headers/${policyHeader.id}/files`}>
                                    <Nav.Link active={(activeLink === SubLinks.files )}>Files</Nav.Link>
                                </LinkContainer>
                                {/* :
                                <LinkContainer to={`/policy-headers/${policyHeader.id}/upload/:actionType?`}>
                                    <Nav.Link active={(activeLink === SubLinks.files )}>Upload File</Nav.Link>
                                </LinkContainer>
                                } */}
                            </Nav.Item>
                            }
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}

export default observer(NavSubMenuComponent);