import { RadioButton } from "@progress/kendo-react-inputs";
import React from "react";

export default function KendoRadio() {
return (
    <div className="row mb-5">
      <div className="col-24 mb-3">
          <h3>Radio Button</h3>
      </div>
      <div>
          <RadioButton
          name="group1"
          value="first"
          checked={true}
          label="Radio Button 1"
        
        />
        <br />
        <RadioButton
          name="group1"
          value="second"
          label="Radio Button 2"
        />
        <br />
        <RadioButton
          name="group1"
          value="third"
          label="Radio Button 3"
        />
      </div>
    </div>
)
    
}