import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import FollowUpDataGrid from './FollowUpDataGrid'
import axios from 'axios';
import { useStore } from '../../app/stores/store';
import LoaderComponent from '../../app/layout/LoaderComponent';
import { observer } from 'mobx-react-lite';
import Unauthorized from '../errors/Unauthorized';
import { Helmet } from "react-helmet"

const FollowUpComponent = () => {
    const { policyStore, subMenuStore, userStore } = useStore(); 
    const { loadingInitial, followUpPolicies, getFollowUpPolicyList } = policyStore;
    const {resetPolicyHeader} = subMenuStore;
    const { hasUserRole, userClaims } = userStore;

    useEffect(() => {
        resetPolicyHeader()
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        
        if(hasUserRole('PortalAdmin')) getFollowUpPolicyList();
        
        return () => {
            source.cancel();
        }
    }, [getFollowUpPolicyList, hasUserRole, resetPolicyHeader])

    console.log(userClaims)
    
    return (
        (!hasUserRole('PortalAdmin')) 
            ?   <Unauthorized />
            :   <>
                    <Helmet title={'Follow-up'} />
                    <Row className='mb-3'>
                        <Col className='col-8'>
                            <h1>Follow up required</h1>
                            <p>Data requests have been issued to the listed policies below over 30 days ago. Your client has yet to respond to the request by uploading the completed data file via the Portal. </p>
                        </Col>
                    </Row>
        
                    { loadingInitial && <LoaderComponent content="Loading list..." />}
                    { !loadingInitial && 
                        <FollowUpDataGrid dataGrid={followUpPolicies} />
                    }
                </>
    )
}

export default observer(FollowUpComponent)