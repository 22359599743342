import React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { Row, Col, Container } from "react-bootstrap";

interface Props {
    content?: string
}

export default function LoaderComponent( {content = 'Loading...'}: Props){
    return (
        <Container>
            <Row className='justify-content-center'>
                <Col className='col align-self-center text-center bg-white p-5'>
                    <Loader
                        size={"large"}
                        type={"converging-spinner"}
                    />
                    <small className='text-muted p-3'>{content}</small>
                </Col>
            </Row>
        </Container>
    );
}