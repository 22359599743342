import React from "react";
import KendoButtons from "./KendoButtons";
import KendoInputs from "./KendoInputs";
import KendoRadio from "./KendoRadio";
import KendoNotice from "./KendoNotice";
import { Row, Col } from "react-bootstrap";
import ProtectedComponent from "../../app/common/routes/ProtectedComponent";

export default function KendoComponents() {
    return (
    <>
        <div className="container">
            <div className='row'>
                <div className="col-24">
                    <h1>Role Access Test</h1>
                    <hr />
                </div>
            </div>
            <Row>
                <Col>
                    <ProtectedComponent allowedRoles={['PortalAdmin']}>
                        <div>Admin can see me..</div>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={['PortalUser']}>
                        <div>user can see me..</div>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={['PortalAdmin','PortalUser']}>
                        <div>Admin and user can see me..</div>
                    </ProtectedComponent>
                    <ProtectedComponent>
                        <div>Everyone can see me..</div>
                    </ProtectedComponent>
                </Col>
            </Row>
            <div className='row'>
                <div className="col-24">
                    <h1>Kendo Components</h1>
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div className="col-24">
                    <KendoButtons />
                </div>
                <div className="col-24">
                    <KendoInputs />
                </div>
                <div className="col-24">
                    <KendoRadio />
                </div>
                <div className="col-24">
                    <KendoNotice />
                </div>
            </div>

            
        </div>
    </>
    )
}