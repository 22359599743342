import { GroupMasterInfo } from "./groupMaster";
import { Pagination } from "./pagination";
import { ProductType } from "./policyHeader";

export interface memberSearchResult {
    groupMasterInfo: GroupMasterInfo,
    memberInfo: memberSearchInfo
}

export interface memberSearchInfo {
    id: string
    policyHeaderGuid: string,
    productType: ProductType,
    startDate: Date,
    policyNumber: string,
    category: number,
    employeeID: string,
    name: string,
    firstname: string,
    surname: string,
    dob: Date,
    joinedDate: Date,
    reviewDate: Date,
    benefitAmount: number,
    premiumAmount: number
}

export interface memberSearchResponse {
    results: memberSearchResult[],
    pagination: Pagination
}

export enum MemberDataGridColumn {
    PolicyNumber = 'policyno',
    EmployeeId = 'employeeid',
    MemberName = 'membername',
    DOB = 'dob',
    DateJoined = 'joineddate',
    GroupName = 'group',
    ProductType = 'producttype',
    Category = 'cat',
    BenefitAmount = 'benefit',
    PremiumAmount = 'premium'
}

export enum DataGridSorting {
    Descending = 'desc',
    Ascending = 'asc'
}