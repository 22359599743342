import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap"
import ActionButton from '../../app/common/form/ActionButton'
import { useHistory } from 'react-router'
import { Grid, GridColumn, GridNoRecords, GridRowClickEvent } from '@progress/kendo-react-grid'
import { useStore } from "../../app/stores/store"
import { useEffect, useMemo } from "react"
import { UserHasPermission } from "../../app/utils/utils"
import { MemberDetails } from "../../models/member"
import ProtectedComponent from "../../app/common/routes/ProtectedComponent"

interface Props {
    member?: MemberDetails
}

const MemberClaimsListComponent = ({member}: Props) => {
    const history = useHistory();
    const {userStore, claimsStore, subMenuStore, preSelectStore} = useStore();
    const {userClaims} = userStore;
    const {getMemberClaims, memberClaims} = claimsStore;
    const {setMember} = preSelectStore;    
    const allowedRoles = useMemo(() => ['PortalAdmin', 'PortalUser'], []);

    // TODO: Permission checker to be refactored, implementation is convoluted.

    useEffect(() => {
        if(member && UserHasPermission(allowedRoles, userClaims?.role)) {
            getMemberClaims(member?.memberInfo.id!);
        }
    }, [member, userClaims, allowedRoles, getMemberClaims])

    const addClaim = (event: any) => {
        setMember({
            id: member?.memberInfo.id,
            label: member?.memberInfo.name
        });
        history.push(`/policy-headers/${subMenuStore.policyHeader?.id}/claims/new/${member?.memberInfo.id!}`)
    }

    const handleRowClick = (e: GridRowClickEvent) => {
        history.push(`/claims/${e.dataItem.id}`);
    }

    const parsedData = memberClaims?.map((c) => {
        const parsedRow = {...c, 
                notificationDate: new Date(c.notificationDate)
            }
        return parsedRow;
    });
    
    return (
        <ProtectedComponent allowedRoles={allowedRoles}>
            <div className='member-claims-list'>
                <Row className='border rounded-top border-bottom-0 bg-white mt-5'>
                    <Col className='col-lg-11 col-md-10 p-4 align-middle'>
                        <h2 className='m-0'>Claims</h2>
                    </Col>
                    <Col className='col-lg-1 col-md-2 p-0 border-start'>
                        <ActionButton clickHandler={addClaim} icon="icon-plus" />
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col className='p-0'>
                        <Grid 
                            onRowClick={handleRowClick} 
                            data={parsedData}
                            >
                            <GridColumn field="claimType" title="Type" />
                            <GridColumn 
                                field="notificationDate" 
                                title="Notification" 
                                filter="date"
                                format="{0:dd/MM/yyyy}"/>
                            <GridColumn 
                                field="benefit" 
                                title="Benefit" 
                                filter="numeric"
                                format="{0:c}" />
                            <GridColumn 
                                field="payment" 
                                title="Payment" 
                                filter="numeric"
                                format="{0:c}" />
                            <GridColumn field="status" title="Status" />
                            <GridNoRecords>No claims.</GridNoRecords>
                        </Grid>
                    </Col>
                </Row>
            </div>            
        </ProtectedComponent>
    )
}

export default observer(MemberClaimsListComponent)