import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { IdTypes, SubLinks } from "../../app/stores/subMenuStore";
import ClaimsInfo from "./ClaimsInfo";
import InsuredBenefits from "./InsuredBenefits";
import EditClaimForm from "./forms/EditClaimForm";
import LoaderComponent from "../../app/layout/LoaderComponent";
import InsurerPaymentForm from "./forms/InsurerPaymentForm";
import ClaimPaymentHistoryDatagrid from "./ClaimPaymentHistoryDatagrid";
import {Helmet} from 'react-helmet'

const ClaimsDetailsComponent = () => { 
    const {claimsGuid} = useParams<{claimsGuid: string}>();
    const {claimsStore, subMenuStore, userStore} = useStore();
    const {getClaimsDetails, selectedClaims, resetClaims } = claimsStore;
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleEditMode = (state: boolean) => {
        setEditMode(state);
    }

    useEffect(() => {
        // show submenu 
        subMenuStore.initPolicyHeader(IdTypes.claimId, claimsGuid);
        subMenuStore.setActivelink(SubLinks.claims);

        getClaimsDetails(claimsGuid);
        window.scroll(0, 0);

        return () => {
            //subMenuStore.resetPolicyHeader();
            resetClaims();
        }
    }, [subMenuStore, claimsGuid, getClaimsDetails, resetClaims])


    if(!selectedClaims && !subMenuStore.policyHeader) return <LoaderComponent content="Loading claim details..." />

    return (
        <>
            <Helmet title={`Claim details ${selectedClaims?.insurerClaimNo}`}/>
            <Row className='mt-4 mb-4'>
                <Col>
                    <h1 className='header-title'>Claim details</h1>
                </Col>
            </Row>
            <Row  className='gx-5'>
                <Col className='col-8'>
                    { !editMode && <ClaimsInfo claim={selectedClaims} editMode={handleEditMode} /> }
                    { editMode && <EditClaimForm claim={selectedClaims!} policyHeader={subMenuStore.policyHeader!} editMode={handleEditMode} /> }
                    { userStore.hasUserRole('PortalAdmin') &&  <InsurerPaymentForm claimsGuid={claimsGuid} selectedClaim={selectedClaims} /> }

                    <ClaimPaymentHistoryDatagrid claimGuid={claimsGuid} />                
                </Col>
                <Col className='col-4'>
                    <InsuredBenefits policyHeaderGuid={subMenuStore.policyHeader?.id}/>
                </Col>
            </Row>
        </>
    )
}

export default observer(ClaimsDetailsComponent)