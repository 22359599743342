import { makeAutoObservable } from "mobx";
import { ClaimDetails, ClaimNotification, EditClaim, InsurerPayment, NewClaim } from "../../models/claim";
import agent from "../api/agent";

export default class ClaimsStore {
    memberClaims: ClaimDetails[] = [];
    policyHeaderClaims: ClaimDetails[] = [];
    selectedClaims?: ClaimDetails;
    loadingInitial = true;
    paymentHistory?: InsurerPayment[];
    paymentTypes: string[] = [];

    constructor(){
        makeAutoObservable(this);
    }

    private setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setMemberClaims = (claims: ClaimDetails[]) => {
        this.memberClaims = claims;
    }

    private setPolicyHeaderClaims = (claims: ClaimDetails[]) => {
        this.policyHeaderClaims = claims;
    }

    setSelectedClaim = (claims?: ClaimDetails) => {
        this.selectedClaims = claims;
    }

    setPaymentHistory = (payments?: InsurerPayment[]) => {
        this.paymentHistory = payments;
    }

    setPaymentTypes = (paymentTypes: string[]) => {
        this.paymentTypes = paymentTypes;
    }

    addNewPayment = (payment: InsurerPayment) => {
        this.paymentHistory?.unshift(payment);
    }

    loadClaims = () =>{
        return [];
    }

    newClaim = async (newClaim: NewClaim) => {
        try {
            return await agent.Claims.create(newClaim)
        }catch(error){
            console.log(error);
        }
    }

    newInsurerPayment = async (insurerPayment: InsurerPayment) => {
        return await agent.Claims.createInsurerPayment(insurerPayment).then(response => this.addNewPayment(response))
    }

    editClaim = async (claim: EditClaim) => {
        return await agent.Claims.update(claim.id, claim);
    }

    newClaimNotification = async (newClaimNotification: ClaimNotification) => {
        return await agent.Claims.newClaimNotification(newClaimNotification)
    } 

    getClaimsDetails = async (id: string) => {
        try {
            let claim = await agent.Claims.getClaimById(id);
            this.setSelectedClaim(claim);
            return this.selectedClaims;
        }catch(error){
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getPaymentHistory = async (claimGuid: string) => {
        try {
            let paymentHistory = await agent.Claims.getPaymentHistory(claimGuid);
            this.setPaymentHistory(paymentHistory);
            return this.paymentHistory;
        }catch(error){
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    getPaymentTypes = async (policyheaderGuid: string) => {
        try {
            let paymentTypes = await agent.Claims.getPaymentTypes(policyheaderGuid);
            this.setPaymentTypes(paymentTypes);
            return this.paymentTypes;
        }catch(error){
            console.log(error);
            this.setLoadingInitial(false);
        }        
    }

    downloadPaymentHistory = async (claimGuid: string, paymentHistoryGuid: string) => {
        return await agent.Claims.downloadPaymentHistory(claimGuid, paymentHistoryGuid);
    }

    deletePaymentHistory = async (claimGuid: string, paymentHistoryGuid: string) => {
        return await agent.Claims.deletePaymentHistory(claimGuid, paymentHistoryGuid);
    }

    getMemberClaims = async (memberId: string) => {
        try {
            let claims = await agent.Claims.getByMemberId(memberId);
            this.setMemberClaims(claims);
            return this.memberClaims;
        }catch(error){
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getPolicyHeaderClaims = async (groupPolicyHeaderId: string) => {
        try {
            let claims = await agent.Claims.getByPolicyHeaderId(groupPolicyHeaderId);
            this.setPolicyHeaderClaims(claims);
            return this.policyHeaderClaims;
        }catch(error){
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    resetClaims = () => {
        this.selectedClaims = undefined;
        this.memberClaims = [];
        this.policyHeaderClaims = [];
    }
}