import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import agent from '../../../app/api/agent'
import fileDownload from 'js-file-download'
import mime from 'mime-types'
import {Helmet} from 'react-helmet'

interface MemberMovementDataFormProps {
    policyHeader?: PolicyHeaderListItem
}

const MemberMovementDataForm = ({policyHeader}: MemberMovementDataFormProps) => {
  
    const downloadTemplate = async () => {
        await agent.StaticFiles.downloadTemplate('movementdata').then(blob => {
            fileDownload(blob, `member-movement-data-template.${mime.extension(blob.type)}`)
        })
    }

    const headerTitle = 'Member movement data';
    const headerDescription = (
            <>
                <p>It is important to ensure that member list is up-to-date under a Group Risk Only Superannuation Policy. However this is optional if the Group Policy is non-Superannuation. </p>
                <p>To upload member movements for your policy, please download the <a href='#downloadTemplate' onClick={downloadTemplate}>member movement template</a>, update in Excel with the required member data, and upload back once ready.</p>
            </>
        )

    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.MemberMovementData,
        file: undefined,
    }

    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.MemberMovementData} 
                        initialValues={initialValues}    
                        enablePolicySelection={policyHeader.isCurrent}
                    />
            }
        </>
    )
}

export default observer(MemberMovementDataForm)