import { useState } from 'react';
import { Modal, ModalProps, Button } from 'react-bootstrap'

type ConfirmationModalProps = {
    headerTitle: string,
    bodyMessage: string,
    onConfirm: () => void,
    confirmBtnVariant?: 'danger' | 'primary',
    confirmBtnLabel?: string,
    confirmBtnOnClickLabel?: string,
    hideConfirmBtn?: boolean,
    disableOnConfirmBtn?: boolean,

} & ModalProps;

const ConfirmationModal = ({headerTitle, bodyMessage, onConfirm, confirmBtnVariant,
    confirmBtnLabel,
    confirmBtnOnClickLabel,
    hideConfirmBtn,
    disableOnConfirmBtn, 
    onHide,
    ...props}: ConfirmationModalProps) => {

    const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
    const [confirmLabel, setConfirmLabel] = useState<string|undefined>( confirmBtnLabel || 'Delete');
    const handleConfirm = () => {
        
        if(disableOnConfirmBtn) setDisableConfirmBtn(true);
        if(confirmBtnOnClickLabel) setConfirmLabel(confirmBtnOnClickLabel);
        onConfirm()
    }

    const resetModalState = () => {
        setDisableConfirmBtn(false);
        setConfirmLabel(confirmBtnLabel || 'Delete')
    }


    return (
        <Modal { ...props} centered onExited={resetModalState}>
            <Modal.Body>
                <h4 className='mb-3 mt-3'>{headerTitle}</h4>
                <p>{bodyMessage}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant='outline-secondary' 
                    size='lg' 
                    disabled={disableConfirmBtn && !hideConfirmBtn}
                    onClick={onHide}> {!hideConfirmBtn ? 'Cancel' : 'Close'}</Button>
                { !hideConfirmBtn &&
                <Button 
                    variant={confirmBtnVariant || 'danger'} 
                    disabled={disableConfirmBtn}
                    size='lg' 
                    onClick={handleConfirm}>{confirmLabel} </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal
