import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Notification } from '@progress/kendo-react-notification';
import { useStore } from "../../stores/store";
import { useLocation } from "react-router-dom";

function NoticeComponent(){
    const {noticeStore} = useStore();
    const {notice, handleClose, closeFlashMessage} = noticeStore;
    const location = useLocation();
    const [noticeLocation, setNoticeLocation] = useState<string|undefined>();

    useEffect(() => {
        if(notice?.flashMessage){
            closeFlashMessage();
        }
        if(!notice?.flashMessage && noticeLocation !== location.key) {
            handleClose();
        }
        setNoticeLocation(location?.key);
    },[closeFlashMessage, notice, location.key, noticeLocation, handleClose]);

    return(
        <>
        {notice && 
            <Notification
                className='mb-3'
                type={{ style: notice.noticeState , icon: true }}
                closable={true} 
                onClose={handleClose}>    
                
                <strong dangerouslySetInnerHTML={{ __html: notice.message }} />
    
                {notice.errors && notice.errors?.length > 0 &&
                    <ul className='mt-2'>
                        { notice.errors.map((error, i) => <li key={i}> {error}</li> ) }
                    </ul>
                }
            </Notification>
            }
        </>
    )
}

export default observer(NoticeComponent);