import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import {Helmet} from 'react-helmet'

interface ServiceLevelAgreementFormProps {
    policyHeader?: PolicyHeaderListItem
}

const ServiceLevelAgreementForm = ({policyHeader}: ServiceLevelAgreementFormProps) => {
    const headerTitle = 'Service level agreement';
    const headerDescription = (
            <>
               <p>You are about to upload a service level agreement that you have agreed with your client. Once uploaded, it will replace the existing agreement, if any, and will be displayed under the Employer portal. </p>
            </>
        )
        
    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.ServiceAgreement,
        file: undefined,
    }

    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    :  <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.ServiceAgreement} 
                        initialValues={initialValues}
                    />
            }
        </>
    )
}

export default observer(ServiceLevelAgreementForm)