
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React  from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function NotFound(props: any){
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }

    return (
        <> 
            <Container>
                <Row className="pt-5 pb-5">
                    <Col>
                        <h1>404: Page not found</h1>
                        <p>The page you are looking for doesn't exist.</p>
                        <Button onClick={handleClick} primary={true} look="flat"> Return to home </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default observer(NotFound)