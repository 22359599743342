import { Button } from "react-bootstrap";

export type Props = {
  clickHandler: (event: any) => void,
  icon?: string,
  label?: string,
  cssClass?: string
};

const ActionButton = ({clickHandler, icon, label, cssClass}: Props) => {
  return (
    <>
      <Button tabIndex={0}
        className={ 'action-button btn-flat-light-yellow-bg border-0' + (cssClass || '')} 
        onClick={clickHandler}>
        <i className={`icon ${icon}`}></i>
        {label}
      </Button>
    </>
  )
};

export default ActionButton