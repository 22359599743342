import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileUploadType } from '../../../models/files'
import PolicyHeaderFileUploadForm from './PolicyHeaderFileUploadForm'
import { PolicyHeaderListItem } from '../../../models/subMenu'
import LoaderComponent from '../../../app/layout/LoaderComponent'
import {Helmet} from 'react-helmet'

interface DataQueryFormProps {
    policyHeader?: PolicyHeaderListItem
}

const DataQueryForm = ({policyHeader}: DataQueryFormProps) => {
    const headerTitle = 'Data query';
    const headerDescription = (
            <>
                <p>If you have received a request for data query confirmation, please download the data query request in Excel under Files/Review Data, add the information require, then upload.</p>
            </>
        )

    const initialValues = {
        policyHeaderGuids: [policyHeader?.id],
        currentPolicyHeader: policyHeader?.id,
        fileUploadType: FileUploadType.DataQuery,
        file: undefined,
        policyHeaderReviewDate: undefined,
    }

    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            {
                (!policyHeader) 
                    ? <LoaderComponent content={`Loading ${headerTitle} form`} />
                    : <PolicyHeaderFileUploadForm 
                        policyHeader={policyHeader} 
                        headerTitle={headerTitle} 
                        headerDescription={headerDescription} 
                        fileUploadType={FileUploadType.DataQuery} 
                        initialValues={initialValues}    
                        enableReviewDateSelection={policyHeader.isCurrent}
                        // enablePolicySelection={policyHeader?.isCurrent}            
                    />
            }
        </>
    )
}

export default observer(DataQueryForm)