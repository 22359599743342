export enum FileTypes {
	Document,
	Excel,
	Folder,
    StaticFile,
    Image
}
 
export interface PolicyFile {
	id?: string,
    policyHeaderGuid: string,
	title: String,
    templateName?: string,
	fileType: FileTypes,
	ext?: string,
	deletable?: boolean,
	createdDate: Date,
	subFiles?: PolicyFile[]
}

export interface PolicyHeaderFiles {
    policyFiles: PolicyFile[] | [],
	reviewReportsFiles: PolicyFile[] | [],
	reviewDataFiles:PolicyFile[] | [],
	claimsFiles: PolicyFile[] | [],
	memberMovementDataFiles: PolicyFile[] | []
}

export enum FileUploadType {
    MemberMovementData,
    DataCollection,
    DataQuery,
    PolicySummary,
    ServiceAgreement,
    UnderwritingDecision,
    ClaimBrochureForms
}

export interface FileUploadPayload {
    policyHeaderGuids: string[],
    currentPolicyHeader: string,
    memberGuid?: string,
    inputFileName?: string,
    fileUploadType: FileUploadType,
    file?: File | undefined,
    policyHeaderReviewDate?: string,
}
