import { Pagination } from "./pagination";
import { CoverType } from "./policyHeader";

export enum ClaimStatus {
    InitialAssessment = 'Initial Assessment',
    InternalReview = 'Internal Review',
    Approved = 'Approved',
    Declined = 'Declined',
    Closed = 'Closed'
}

export interface ClaimSearchResult { 
    id: string,
    employeeID: string,
    memberGuid: string,
    memberName: string,
    coverType: CoverType, 
    notificationDate: Date,
    benefitAmount: number,
    paymentAmount: number,
    claimStatus: ClaimStatus       
}

export interface ClaimSearchResponse {
    results: ClaimSearchResult[],
    pagination: Pagination
}