import React from 'react'
import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg'
import { Alert, Container } from 'react-bootstrap'

const Login = () => {
    return (
        <Container fluid className='ids-login'>
            <div className="row d-flex align-items-stretch bg-white" style={{ height: "100vh" }} >
                <div className="col-4 d-flex align-self-center bg-light text-dark" style={{ height: "100%" }}>
                    <div className="align-self-center p-4">
                        <LogoSvG className='logo'/>
                        <h1 className='tag-line'>
                            <span className="text-muted">Imagine a world where </span>
                            financial worry is diminished...
                        </h1>
                        <div className="fixed-bottom p-4 az-nga-group">
                            PART OF THE <br/>
                            AZ NGA GROUP
                        </div>
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <Alert variant='success' className='text-success d-inline-block mb-4  p-2 ps-3 pe-3'>
                            <i className='icon icon-check'/> If you have an account, we'll email you a reset link.</Alert>
                            <Alert variant='danger' className='text-danger d-inline-block mb-4 p-2  ps-3 pe-3'>
                            <i className='icon icon-info'/> Invalid username / password.</Alert>
                            <h1 className='mb-3'>Log into Certe</h1>
                            <p className='sub-text'>Enter your details below</p>
                            <form className="mt-5 login-form">
                                <div className="mb-4">
                                    <label htmlFor="certe-email" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="certe-email" placeholder="email"/>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="certe-password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="certe-password" placeholder="***********"/>
                                </div>
                                <button className='mt-4 k-button k-primary w-100'> LOG IN</button>
                            </form>
                            <a href="#forgotPassword" className='mt-4 float-end'>Forgot Password?</a>

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Login