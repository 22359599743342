import { makeAutoObservable, observable, runInAction } from "mobx";
import { User } from "oidc-client";
import { history } from "../..";

import jwtDecode from "jwt-decode";
import OidcService from "../api/OidcService";
import agent from "../api/agent";

interface UserClaims {
    groupId: number,
    role: string,
    accessLevelId?: number
}

interface UserClaimsResponse {
    group_id: number,
    role: string,
    access_level_id?: number
}

export default class UserStore {
    user?: User | null;
    userClaims?: UserClaims | null;
    accessToken?: string;
    groupMasterId?: string; // User's default group master id. Admin will have a 0 guid
    
    // initiate oidc client
    oidcClient = OidcService;

    constructor() {
        makeAutoObservable(this, {
            user: observable,
            accessToken: observable,
            userClaims: observable
        });

        // setup event handlers
        this.setOidcClientEventHandlers();        
    }

    private setOidcClientEventHandlers = () => {
        this.oidcClient.userManager.events.addUserLoaded((user: User) => {
            this.setUser(user);
            this.getUserClaims();
        });
        
        // this.oidcClient.userManager.events.addSilentRenewError((e: any) => {
        //    console.log('addSilentRenewError', {e})
        // });

        // this.oidcClient.userManager.events.addSilentRenewError(() => {
        //     const datenow = new Date(Date.now());
        //     console.log('oidc event: addSilentRenewError', datenow)
        // })
        // this.oidcClient.userManager.events.addUserSignedIn(() => {
        //     const datenow = new Date(Date.now());
        //     console.log('oidc event: addUserSignedIn', datenow)
        // })
        // this.oidcClient.userManager.events.addUserSignedOut(() => {
        //     const datenow = new Date(Date.now());
        //     console.log('oidc event: addUserSignedOut', datenow)
        // })
        // this.oidcClient.userManager.events.addUserSessionChanged(() => {
        //     const datenow = new Date(Date.now());
        //     console.log('oidc event: addUserSessionChanged', datenow, this.user)
        // })

        this.oidcClient.userManager.events.addAccessTokenExpired(() => {
            this.signoutRedirect(); // replace with session expired
        });
    }

    private setUser = (user?: User | null) => {
        this.user = user;
    }

    private setAccessToken = (accessToken?: string ) => {
        this.accessToken = accessToken;
    }

    private setUserClaims = (userClaims?: UserClaims | null) => {
        this.userClaims = userClaims;
    }

    private setGrouMasterId = (groupMasterId?: string) => {
        this.groupMasterId = groupMasterId;
    }

    getLocalStorageToken = () => {
        const tokenKey = `${process.env.REACT_APP_LOCALSTORAGE_TOKEN_PREFIX}:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
        const localStorageToken = localStorage.getItem(tokenKey);
        return (!localStorageToken) ? null : JSON.parse(localStorageToken);
    }

    isTokenExpired = (userToken?: User) : boolean => {
        if(!userToken) return true;
        return (userToken.expires_at <= Math.floor(Date.now()/ 1000))
    }

    getCurrentSession = async () => {
        return await this.oidcClient.userManager.getUser().then( async (user: User | null) => {
            this.oidcClient.userManager.clearStaleState();
            if(user){
                this.setUser(user);
                this.getUserClaims();
                this.signinSilent(); //extend token
                return this.user
            }else{
                await this.signInRedirect();
            }
        })   
    }

    getTokenStatus = async () => {
        let token = this.getLocalStorageToken();
        let isTokenExpired = this.isTokenExpired(token);

        if(!token || isTokenExpired) return this.signInRedirect();
        
        return await this.oidcClient.userManager.querySessionStatus()
                .catch( async (error) => {
                    if(isTokenExpired && error.message === 'login_required') {
                        await this.signInRedirect();
                    }
                });
    }
    
    getUser = async () => {
        runInAction( async () => {
            await this.getTokenStatus()
                .then( async () => {
                    let user = await this.oidcClient.userManager.getUser();
                    this.setUser(user);
                    this.getUserClaims();
                    this.oidcClient.userManager.clearStaleState();
                });

            return this.user;
        });
    }

    getUserSid = () => {
        const token = this.getLocalStorageToken();
        return (token) ? token.profile.sid : null;
    }

    getUserClaims = () => {
        runInAction( () => {
            if(this.user?.access_token){
            let userClaims = jwtDecode<UserClaimsResponse>(this.user?.access_token);

            this.setUserClaims({
                    groupId: userClaims.group_id, 
                    role: userClaims.role,
                    accessLevelId: userClaims.access_level_id ?? 0,
                });

            this.getGroupMasterId();
            return this.userClaims;
            }
        });
    }

    hasUserRole = (userRole: string) => {
        return this.userClaims?.role.indexOf(userRole) !== -1;
    }

    /**
     * User will be redirected to IDS to login
     * Once the login is completed, user will be redirected to the oidc redirect_uri with the IDS token.
     */
    signInRedirect = async () =>  {
        await this.oidcClient.userManager.signinRedirect();
    }

    /**
     * This should be executed in the oidc redirect_uri page.
     * signInRedirectCallback handles the token issued by IDS.
     */
    signInRedirectCallback = async () => {        
        await this.oidcClient.userManager.signinRedirectCallback()
            .then(() => {
                this.oidcClient.userManager.clearStaleState();
            });   
    }

    /**
     * signoutRedirect will logout the user from IDS. This will invalidate the issued token.
     * It also clears the staleStates and tokens in the browser.
     */
    signoutRedirect = async () => {
        await this.oidcClient.userManager.signoutRedirect({
            id_token_hint: this.user?.id_token
        }).then(() => {
            this.oidcClient.userManager.clearStaleState()
            this.oidcClient.userManager.removeUser()
        });
    }

    /**
     * Once the user has successfully logged out, IDS will redirect the user to the post_logout_redirect_uri.
     * This should be called when the post_logout_redirect_uri page loads.
     */
    signoutRedirectCallback = async () => {
        await this.oidcClient.userManager.signoutRedirectCallback()
            .then(() => {
                this.oidcClient.userManager.clearStaleState()
                this.oidcClient.userManager.removeUser()
                this.setUser(null);
                this.setUserClaims(null);
                localStorage.clear();
                sessionStorage.clear();
                history.replace('/login');
            });
    }

    /**
     * signinSilent refreshes the token in the background. 
     * This will extend token validity
     */
    signinSilent = async () => {
        if(this.user){
            await this.oidcClient.userManager.signinSilent()
                .then((user) => {
                    // console.log('signinSilent',user);
                    this.setUser(user);
                })
                .catch((err) => {
                    console.log('signinSilent',err);
                });
        }
    };

    /**
     * signinSilentCallback 
     */
    signinSilentCallback = async () => {
        await this.oidcClient.userManager.signinSilentCallback()
    }

    getGroupMasterId = async () => {
        await  agent.User.getGroupMasterId().then(res => {
            this.setGrouMasterId(res.toString());
        })
    }
}
