import { Pagination } from "./pagination";
import { PolicyHeaderStatus, ProductType } from "./policyHeader";


export enum PolicyDataGridColumn {
    PolicyNo = 'policy',
    GroupName = 'group',
    ProductType = 'producttype',
    Insurer = 'insurer',
    MemberCount = 'members',
    PremiumAmount = 'premium',
    RenewalDate = 'renewal',
    PolicyStatus = 'status'
}

export interface PolicySearchResult {
    premium: number,
    members: number,
    id: string,
    policyNumber: string,
    groupName: string,
    productType: ProductType,
    insurer: string,
    reviewDate: Date,
    ceaseDate: Date,
    status: PolicyHeaderStatus
}

export interface PolicySearchResponse {
    results: PolicySearchResult[],
    pagination: Pagination
}