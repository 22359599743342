import { Skeleton } from '@progress/kendo-react-indicators'
import { Col, Row } from 'react-bootstrap'
import ProtectedComponent from '../../app/common/routes/ProtectedComponent'
import DOMPurify from 'dompurify';
import Note, { NewNote, NoteTypes } from '../../models/note'
import ActionButton from '../../app/common/form/ActionButton'
import { ChangeEvent, useEffect, useState } from 'react'
import { Button } from '@progress/kendo-react-buttons'
import { useStore } from '../../app/stores/store'
import { observer } from 'mobx-react-lite'

import { Formik, Form, Field, ErrorMessage  } from 'formik'
import * as Yup from 'yup';
import ConfirmationModal from '../../app/common/form/ConfirmModal'
import { EmailUsername } from '../../app/utils/utils'

const NoteValidationSchema = Yup.object().shape({
    notes: Yup.string().required('Note is required').trim().min(1, 'Must be at least 1 character.'),
    username: Yup.string().required('Username is required'),
    notesType: Yup.string().required('Notetype is required'),
    notesOf: Yup.string().required('NoteOf is required')
});

interface Props {
    listTitle: string,
    notesType: NoteTypes,
    notesOf: string, // Guid of the targed noteType
}

const NotesListComponent = ({listTitle, notesType, notesOf}: Props ) => {
    const {notesStore, userStore} = useStore();
    const {notes,
            selectedNote,
            createNewNote, 
            deleteNote, 
            getNotes,
            setNotes,
            selectNote,
        } = notesStore;
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');
    const [noteKeyword, setNoteKeyword] = useState<string>('');
    const [openSearchBox, setOpenSearchBox] = useState(false);

    const noteFormInitialValue = {
        notes: '',
        username: userStore.user?.profile.email!,
        notesType: notesType,
        notesOf: notesOf
    };

    useEffect(() => {
        getNotes(notesType, notesOf);
        
        return () => {
            setNotes(undefined)
        }
    }, [setNotes, getNotes, notesType, notesOf])

    const toggleForm = () => {
        setOpenForm(!openForm);
        if(!openForm){
            setOpenSearchBox(false);
            setNoteKeyword('');
        }
    }

    const confirmDelete = (note: Note) => {
        selectNote(note)
        setModalBodyMessage(`Please confirm you like to delete the note from ${new Date(note.createDate).toLocaleDateString()}. This action is permanent.`);
        setModalShow(true);
    }

    const handleCancel = () => {
        setModalShow(false);
        selectNote(undefined);
    }

    const handleDeleteConfirm = () => {
        deleteNote(selectedNote).then(() => {
            setModalShow(false);
        }).catch(error => {
            console.log(error);
        })
        return;
    }

    const handleSubmit = (newNote: NewNote) => {
        createNewNote(newNote).then(() => {
             setOpenForm(false);
        }).catch(error => {
            console.log(error)
        });
    }

    const clearNote = () => {
        setOpenForm(false);
    }

    const showSearchBox = (status: boolean) => {
        setOpenSearchBox(status);
        if(!status) {
            setNoteKeyword('');
        }
    }

    const handleNoteKeyword = (e: ChangeEvent<HTMLInputElement>) => {
        setNoteKeyword(e.target.value);
    }

    const highlightWord = (text: string, keyword?: string) => {

        if(!keyword || keyword === '') return text;
        var re = new RegExp(keyword, 'ig');
        return (
            text.replace(re, '<strong>'+keyword+'</strong>')
        )
    }
 
    return (
        <ProtectedComponent  allowedRoles={['PortalAdmin']}>  
            <div className='notes-component'>
                <Row className='border rounded-top bg-white'>
                    <Col className='col-9 p-3 ps-3 pe-3'>
                        {!openSearchBox && 
                            <>
                                <h3 className='m-0 float-start ps-0 p-2'>{listTitle}</h3>
                                <i onClick={() => showSearchBox(true)} className='icon icon-magnifying-glass float-end p-3 pe-0 clickable' />
                            </>
                        }
                        {openSearchBox && 
                            <>
                            <div className="input-group">
                                <input type="text" autoFocus placeholder="search note" value={noteKeyword} onChange={handleNoteKeyword} className='form-control' /> 
                                <i className='icon icon-cross float-end p-3 pe-0 clickable' onClick={() => showSearchBox(false)} />
                            </div>
                            </>
                        }
                    </Col>
                    <Col className='col-3 border-start d-grid p-0'>
                        <ActionButton clickHandler={toggleForm} icon="icon-plus" />
                    </Col>
                </Row>

                { openForm && 
                    <Formik
                        initialValues={noteFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={NoteValidationSchema}>
                        {({ isSubmitting }) => (
                            <Form>
                                <Row className='border border-top-0 pt-3 pb-3 bg-white'>
                                    <Col className='col-9'>
                                        <Field  
                                            autoFocus
                                            id="notes" 
                                            name="notes" 
                                            placeholder="type note here" 
                                            as="textarea"
                                            rows={3} 
                                            className="form-control notes-textarea"
                                            />
                                        <ErrorMessage name="notes" render={ msg => 
                                            <small className="text-warning">{msg}</small>
                                            } />
                                    </Col>
                                    <Col className='col-3 text-start p-0'>
                                        <Button look="flat" type="submit" disabled={isSubmitting}> <i className='icon icon-check'></i></Button>    
                                        <Button look="flat" onClick={clearNote}> <i className='icon icon-cross'></i></Button>    
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }

                { !notes &&
                    <Row className='border border-top-0 pt-3 pb-3  bg-white'>
                        <Col className='col-8'>
                            <div className='mb-3'>
                                <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                                <Skeleton shape={"text"} style={{ width: "60%" }} /> 
                            </div>
                        </Col>
                        <Col className='col-4'>
                            <small className='text-muted'><Skeleton shape={"text"} /></small>
                        </Col>
                    </Row>
                }
                
                { notes && notes.filter(n => n.notes.toLowerCase().includes(noteKeyword.toLowerCase())).length === 0 &&
                    <Row className='border border-top-0 pt-3 pb-3 bg-white'>
                        <Col >
                            No notes found.
                        </Col>
                    </Row>
                }  

                { notes && notes.filter(n => n.notes.toLowerCase().includes(noteKeyword.toLowerCase())).map((n, index) => (
                        <Row 
                        key={index}
                        className={`border border-top-0 pt-3 pb-3 note-item ${(selectedNote?.id === n.id) ? ' confirm-delete ': ''}`} >
                            <Col className='col-9'>
                                <div className='mb-3' style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightWord(n.notes, noteKeyword))}}></div>
                                <small className='text-muted'>{ EmailUsername(n.username)}</small>
                            </Col>
                            <Col className='col-3 position-relative '>
                                <small className='text-muted position-absolute top-0 end-0 me-3'>{new Date(n.createDate).toLocaleDateString()}</small>
                                <i className='icon icon-trash position-absolute bottom-0 end-0 me-3'
                                    onClick={() => {
                                        selectNote(n)
                                        confirmDelete(n)
                                    }}></i>
                            </Col>  
                        </Row>
                    )
                )}

                <ConfirmationModal 
                    show={modalShow} 
                    headerTitle='Delete note'
                    bodyMessage={modalBodyMessage}
                    onConfirm={handleDeleteConfirm}
                    onHide={handleCancel}/>
            </div>
        </ProtectedComponent>
    )
}

export default observer(NotesListComponent)