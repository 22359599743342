import { DateInput } from "@progress/kendo-react-dateinputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { MaskedTextBox, NumericTextBox } from "@progress/kendo-react-inputs";
import classNames from "classnames";
import { useField, ErrorMessage, Field } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { PolicyHeaderListItem } from "../../../models/subMenu";
import { useStore } from "../../stores/store";
import { GetFileInfo } from "../../utils/utils";
import MemberSearchBar from "../search-bars/MemberSearchBar";
import _ from 'lodash'
import InfoToolTip from "./InfoToolTip";

export const FormikDateInput = (props: any) => {
    const [field] = useField(props);
    
    return (
    <>
        <label className='text-muted' htmlFor={props.name}>{props.label}</label>
        <DateInput       
            {...field}
            {...props} 
            label=''
            placeholder={"dd/mm/yyyy"}
            format="dd/MM/yyyy" formatPlaceholder="formatPattern" 
            />
        <ErrorMessage 
            name={props.name}
            render={ msg => 
                    <small className="text-warning">{msg}</small>
                } 
            />    
    </>
    );
};

export const FormikInput = (props: any) => {
    const [field, meta] = useField(props);
    const isValid = useState(meta.error);
    
    return (
    <>
        { props.type !== 'hidden'  &&
            <label className='text-muted' htmlFor={props.name}>{props.label}</label>
        }
        <Field 
            {...field}
            {...props}
            validate={meta.error}
            className={classNames('form-control',{
                'k-state-invalid': isValid
            })}/>
        <ErrorMessage name={props.name} render={ msg => 
            <small className="text-warning">{msg}</small>
        } />      
    </>
    );
};


export const FormikMaskedInput = (props: any) => {
    const [field, meta] = useField(props);
    
    return (
    <>
        <label className='text-muted' htmlFor={props.name}>{props.label}</label>
        <MaskedTextBox 
            {...field}
            {...props}
            label=""
            validate={meta.error}
            className={'form-control'}/>
        <ErrorMessage name={props.name} render={ msg => 
            <small className="text-warning">{msg}</small>
        } />      
    </>
    );
};

export const FormikAmountInput = (props: any) => {
    const [field] = useField(props);
    return (
    <>
        <label className='text-muted' htmlFor={props.name}>{props.label}</label>
        {props.name==="assessedBenefit" && <InfoToolTip id='aba-info' toolTipMessage='Claim benefit which has been accepted by insurer, please enter the lump sum for Death or TPD claim; or the monthly benefit for GSC claim.' />}
        <NumericTextBox 
            {...field}
            {...props}
            label=''
            min={0}
            className='form-control'/>
        
        <ErrorMessage name={props.name} render={ msg => 
            <small className="text-warning">{msg}</small>
        } />      
    </>
    );
};

export const FormikFileUpload = (props: any) => {
    const [field] = useField(props);
    return (
        <>
        <label htmlFor={props.name}>{props.label}</label>
        <Field 
            {...field}
            {...props}
            type="file"
            />
        <ErrorMessage name={props.name} render={ msg => 
            <small className="text-warning">{msg}</small>
        } />      
        </>
    )
}

export const FormikFileDropzone = observer((props: any) => {
    const {fileStore} = useStore();
    const [field, meta, helpers] = useField(props);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isValid, setIsValid] = useState(meta.error);

    const maxFileSize = props.maxFileSize ||  10; //Megabytes
    
    const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
        disabled: props.disabled,
        accept: 'image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,text/*,application/vnd.ms-outlook',
        maxFiles: 1,
        maxSize: 1024 * 1024 * maxFileSize,
        onDrop: acceptedFiles => {
            fileStore.setUploadProgress(0);
            helpers.setValue(acceptedFiles[0])
            helpers.setTouched(true)
            }
        },
        
    );

    const handleRemoveFile = () => {
        helpers.setValue(undefined)
    }

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) 
            return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
    <>
        { !field.value &&
        <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            { isDragActive ? (
                <div className="text-center">
                    <strong className="text-muted">Drop the files here...</strong>
                </div>
            ) : (
                <>
                    <div className="text-center p-5">
                        <i className="icon icon-upload"/>
                        <h3 className="mb-0">Drag and drop</h3>
                        <strong className="text-muted">your file here, or <span className="text-decoration-underline">browse</span></strong>
                    </div>
                </>
            )}
        </div>
        }
        
        {field.value && fileStore.uploadProgress !== 100 &&         
            <>
                <div className="k-widget k-upload bg-white border-top-0">
                    <ul className="k-upload-files k-reset">
                        <li className="k-file mt-1 mb-2" >
                            <div className="k-file-single">
                                <div className='k-progressbar mt-3'>
                                    <ProgressBar now={fileStore.uploadProgress || 0} variant="warning" className="rounded" />
                                </div>
                                <span className="k-file-group-wrapper">
                                    <span className={`k-file-group k-icon icon ${GetFileInfo(field.value.name).icon}`}></span>
                                </span>
                                <span className="k-file-name-size-wrapper">
                                    <span className="k-file-name" title={field.value.name }>{ field.value.name }</span>
                                    <span className="k-file-validation-message">{ formatBytes( field.value.size )}</span>
                                </span>
                                <button type="button" className="k-button k-button-icon k-flat k-upload-action" disabled={props.disabled} >
                                    <span
                                    aria-label="Remove"
                                    title="Remove"
                                    className="k-icon k-delete k-i-x"
                                    onClick={handleRemoveFile}>
                                </span>
                                </button>
                            </div>
                        </li>   
                    </ul>
                </div>
            </>
        }

        { 
            (!_.isEmpty(fileRejections))
                ?  fileRejections[0]?.errors.map(m => {
                        switch (m.code) {
                            case 'file-too-large':
                                return <small className="text-warning">File too large. Max file size allowed is {maxFileSize}MB</small>
                            case 'file-invalid-type':
                                return <small className="text-warning">Allowed file types are images, text, .pdf, .xls, .doc, .csv, .msg</small>
                            default:
                                return <small className="text-warning">{m.message}</small>
                        }
                    })
                :  <ErrorMessage name={props.name} render={ msg => 
                    <small className="text-warning">
                        {msg}
                    </small>
                } />   
        }
      </>
    );
})

export const FormikCheckbox = (props: any) => {
    const [field, meta] = useField(props);
    const isSelected = (meta.value.indexOf(props.value) !== -1 || props.isfixedvalue) ;
    return (
    <>  
        <div 
            key={`div-checkbox-${props.value}`}
            className={classNames('mt-2',{
            'bg-light-yellow': (!props.disabled) || !props.isfixedvalue,
            'bg-light-gray': props.isfixedvalue
        })} >
            <label className={classNames('p-3 w-100',{
                'clickable': (props.disabled || !props.isfixedvalue)
            })} htmlFor={props.value}  key={`label-checkbox-${props.value}`}>
                <Field  
                    {...props}
                    {...field}
                    type='checkbox'
                    label=''
                    id={props.value}
                    value={props.value}
                    tabIndex="0"
                    selected={props.isfixedvalue || isSelected}
                    className='k-checkbox '
                    disabled={props.disabled || props.isfixedvalue}
                    key={`checkbox-${props.value}`}
                    />
                <span className="ms-2">
                    <strong>{props.policy.groupName} {props.policy.insurer} - {props.policy.productType}</strong> 
                    <small className='ms-2 text-muted'>({props.policy.policyNumber})</small>
                </span>
            </label>
        </div>
    </>
    );
}

interface FormikPolicyHeaderCheckboxesProps {
    label?: string| React.ReactNode,
    name: string,
    id?: string,
    policyHeaders: PolicyHeaderListItem[],
    selectedValues?: string[],
    disabled?: boolean
}

export const FormikPolicyHeaderCheckboxes = observer((props: FormikPolicyHeaderCheckboxesProps) => {
    return (
        <>
            { props.policyHeaders && 
                <>
                    <label htmlFor={props.name} className='text-bold mb-3' style={{fontWeight: 500}}>
                        {props.label}
                    </label>       
                    {
                        (!props.policyHeaders[0])
                        ? <Skeleton shape={"text"} style={{ width: "100%", height: "75px" }} />
                        :  <>
                            {
                                props.policyHeaders.map((p, index) => (
                                    <>
                                    <FormikCheckbox 
                                        key={`policyHeader-${index}`}
                                        name={props.name} 
                                        value={p.id}
                                        policy={p}
                                        isfixedvalue={props.selectedValues?.includes(p.id)}
                                        disabled={props.disabled}
                                        />
                                    </>
                                ))
                            }
                        </>
                        
                    }
                </>
            }
        </>
    )
});

interface FormikSelectProps {
    name: string,
    id: string,
    placeholder?: string,
    label?: string,
    type?: string,
    // value?: string,
    options?: string[], // | {[key: string]: any}[], 
    // onChange: any
    error?: string
}

export const FormikSelect = ({...props}: FormikSelectProps) => {
    const [field, meta, helpers] = useField(props.name);
    return (
        <>
            <Form.Group>
                <Form.Label>{props.label}</Form.Label>
                <Form.Select 
                    name={props.name} 
                    id={props.id}
                    value={field.value} 
                    isInvalid={!!props.error}
                    onChange={(e: any) => {
                        helpers.setValue(e.target.value)
                    }}
                    
                    >
                    <option>Select</option>

                    { props.options && props.options.map(opt => {
                            //  if(typeof opt === Object[]){
                            //     return (<option value={Object.key(opt)}>{Object.value(opt)}</option>)
                            //}else{


                                return (<option value={opt}>{opt}</option>)   
                            //}
                        })
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid" className='mt-3 mb-3'>
                    {props.error}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    )
}


// TODO: consider creating a config settings for member search bar
interface FormikMemberSelectProps  {
    label?: string,
    memberName?: string,
    memberGuid?: string,
    policyHeaderGuid?: string,
    focusOnLoad?: boolean,
    suggestDob?: boolean,
    suggestDjp?: boolean,
    suggestPolicyNum?: boolean,
    updateDatagridOnClose?: boolean
}

export const FormikMemberSelect = observer((props: FormikMemberSelectProps) => {
    const {searchMemberStore} = useStore();
    const {selectedMember} = searchMemberStore;
    return (
        <>  
            <MemberSearchBar 
                {...props}
                keyword={props.memberName}
                policyHeaderId={props.policyHeaderGuid}
                placeholder={'Search member'}
                />
            <FormikInput 
                name="memberGuid" 
                type='hidden' 
                value={selectedMember?.memberInfo.id || props.memberGuid ||  ''} />
        </>
    )
})