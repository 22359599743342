import { makeAutoObservable } from "mobx";
import { GroupMasterDetails } from "../../models/groupMaster";
import Note from "../../models/note";
import agent from "../api/agent";

export default class GroupMasterStore {
    groupMaster?: GroupMasterDetails;
    notes?: Note[]; 
    
    constructor(){
        makeAutoObservable(this);
    }

    setGroupMaster = (groupMaster?: GroupMasterDetails) => {
        this.groupMaster = groupMaster;
    }

    getGroupMasterDetails = (groupMasterId: string) => {
        return agent.GroupMasters.details(groupMasterId).then(gm => this.setGroupMaster(gm));
    }
}
