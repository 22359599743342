import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridNoRecords, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { Skeleton } from '@progress/kendo-react-indicators';
import { useState } from 'react';
import { useHistory } from 'react-router';
import ReRate from '../../models/reRate';

interface Props {
    data: ReRate[]
}
const ReRateDataGrid = ({data}: Props) => {
   const history = useHistory();
   const handlerRowClick = (e: GridRowClickEvent) => {
      history.push(`/policy-headers/${e.dataItem.id}`);
   }
    
   const parsedData = data.map((p) => {
      const parsedRow = {...p, reRateDate: new Date(p.reRateDate) }
      return parsedRow;
   });
   
   const [sort, setSort] = useState<SortDescriptor[]>([]);

   return (
        <Grid 
            onRowClick={handlerRowClick}
            data={orderBy(parsedData, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}> 
            
            <GridColumn 
               field="policyNumber" 
               title="Policy"/>
            <GridColumn 
               field="groupName" 
               title="Group"
               width={300} 
               />
            <GridColumn 
               field="productType" 
               title="Type"
               width={80} />
            <GridColumn 
               field="insurerName" 
               title="Insurer"/>
            <GridColumn 
               field="memberCount" 
               title="Members"/>
            <GridColumn 
               field="totalPremium" 
               title="Premium" 
               filter="numeric"
               format="{0:c}"/>
            <GridColumn 
               field="reRateDate" 
               title="Re-Rate" 
               filter="date"
               format="{0:dd/MM/yyyy}"
               />
            <GridNoRecords >
               { (!data) 
                  ?  <div className='text-start'>
                           <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                     </div>
                  : 'There is no upcoming re-rate.' }
            </GridNoRecords>
            
        </Grid>
    )
}

export default ReRateDataGrid