import { orderBy, SortDescriptor } from '@progress/kendo-data-query'
import { Grid, GridCellProps, GridColumn, GridNoRecords, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import ConfirmationModal from '../../app/common/form/ConfirmModal'
import { Notice, NoticeStates } from '../../app/stores/noticeStore'
import { useStore } from '../../app/stores/store'
import { FormatDate, GetFileInfo } from '../../app/utils/utils'
import {InsurerPayment} from '../../models/claim'

interface ClaimPaymentHistoryDatagridProps {
    claimGuid: string
}
 
const ClaimPaymentHistoryDatagrid = ({claimGuid}: ClaimPaymentHistoryDatagridProps) => {
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const {claimsStore, userStore, noticeStore} = useStore();
    const {hasUserRole} = userStore;
    const { paymentHistory,
            getPaymentHistory, 
            setPaymentHistory, 
            downloadPaymentHistory,
            deletePaymentHistory,
            selectedClaims,
        } = claimsStore;

    const [selectedPaymentHistory, setSelectedPaymentHistory] = useState<InsurerPayment>();
    const [modalShow, setModalShow] = useState(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');
    
    const parsedData = paymentHistory?.map((p) => {
        const parsedRow = {...p,
                paymentDate: new Date(p.paymentDate!),
                startDate: p.startDate ? new Date(p.startDate) : undefined,
                endDate: p.endDate ? new Date(p.endDate) : undefined,
                period: ((p?.startDate) ? `${FormatDate(p.startDate)} - ` : '')  +  ((p?.endDate) ? `${FormatDate(p.endDate)}` : ''),
                paymentType: p.paymentType ? p.paymentType : '',
            }
        return parsedRow;
    });

    const handleDownload = (dataitem: InsurerPayment) => {
        if(!dataitem.filePath) return;

        const fileInfo =  GetFileInfo(dataitem.filePath);

        downloadPaymentHistory(claimGuid, dataitem.id!).then((blob: Blob) => {
            fileDownload(blob, fileInfo.basename);
        })
    }

    const confirmDelete = (payment: InsurerPayment) => {
        if(!hasUserRole('PortalAdmin')) return;
        setModalBodyMessage(`Please confirm you would like to delete the payment (${FormatDate(payment.paymentDate)}) for ${payment.paymentType} benefit. This action is permanent.`);
        setSelectedPaymentHistory(payment);
        setModalShow(true);
    }

    const handleConfirm = () => {
        if(selectedPaymentHistory) {
            deletePaymentHistory(claimGuid, selectedPaymentHistory.id!)
                .then((response) => {
                    const updatedHistory = paymentHistory?.filter(p => p.id !== selectedPaymentHistory.id);
                    setPaymentHistory(updatedHistory);
                    setModalShow(false);

                    const notice: Notice = {
                        noticeState: NoticeStates.success,
                        message: `Successfully deleted payment!`,
                        statusCode: 200,
                    };
        
                    noticeStore.setNotice(notice);
                    setSelectedPaymentHistory(undefined);
                    selectedClaims!.cumulativePayment -= selectedPaymentHistory.paymentAmount + selectedPaymentHistory.superAmount;
                    window.scrollTo(0, 0);
                }).catch((err: AxiosError)=> {

                     const notice: Notice = {
                        noticeState: NoticeStates.error,
                        message: `An error occured. Please try again.`,
                        statusCode: 500,
                    };
        
                    noticeStore.setNotice(notice);

                })
        }else{
            return false;
        }
        
    }

    const handleRowClick = (event: any) => {
        if(!event.dataItem.filePath) return false;
        handleDownload(event.dataItem);
    }

    const handleCancel = () => {
        setModalShow(false);
        setSelectedPaymentHistory(undefined);
    }

    useEffect(() => {
        getPaymentHistory(claimGuid)
        return () => {
            setPaymentHistory(undefined)
        }
    },[claimGuid, getPaymentHistory, setPaymentHistory])

    
    // TODO - find a way to combine props and children
    const paymentTypeHeaderCell = (props: any) => {
        return (
            <span className='k-link' onClick={props.onClick} onChange={props.selectionChange}   >
                {props.title}  
                <div className="float-end">{props.children}</div>
                <small className='d-block text-muted'>Pre-tax</small>
            </span>          
        )
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        const trProps: any = { style: {
            cursor: (props.dataItem.filePath) ? 'pointer' : 'default'
        }};

        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };

    return(
        <>
            <div className="border mt-5 bg-white rounded"> 
                <Row className="m-0">
                    <Col className="p-3 ">
                        <h3 className="mb-0">Payment history</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Grid 
                            data={orderBy(parsedData || [], sort)}
                            sortable={true}
                            sort={sort}
                            onRowClick={handleRowClick}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                            rowRender={rowRender}
                            >
                            <GridColumn field="paymentDate" title="Paid" filter="date" format="{0:dd/MM/yyyy}" width={120}/>
                            <GridColumn field="period" title="Period" width={115}/>
                            <GridColumn field="paymentType" title={'Payment Type'} width={179} />
                            <GridColumn field="paymentAmount" title="payment" filter="numeric" format="{0:c}"  headerCell={paymentTypeHeaderCell} />
                            <GridColumn field="superAmount" title="super" filter="numeric" format="{0:c}" />
                            <GridColumn field="" title="" width={85}
                            cell={ (props: GridCellProps) => (
                                <td>
                                    <>
                                        { hasUserRole('PortalAdmin') && 
                                        <i className='icon icon-trash me-4 clickable' 
                                            onClick={() => {
                                                confirmDelete(props.dataItem)
                                            }}
                                            />
                                        }
                                        
                                        {  props.dataItem.filePath  && 
                                        <i className='icon icon-download clickable' 
                                            onClick={() => {
                                            handleDownload(props.dataItem)
                                            }}
                                            />
                                        }
                                    </>
                                
                                </td>
                            )} />
                            
                            <GridNoRecords >
                                No payment history
                            </GridNoRecords>
                        </Grid>

                    </Col>
                </Row>
            </div>

            <ConfirmationModal 
                    show={modalShow} 
                    headerTitle='Delete payment history'
                    bodyMessage={modalBodyMessage}
                    onConfirm={handleConfirm}
                    onHide={handleCancel}/>
        </>
    
    )
}

export default observer(ClaimPaymentHistoryDatagrid)