import { Input } from "@progress/kendo-react-inputs";

export default function KendoInputs() {
return (
    <div className="row mb-5">
      <div className="col-24 mb-3">
          <h3>Input Box</h3>
          <Input />
      </div>
    </div>
)
    
}