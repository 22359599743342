import { cloneElement, useEffect, useRef } from 'react';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MemberSearchBar from '../../../app/common/search-bars/MemberSearchBar';
import { PolicyHeaderListItem } from '../../../models/subMenu';
import LoaderComponent from '../../../app/layout/LoaderComponent'
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Row, Col} from 'react-bootstrap';
import { ClaimNotification } from '../../../models/claim';
import { FormikInput, FormikMaskedInput } from '../../../app/common/form/FormikForm';
import { useHistory } from 'react-router-dom';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import { AxiosError } from 'axios';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { FormatDate } from '../../../app/utils/utils';

interface ClaimNotificationFormProps {
    policyHeader?: PolicyHeaderListItem
    manualEntry?: any
} 

const ClaimNotificationForm = ({policyHeader, manualEntry}: ClaimNotificationFormProps) => {
    const {searchMemberStore, claimsStore, noticeStore, preSelectStore} = useStore();
    const {selectedMember} = searchMemberStore;
    const {member} = preSelectStore;
    const history = useHistory();
    
    const formRef = useRef<FormikProps<ClaimNotification>>(null);
    
    const ValidationScheme = Yup.object().shape({
        memberGuid: Yup.string().required('Select a member'),
        phone: Yup.string().required(),
        email: Yup.string().email().required(),
        notes: Yup.string(),
    })
    
    useEffect(() => {
        formRef.current?.setFieldValue('memberGuid', member?.id || selectedMember?.memberInfo.id || '');
        formRef.current?.setFieldValue('employeeId', selectedMember?.memberInfo.employeeID || '');
        formRef.current?.setFieldValue('dob', selectedMember?.memberInfo.dob || 'No dob available in DB');
        formRef.current?.setFieldValue('surname', selectedMember?.memberInfo.surname || 'No surname available in DB');
        formRef.current?.setFieldValue('firstname', selectedMember?.memberInfo.firstname || 'No firstname available in DB');
        formRef.current?.setFieldValue('productType', selectedMember?.memberInfo.productType || '');
    },[selectedMember, formRef, member]);

    if(!policyHeader) return <LoaderComponent content='loading claim form...'/>
    
    const NewClaimInitialValues: ClaimNotification = {
        policyHeaderGuid: policyHeader.id, 
        memberGuid: member?.id || '',        
        dob: undefined,
        surname: '',
        firstname: '',
        productType: undefined,
        phone: '',
        email: '',
        notes: ''
    }    

    const handleSubmit = async (values: ClaimNotification, actions: any) => {
        values.productType = policyHeader.productType;
        await claimsStore.newClaimNotification(values).then((response) => {                      
            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: "Notification sent!",
                flashMessage: true,
                statusCode: 200,
            };
            
            noticeStore.setNotice(notice);
            history.push(`/policy-headers/${policyHeader.id}/claims`);

        }).catch( (error: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: "An error occured, please try again.",
                statusCode: 200
            };

            noticeStore.setNotice(notice);
        })
        actions.setSubmitting(false);
    }

    const handleCancel = () => {
        history.push(`/policy-headers/${policyHeader.id}/claims`)
    }

    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
          <div className="autocomplete-suggestion member-suggestion w-100 ps-3 pe-3 pt-1 pb-1"  >
            {li.props.children}  
            <>
                <small className='text-muted ms-2'>
                    {`(DOB: ${ FormatDate(itemProps.dataItem.memberInfo.dob)}) `}
                    {`(Joined: ${ FormatDate(itemProps.dataItem.memberInfo.joinedDate)}) ` }
                    {`[${itemProps.dataItem.memberInfo.policyNumber}] ` }
                </small>
            </>
          </div>
        );

        const datasetAttr = {
            ...li.props,
            'data-memberguid': itemProps.dataItem.memberInfo.id,
            'data-policyheader': itemProps.dataItem.memberInfo.policyHeaderGuid,
            'data-employeeid': itemProps.dataItem.memberInfo.employeeID
        }

        return cloneElement(li, datasetAttr, itemChildren);
    };

    const footer = () => { 
          return ( 
            <>  
                <div className='k-nodata'>        
                    <div className='text-start text-grey enter-manually' 
                        onClick={switchToManualEntry} 
                        onMouseDown={(e) => { 
                            // prevent propagation to trigger clickhandler
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        >
                        <strong>No matches.</strong> <span className='text-primary clickable' onClick={switchToManualEntry}>Enter member details manually?</span>  
                    </div>
                </div>
            </>
          )
    }


    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        return <></>
    };

    const switchToManualEntry = () => {
        manualEntry(true);
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={NewClaimInitialValues}
                validationSchema={ValidationScheme}
                onSubmit={handleSubmit}>
                { props => (
                <Form >    
                    <MemberSearchBar 
                        focusOnLoad
                        itemRender={itemRender}
                        noResultRender={listNoDataRender}
                        footer={footer()}
                        placeholder="Search member" 
                        selectOnEnter policyHeaderId={policyHeader.id}
                        keyword={member?.label}
                        />  

                    <FormikInput name="memberGuid" type='hidden' value={member?.id || selectedMember?.memberInfo.id || ''} />
                    <div className='claim-form border'>
                        <Row className='border-bottom m-0'>
                            <Col className='col-4 p-3 border-end'>
                                <FormikMaskedInput 
                                    name="phone" 
                                    label='Phone number*' 
                                    mask="(+99) 000 000 000"
                                    placeholder="(+61) 000 000 000" />
                            </Col>
                            <Col className='col-8 p-3 '>
                                <FormikInput name="email" label='Email*' />
                            </Col>
                        </Row>
       
                        <Row className='border-bottom m-0'>
                            <Col className='p-3'>
                            <label  className='mb-2 text-muted'>Notes</label>
                            <Field as="textarea" row='4' name="notes" className='form-control mb-3'/>
                            <ErrorMessage name="notes" render={ msg => 
                                    <small className="text-warning">{msg}</small>
                                } />   
                            </Col>
                        </Row>

                        <Row className='border-bottom m-0'>
                            <Col className='p-4 pe-3 text-end'>
                            <button className='btn btn-stale me-2' onClick={handleCancel}>Cancel</button>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                            </Col>
                        </Row>

                    </div>
                </Form>
                )}
            </Formik>
        </>
    )
}

export default observer(ClaimNotificationForm)