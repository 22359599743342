import React from 'react'
import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg'
import { Container } from 'react-bootstrap'

const ForgotPassword = () => {
    return (
        <Container fluid className='ids-login'>
            <div className="row d-flex align-items-stretch bg-white" style={{ height: "100vh" }} >
                <div className="col-4 d-flex align-self-center bg-light text-dark" style={{ height: "100%" }}>
                    <div className="align-self-center p-4">
                        <LogoSvG className='logo'/>
                        <h1 className='tag-line'>
                            <span className="text-muted">Imagine a world where </span>
                            financial worry is diminished...
                        </h1>
                        <div className="fixed-bottom p-4 az-nga-group">
                            PART OF THE <br/>
                            AZ NGA GROUP
                        </div>
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <h1 className='mb-3'>Forgot your password?</h1>
                            <p className='sub-text'>We'll send you an email to reset your password.</p>
                            <form className="mt-5 login-form">
                                <div className="mb-4">
                                    <label htmlFor="certe-email" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="certe-email" placeholder="email"/>
                                </div>
                                <button className='mt-4 k-button k-primary w-100'>RESET PASSWORD</button>
                            </form>
                            <a href="#login" className='mt-4 float-end'>Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ForgotPassword