import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ForbiddenError = () => {
    return (
        <Row className='bg-white text-center p-5'>
            <Col>
                <h1>403: Forbidden</h1>
                <p>You don't have permission to access this page.</p>
            </Col>
        </Row>
    )
}

export default ForbiddenError