import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import LoaderComponent from '../../layout/LoaderComponent';
import { useStore } from '../../stores/store';

export type ProtectedRouteProps = {
  role?: string[]
} & RouteProps;

const ProtectedRoute = ({role, ...routeProps}: ProtectedRouteProps) => {
  const {userStore } = useStore();
  const {user, getCurrentSession} = userStore;
  const location = useLocation();

  const checkUser = useCallback(
    async() => {
      // always check the user status on component load.
      await getCurrentSession();
    },
    [getCurrentSession],
  );
  
  useEffect(() => {
      checkUser();
  }, [location, checkUser])

  if(!user) return <LoaderComponent content='Loading application...'/>

  if(user) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={'/error/unauthorized'} />;
  }
};

export default observer(ProtectedRoute);