import { Button } from "@progress/kendo-react-buttons";
import axios, { AxiosResponse } from "axios";
import NoticeComponent from "../../app/common/notifications/NoticeComponent";
import { Notice, NoticeStates } from "../../app/stores/noticeStore";
import { useStore } from "../../app/stores/store";


function KendoNotice(){

    const {noticeStore} = useStore();
    const responseEndPoint =  process.env.REACT_APP_API_BASEURL + '/response';

    function handleNotFound() {
        axios.get(responseEndPoint + '/not-found');
    }
    
    function handleBadRequest() {
        axios.get(responseEndPoint + '/bad-request')
    }

    function handleValidationError() {
        const claim = {
            "ClaimID": "",
            "DataSource": "",
            "GroupID": 1,
            "PolicyHeaderID": 1,
            "MemberID": 1,
            "CoverType": "",
            "NotificationDate": "",
            "DisablementDate": "",
            "PaymentStartDate": "",
            "PaymentEndDate": "",
            "ClaimCloseDate": "",
            "DecisionType": "",
            "DecisionDate": "",
            "BenefitPeriod": "",
            "WaitingPeriod": "",
            "Benefit": 1,
            "CumulativePayment": 1,
            "Notes": "",
            "Username": ""
        }
        axios.post(responseEndPoint + '/create-claim', claim)
    }
    
    
    function handleServerError() {
        axios.get(responseEndPoint + '/server-error')
    }
    
    function handleUnauthorised() {
        axios.get(responseEndPoint + '/unauthorised')
    }

    function handleSuccess() {
        axios.get(responseEndPoint + '/success').then((data: AxiosResponse) => {

            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: data.data,
                statusCode: data.status
            }

            noticeStore.setNotice(notice);
        });
    }

    function handleCreated() {
        axios.get(responseEndPoint + '/created').then((data: AxiosResponse) => {

            const notice: Notice = {
                noticeState: NoticeStates.success,
                message: JSON.stringify(data.data),
                statusCode: data.status
            }

            noticeStore.setNotice(notice);
        });
    }

    return (
        <>  
            <div className='row'>
                <div className='col-24'>
                    <h1>Errors</h1>
                    <NoticeComponent />
                </div>
                <div className='col'>
                    <Button onClick={handleNotFound}>Not found 404</Button>
                    <Button onClick={handleBadRequest}>Bad Request 401</Button>
                    <Button onClick={handleValidationError}>Validation Error</Button>
                    <Button onClick={handleServerError}>Server Error 500</Button>
                    <Button onClick={handleUnauthorised}>Unauthorised 403</Button>
                </div>
            </div>
            <div className='row'>
                <div className='col-24'>
                    <h1>Success</h1>
                </div>
                <div className='col'>
                    <Button onClick={handleSuccess}>Success 200</Button>
                    <Button onClick={handleCreated}>Created 201</Button>
                
                </div>
            </div>
        </>
    )
}

export default KendoNotice;