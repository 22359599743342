import { useState } from 'react'
import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { ClaimSearchResult, ClaimStatus } from '../../models/claimSearch';
import { Skeleton } from '@progress/kendo-react-indicators';

interface ClaimsSearchDatagridProps {
    results?: ClaimSearchResult[],
}

const ClaimsSearchDatagrid = ({results}: ClaimsSearchDatagridProps) => {
    const history = useHistory();
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    function handleRowClick(event: any) {
        history.push(`/claims/${event.dataItem.id}`);
    }

    const parsedData = results?.map((c) => {
        const parsedRow = {...c, 
                joinedDate: new Date(c.notificationDate),
                claimStatus: ClaimStatus[c.claimStatus as keyof typeof ClaimStatus]
            }
        return parsedRow;
    });

    // TODO: Implement columnInterface
    return (
        <>  
            
            <Grid onRowClick={handleRowClick} 
                data={orderBy(parsedData || [], sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}>
                <Column field="employeeID" title="Employee ID" width={130} />
                <Column field="memberName" title="Name" width={300} />
                <Column field="coverType" title="Type"/>
                <Column field="notificationDate" title="Notification" filter="date" format="{0:dd/MM/yyyy}"/>
                <Column field="benefitAmount" title="Benefit" filter="numeric" format="{0:c}" />
                <Column field="paymentAmount" title="Payment" filter="numeric" format="{0:c}" />
                <Column field="claimStatus" title="Status"  />
                <GridNoRecords >
                    { (!results) 
                        ?   <div className='text-start'>
                                <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                                
                            </div>
                        : 'No results found.' }
                </GridNoRecords>
            </Grid>
        </>
    );
}

export default observer(ClaimsSearchDatagrid);
