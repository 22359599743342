import { Skeleton } from '@progress/kendo-react-indicators'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Col, NavDropdown, Row } from 'react-bootstrap'
import { useStore } from '../../app/stores/store'
import { FormatDate } from '../../app/utils/utils'
import { PolicyHeaderDetails, PolicyHeaderStartDate, PolicyHeaderStatus } from '../../models/policyHeader'

interface PolicyHeaderBarProps {
    policyHeader?: PolicyHeaderDetails
}

const PolicyHeaderBar = ({policyHeader}: PolicyHeaderBarProps) => {

    const {policyHeaderStore} = useStore();
    const {setPolicyHeader, getPolicyHeaderDetails, setIsLoading} = policyHeaderStore;
    const [isCurrent, setIsCurrent] = useState(true);

    const handleSelectStartDate = (policyHeaderStartDate: PolicyHeaderStartDate) => {
        setIsLoading(true);
        getPolicyHeaderDetails(policyHeaderStartDate.policyHeaderGuid, policyHeaderStartDate.startDate).then( details => {
            setPolicyHeader(details);
            setIsCurrent((policyHeaderStartDate.status === PolicyHeaderStatus.CURRENT)); 
            setIsLoading(false);
        })
    }

    return (
        <Row className='bg-white border'>
            <Col className='col-2 p-3 border-end'>
                <small className='text-muted'>Number</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div className='mt-2'>{policyHeader?.policyHeaderInfo.policyNumber}</div>
            </Col>
            <Col className='col-4 p-3 border-end'>
                <small className='text-muted'>Group</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div className='mt-2'>{policyHeader?.policyHeaderInfo.groupName}</div>
            </Col>
            <Col className='col-2 p-3 border-end'>
                <small className='text-muted'>Type</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div className='mt-2'>{policyHeader?.policyHeaderInfo.productType}</div>
            </Col>
            <Col className='col-2 p-3 border-end'>
                <small className='text-muted'>Insurer</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }
                <div className='mt-2'>{policyHeader?.policyHeaderInfo.insurer}</div>
            </Col>
            <Col className={classNames('col-2 p-3 group-headers-review-dates d-grid', {
                    ' bg-light-yellow ': isCurrent,
                    ' bg-light-gray text-white ': !isCurrent,
                })} >
                <small className='text-muted'>Review date</small>
                {!policyHeader &&
                    <Skeleton shape={"text"} style={{ width: "80%" }} />
                }

                { policyHeader &&
                    <NavDropdown id="group-headers-review-dates-option" title={FormatDate(policyHeader.policyHeaderInfo.reviewDate)}>
                    { policyHeader.policyHeaderInfo.otherStartDates.map( (policyHeaderStartDate, index) => (
                        <NavDropdown.Item key={index} onClick={() => handleSelectStartDate(policyHeaderStartDate)} >
                            {FormatDate(policyHeaderStartDate.startDate)}
                        </NavDropdown.Item>
                        ))
                    }
                    </NavDropdown>
                }
            </Col>
        </Row>
    )
}

export default PolicyHeaderBar