import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import InView from 'react-intersection-observer'
import { useHistory, useParams } from 'react-router-dom'
import ConfirmationModal from '../../app/common/form/ConfirmModal'
import FloatingBar from '../../app/layout/FloatingBar'
import { useStore } from '../../app/stores/store'
import { IdTypes, SubLinks } from '../../app/stores/subMenuStore'
import { PolicyHeaderStatus } from '../../models/policyHeader'
import PolicyCampaignDatagrid from './PolicyCampaignDatagrid'

interface CampaignUrlParams {
    policyHeaderGuid: string
}

const PolicyCampaignComponent = () => {
    const {campaignStore, subMenuStore} = useStore();
    const history = useHistory();
    const visibilityRef = useRef<HTMLDivElement>(null);
    const [showWloatingBar, setshowWloatingBar] = useState<boolean>(false);
    const {policyHeaderGuid} = useParams<CampaignUrlParams>();
    const {members, getQualifiedMembers, getSelectedMembers, sendCampaignEmail, resetCampaign} = campaignStore;
    const [modalShow, setModalShow] = useState(false);
    const [hideConfirmBtn, setHideConfirmBtn] = useState<boolean>(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');
    const [modalHeaderTitle, setModalHeaderTitle] = useState('Confirm send');

    useEffect(() => {
        // show submenu 
        subMenuStore.initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        subMenuStore.setActivelink(SubLinks.members);
        getQualifiedMembers(policyHeaderGuid);

        return () => {
            resetCampaign()
        }
    }, [policyHeaderGuid, subMenuStore, getQualifiedMembers, resetCampaign])


    if(subMenuStore.policyHeader?.status.toLocaleLowerCase() === PolicyHeaderStatus.PREVIOUS ) {
        subMenuStore.setDisplay(false);
        history.push('/error/not-found')
    }

    const floatingBarVisibility = (inView: boolean)  => {
        setshowWloatingBar(!inView)
    }

    const confirmation = () => {
        setModalBodyMessage(`The underwriting notifications will be sent to ${getSelectedMembers().length} ${ pluralize('member', getSelectedMembers().length)} from ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.insurer}.`)
        setModalShow(true);
    }

    const handleConfirm = () => {
        const payload = getSelectedMembers().map((m) => m[0])
        sendCampaignEmail(policyHeaderGuid, payload).then((data: any) => {
            setModalBodyMessage(`${data}`) // API response didn't follow the reponse format 
            setModalHeaderTitle('Success');
            setHideConfirmBtn(true);
        }).catch(e => {
            setModalHeaderTitle('Error');
            setModalBodyMessage('An error occured. Please try again.');
            setHideConfirmBtn(true);
        })
    }

    const handleCancel = () => {
        setModalShow(false);
        setTimeout(resetModalBox, 800)
    }

    const resetModalBox = () => {
        setModalHeaderTitle('Confirm send?');
        setModalBodyMessage(`The underwriting notifications will be sent to ${getSelectedMembers().length} ${ pluralize('member', getSelectedMembers().length)} from ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.insurer}.`)
        setHideConfirmBtn(false);
    }

    return (
        <>
            <Row>
                <Col className='col-8'>
                    <h1 className='header-title'>Member Statements</h1>
                    <p>Below are statements for each member for the current review date. These statements include all members and notify those members who are eligible for additional benefits with medical underwriting. Please select the members that you would like to send statements to via email, then click the ‘send’ button at the bottom of the page.</p>
                    <p>Each email will outline their current benefit and notify the member if they can increase cover.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PolicyCampaignDatagrid members={members} />
                </Col>
            </Row>

            { members && members.length > 0 &&
            <>
                <InView onChange={floatingBarVisibility}>
                <Row className='mt-4' ref={visibilityRef}>
                    <Col>
                        <Button 
                            onClick={confirmation}
                            disabled={getSelectedMembers().length  ===  0}
                            className='me-2 btn-outline-black-border-yellow-bg'><i className='icon icon-envelop me-2' />Send</Button>
                        {getSelectedMembers().length  ===  0 && <small>(select members from the list above)</small> }
                        {getSelectedMembers().length  !==  0 && <small> {getSelectedMembers().length} selected members </small> }
                    </Col>
                    <Col className='text-end'>
                        {members?.length || 0} results
                    </Col>
                </Row>
                </InView>
                <Row>
                    <Col>
                        <FloatingBar 
                            position='bottom' 
                            visible={showWloatingBar}>
                            <Row className='mt-3 mb-3'>
                                <Col>
                                    <Button 
                                        onClick={confirmation}
                                        disabled={getSelectedMembers().length  ===  0}
                                        className='me-2 btn-outline-black-border-yellow-bg'><i className='icon icon-envelop me-2' />Send</Button>
                                    {getSelectedMembers().length  ===  0 &&  <small>(select members from the list above)</small> }
                                    {getSelectedMembers().length  !==  0 &&  <small> {getSelectedMembers().length} selected { pluralize('member', getSelectedMembers().length)}</small> }
                                </Col>
                                <Col className='text-end'>
                                    {members?.length || 0} results
                                </Col>
                            </Row>                     
                        </FloatingBar>
                    </Col>
                </Row>
            </>
            }

            <ConfirmationModal 
                show={modalShow} 
                headerTitle={modalHeaderTitle}
                bodyMessage={modalBodyMessage}
                onConfirm={handleConfirm}
                confirmBtnVariant='primary'
                confirmBtnLabel='Send'
                confirmBtnOnClickLabel='Processing'
                hideConfirmBtn={hideConfirmBtn}
                disableOnConfirmBtn={true}
                onHide={handleCancel}/>
        </>
    )
}

export default observer(PolicyCampaignComponent)