import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite';

import { useParams } from 'react-router';
import { useStore } from '../app/stores/store';
import { Button } from 'react-bootstrap';
import DumpObject from '../app/common/debugger/DumpObject';

interface UrlParams {
    id?: string
}

const StateTest = () => {
    const {id} = useParams<UrlParams>();
    const {stateTestStore} = useStore();
    const {testList, uriParam, details, setUriParam, setDetails} = stateTestStore;
    
    
    useEffect(() => {
        setUriParam(id);
        setDetails(id)
    }, [setUriParam, setDetails,id])

    const clickHandler = () => {
        console.log(details)
    }

    const clickHandler2 = (id?: string) => {
        setDetails(`${parseInt(id || '') + 7}`);
    }


    return ( 
        <div className='text-center'>
            <h1 className=' p-5'>URI Param: {uriParam}</h1>
            <p>URI Param: {details}</p>
            <Button onClick={clickHandler}> What's the store value?</Button><br/>
            <Button onClick={() => clickHandler2(id)}> setNewValue</Button>
            <div>{details}</div>
            <DumpObject obj={testList} />

        </div>
    )
}

export default observer(StateTest)