
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React  from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NoticeComponent from "../../app/common/notifications/NoticeComponent";
import { useStore } from "../../app/stores/store";

function Unauthorized(){
    const history = useHistory();
    const {userStore} = useStore();
    const {user} = userStore;

    const handleClick = () => {
        (!user) 
            ? history.push('/login')
            : history.push('/dashboard')
    }

    return (
        <> 
            <Container>
                <Row>
                    <Col>
                        <NoticeComponent />
                        <h1>Unauthorized Access</h1>
                        <p>You don't have permission to acces this page.</p>
                        <Button onClick={handleClick} icon="home" primary={true} look="flat">  
                        { (!user) ? 'login' : 'Go back to dashboard' }
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default observer(Unauthorized)