import React, { useEffect, useState } from 'react'
import { useStore } from "../../app/stores/store"
import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap"
import MemberSearchBar from '../../app/common/search-bars/MemberSearchBar'
import MemberTopLevelSearchDataGrid from '../../app/common/search-bars/MemberTopLevelSearchDataGrid'
import InView from 'react-intersection-observer'
import pluralize from 'pluralize'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { SortDescriptor } from '@progress/kendo-data-query'
import { DataGridSorting, MemberDataGridColumn } from '../../models/memberSearch'
import { Helmet } from "react-helmet"

const MemberSearchComponent = () => {
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const {searchMemberStore, subMenuStore} = useStore();
    const {currentPage, loadMore, pagination, sortSearch, dataGrid} = searchMemberStore; 
    const {resetPolicyHeader} = subMenuStore;
    const handleLoadMore = (inView: boolean) => {
        if(pagination && currentPage < pagination.totalPages){
            if(inView ){
                loadMore();
            }
        }
    }

    const sortHandler = (e: GridSortChangeEvent) => {
        const fieldMap = {
            'memberInfo.policyNumber': 'PolicyNumber',
            'memberInfo.employeeID': 'EmployeeId',
            'memberInfo.name': 'MemberName',
            'memberInfo.dob': 'DOB',
            'memberInfo.joinedDate': 'DateJoined',
            'memberInfo.productType': 'ProductType',
            'groupMasterInfo.name': 'GroupName'
        }

        if(e.sort.length > 0) {
            const sortField = e.sort[0].field!;
            const sortDir = (e.sort[0].dir! === 'asc') ? DataGridSorting.Ascending : DataGridSorting.Descending;
            const fieldName = fieldMap[sortField  as keyof typeof fieldMap];
            sortSearch(MemberDataGridColumn[fieldName as keyof typeof MemberDataGridColumn], sortDir)
        }else{
            sortSearch();
        }
        setSort(e.sort);
    }

    useEffect(() => {
        resetPolicyHeader();
    }, [resetPolicyHeader])

    return (
        <>
            <Helmet title="All members" />
            <Row className='mb-4'>
                <Col>
                    <h1 className='header-title'>All members</h1>
                </Col>
            </Row>
    
            <MemberSearchBar 
                focusOnLoad
                searchOnInit 
                withDataGrid
                disableSuggestion
                placeholder='Search members' 
                suggestedField='memberInfo.name'/>
                
            <Row className='mt-4'>
                <Col>
                    <MemberTopLevelSearchDataGrid 
                        sort={sort}
                        sortHandler={sortHandler}
                        results={dataGrid} 
                        />
                </Col>
            </Row>

            {dataGrid &&                        
                <Row className='mt-4'>
                    <Col className='text-end'>
                    {pagination?.totalResults} {pluralize('Result', pagination?.totalResults)}
                    </Col>
                </Row>
            }
                
            {pagination &&  dataGrid && pagination.pageSize <= dataGrid.length &&
            <Row>
                <Col className='text-center mt-4'>
                    
                    <InView as="div" onChange={handleLoadMore}>
                        { currentPage < pagination.totalPages  && 
                         <span className='text-muted'>loading...</span>
                        }
                    </InView>
                </Col>
            </Row>
            }
                      
        </>
    )
}

export default observer(MemberSearchComponent)