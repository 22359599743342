import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as HeartSVG } from '../../assets/img/svg/heart.svg'
import { ReactComponent as ShieldSVG } from '../../assets/img/svg/shield.svg'
import { ReactComponent as InwardArrowSGV } from '../../assets/img/svg/inward-arrow.svg'
import { Skeleton } from '@progress/kendo-react-indicators'

export interface CardInfo {
    key: string,
    value: string | number
}

export interface CardSummaryProps {
    icon?: 'heart' | 'shield' | 'inward-arrow',
    headerTitle?: string,
    cardInfoSummary?: CardInfo, 
    cardInfoBreakdown?: CardInfo[],
    buttonLabel?: string,
    buttonIcon?: string,
    buttonLink?: string,
    buttonOnClick?: () => void
}

const CardSummary = ( props: CardSummaryProps) => {
    return (
        <Container className='card-summary text-center mb-sm-4 p-0'>
            <Row className='m-0'>
                <Col  className='card-summary-row card-summary-header p-4'>
                
                    <div className='card-header-icon p-4'>
                        {!props.icon && <Skeleton shape={"circle"} style={{ width: "150px", height: "150px", margin: "0 auto" }} />}

                        { props && props.icon === 'heart' && 
                            <HeartSVG className='img-fluid' />
                        }

                        { props && props.icon === 'shield' && 
                            <ShieldSVG className='img-fluid' />
                        }

                        { props && props.icon === 'inward-arrow' && 
                            <InwardArrowSGV className='img-fluid' />
                        }
                    </div>

                    <h2 className='card-header-title'>
                        {!props.headerTitle && <Skeleton shape={"text"} style={{ width: "50%", margin: "0 auto" }} />}
                        { props.headerTitle}
                    </h2>
                </Col>
            </Row>
            <Row className='card-summary-row m-0'>
                <Col className='p-3'>
                    { !props.cardInfoSummary && 
                        <Skeleton shape={"text"} style={{ width: "50%", margin: "0 auto", padding: '10px', display:'inline-block' }} />
                    }

                    { props.cardInfoSummary &&
                        <>
                            <small className='k-label text-muted'>{ props.cardInfoSummary.key}</small>
                            <div className='card-summary-value'>{props.cardInfoSummary.value}</div>
                        </>
                    }
                </Col>
            </Row>
            <Row className="card-summary-row m-0">
                { !props.cardInfoBreakdown && 
                    <Col className='card-breakdown-item p-3'>
                        <Skeleton shape={"text"} style={{ width: "40%", margin: "0 auto", padding: '10px', display:'inline-block' }} /> &nbsp;
                        <Skeleton shape={"text"} style={{ width: "40%", margin: "0 auto", padding: '10px', display:'inline-block' }} />
                    </Col> }

                { props.cardInfoBreakdown && props.cardInfoBreakdown.map((info: CardInfo, index) => {
                    return (
                        <Col className='card-breakdown-item p-3'  key={index}>
                            <small className='k-label text-muted'>{info.key}</small>
                            <div className='card-breakdown-value'>{info.value}</div>
                        </Col>
                    )
                }) }
            </Row>
           
            <Row className='card-summary-footer'>
                <Col className='d-grid'>
                { !props.buttonLabel && 
                    <div className='btn btn-primary pt-4 pb-4'>
                      <Skeleton shape={"text"} style={{ width: "80%", margin: "0 auto" }} />
                    </div> 
                }
                
                { props.buttonLink &&
                    <Link to={props.buttonLink} className='btn btn-primary pt-4  pb-4'>
                        { props.buttonIcon && <i className={`${props.buttonIcon}`}></i>} {props.buttonLabel}
                    </Link>
                }

                { props.buttonOnClick &&
                    <Button className='btn btn-primary pt-4 pb-4' onClick={props.buttonOnClick}>
                        { props.buttonIcon && <i className={`${props.buttonIcon}`}></i>} {props.buttonLabel}
                    </Button>
                }

                { props.buttonOnClick === undefined && props.headerTitle === 'Claims' &&
                    <Button className='btn btn-primary pt-4 pb-4' disabled>
                        { props.buttonIcon && <i className={`${props.buttonIcon}`}></i>} {props.buttonLabel} 
                    </Button>
                }
                </Col>
            </Row>
        </Container>
    )
}

export default CardSummary