import jwtDecode from "jwt-decode";
import { makeAutoObservable, reaction } from "mobx";
import { User } from "oidc-client";
import { ServerError } from "../../models/serverError";

interface UserClaims {
    groupId: number,
    role: string
}

export default class CommonStore {
    user?: User | null;
    userClaims?: UserClaims;
    isAuthenticated: boolean = false;
    error: ServerError | null = null;
    appLoaded: boolean = false;
    
    constructor(){
        makeAutoObservable(this);

        reaction( 
            () => this.user,
            user => {
                if (user) {
                    this.setUserClaims(jwtDecode(user?.access_token));
                }else{
                    
                    this.userClaims = undefined;
                }
            }
        )
    }

    setUser = (user: User | null) => {
        this.user = user;
    }

    setAuthenticated = () => {
        this.isAuthenticated = this.user ? true : false;
    }
    
    setUserClaims = (userClaims: UserClaims) => {
        this.userClaims = userClaims;
    }

    setServerError = (error: ServerError) => {
        this.error = error;
    }
}
