import { makeAutoObservable, observable, reaction } from "mobx";
import { Pagination } from "../../models/pagination";
import agent from "../api/agent";
import { ClaimSearchResponse, ClaimSearchResult } from "../../models/claimSearch";

export default class SearchClaimStore {
    /** Filters */
    keyword?: string = '';
    currentPage: number = 1;
    policyHeader!: string;

    /** Response */
    response?: ClaimSearchResponse;
    results?: ClaimSearchResult[];
    pagination?: Pagination
    
    constructor(){
        makeAutoObservable(this, {
            keyword: observable,
            response: observable,
            results: observable,
            pagination: observable
        });

        reaction( 
            () => this.response,
            response => {

                if(response){
                    const parsedData = response?.results?.map((p) => {
                       
                        const parsedRow = {...p, 
                            notificationDate: new Date(`${p.notificationDate}`)
                            }
                        return parsedRow;
                    });


                    if(response && response?.pagination?.currentPage > 1){
                        this.appendResults(parsedData);
                    }else{
                        this.setResults(parsedData);
                    }

                    this.setPagination(response?.pagination);
                }
            }
        )
    }

    private setResponse = (response?: ClaimSearchResponse) => {
        this.response = response
    }

    private setResults = (results?: ClaimSearchResult[]) => {
        this.results = results;
    }

    private appendResults = (results?: ClaimSearchResult[]) => {
        this.results = [...this.results!, ...results!];
    }

    private setPagination = (pagination?: Pagination) => {
        this.pagination = pagination;
    }

    setKeyword = (keyword: string) => {
        this.keyword = keyword;
    }

    setCurrentPage = (pageNumber: number) => {
        this.currentPage = pageNumber;
    } 

    setPolicyHeader = (policyHeader: string) => {
        this.policyHeader = policyHeader
    }

    // TODO: implement named parameters
    search = async (keyword?: string, currentPage?: number ) => {
        this.keyword = keyword || '';
        this.currentPage = currentPage || 1;

        return await agent.Search.claims(this.policyHeader, this.keyword, this.currentPage)
                        .then((response)=> { 
                            this.setResponse(response);
                        });
    } 

    loadMore = async () => {
        this.currentPage += 1;
        return await agent.Search.claims(this.policyHeader, this.keyword, this.currentPage)
            .then((response)=> { 
                this.setResponse(response);
            }); 
    }

    initSearch = (keyword?: string) => {
        // reset store before init
        this.resetSearch();

        // auto search on invoke
        this.search(keyword, 1);
    }

    resetSearch = () => {
        this.setCurrentPage(1);
        this.setKeyword('');
        this.setResponse(undefined);
        this.setPagination(undefined);
        this.setResults(undefined);
    }
}