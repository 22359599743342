import { useEffect, useMemo, useState } from 'react'
// import { useStore } from "../../app/stores/store"
import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap"
import ProtectedComponent from '../../app/common/routes/ProtectedComponent'
import ActionButton from '../../app/common/form/ActionButton'
import ConfirmationModal from '../../app/common/form/ConfirmModal'
import { useStore } from '../../app/stores/store'
import { FormatDate, UserHasPermission } from '../../app/utils/utils'
import { useHistory } from 'react-router-dom'
import fileDownload from 'js-file-download'
import mime from 'mime-types'
import { AxiosError } from 'axios'
import { Notice, NoticeStates } from '../../app/stores/noticeStore'
import { PolicyFile } from '../../models/files'

interface Props {
    policyHeaderGuid: string,
    memberId: string,
}

const MemberUnderwritingDecisions = ({memberId, policyHeaderGuid}: Props) => {
    // components should interact via store
    const {noticeStore, memberStore, userStore, preSelectStore, policyHeaderStore, subMenuStore} = useStore();
    const {userClaims} = userStore;
    const {setMember} = preSelectStore;
    const history = useHistory();
    const {getMemberUnderwritingDecisions, member} = memberStore;
    const [selectedUd, setSelectedUd] = useState<PolicyFile>();
    const [udList, setUdList] = useState<PolicyFile[]>([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');
    const allowedRoles = useMemo(() => ['PortalAdmin'], []);
    const {downloadFile, deleteFile} = policyHeaderStore;
    const {policyHeader} = subMenuStore;

    useEffect(() => {
        if(UserHasPermission(allowedRoles, userClaims?.role)) {
            getMemberUnderwritingDecisions(memberId).then(udList => {
                setUdList(udList)
            })
        }
    }, [setUdList, getMemberUnderwritingDecisions, memberId, userClaims,allowedRoles])

    const confirmDelete = (ud: PolicyFile) => {
        if(!ud.deletable) return;
        
        setSelectedUd(ud);
        setModalBodyMessage(`Please confirm you like to delete ${ud.title}?`);
        setModalShow(true);
    }

    const handleConfirm = () => {
        deleteFile(policyHeaderGuid, selectedUd?.id!).then(() => {
            // remove from list
            setUdList(udList.filter(ud => ud.id !== selectedUd?.id!));
            setModalShow(false);

        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to download file. Please try again.`,
                statusCode: 500,
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
        })

    }

    const handleDownloadFile = (ud: PolicyFile) => {
       
        downloadFile(policyHeaderGuid, ud.id!).then((blob) => {
            fileDownload(blob, `${ud.title} ${policyHeader?.policyNumber} ${member?.memberInfo.employeeId} ${ud.createdDate}.${mime.extension(blob.type)}`);
        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to download file. Please try again.`,
                statusCode: 500,
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
        })

    } 

    const uploadFile = () => {
        setMember({
            id: member?.memberInfo.id,
            label: member?.memberInfo.name
        });
        history.push(`/policy-headers/${policyHeaderGuid}/upload/underwriting decision letter`);
    }

    const handleCancel = () => {
        setModalShow(false);
        setSelectedUd(undefined);
    }
    
    return (
        <ProtectedComponent allowedRoles={allowedRoles}>
            <div className='member-underwriting-decision-list'>
                <Row className='border rounded-top bg-white mt-5'>
                    <Col className='col-lg-11 col-md-10 p-4 align-middle'>
                        <h2 className='m-0'>Underwriting decisions</h2>
                    </Col>
                    <Col className='col-lg-1 col-md-2 p-0 border-start'>
                        <ActionButton clickHandler={uploadFile} icon="icon-upload" />
                    </Col>
                </Row>
                { udList.map( (ud, index) => (
                            <Row className='border border-top-0 bg-white  text-center clickable' key={index} onClick={() => handleDownloadFile(ud)}>
                                <Col className='p-4 col-2'>{FormatDate(ud.createdDate)}</Col>
                                <Col className='p-4 col-8 text-start'>{ud.title}</Col>
                                <Col className='p-4 col-2 text-end'>
                                    { ud.deletable &&
                                    <span className='list-action-link clickable me-3' onClick={(e: any) => {
                                        e.stopPropagation();
                                        confirmDelete(ud)
                                    }}  >
                                        <i className='icon icon-trash ' />
                                    </span>
                                    }
                                    <span className='list-action-link clickable' onClick={(e: any) => {
                                        e.stopPropagation();
                                        handleDownloadFile(ud)
                                    }}  >
                                        <i className='icon icon-download list-action-link ' />
                                    </span>
                                </Col>
                                
                            </Row>
                        ) 
                    )
                }
                { udList.length === 0 && 
                    <Row className='border border-top-0 bg-white text-center'>
                        <Col className='p-4'>No underwriting decisions.</Col>
                    </Row>
                }
            </div>

            <ConfirmationModal 
                    show={modalShow} 
                    headerTitle='Delete underwriting decision'
                    bodyMessage={modalBodyMessage}
                    onConfirm={handleConfirm}
                    onHide={handleCancel}/>
        </ProtectedComponent>
    )
}

export default observer(MemberUnderwritingDecisions)