import React from "react";
import { Col, Row } from "react-bootstrap";

export default function PrivacyPolicyComponent(){
    return (
        <Row>
            <Col>
                <h1 className='mb-3'>Privacy Policy Component</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At nisi ut non ad, distinctio excepturi cupiditate quaerat quis suscipit hic esse aspernatur quia doloremque ducimus, recusandae quas consequatur, aliquid libero.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At nisi ut non ad, distinctio excepturi cupiditate quaerat quis suscipit hic esse aspernatur quia doloremque ducimus, recusandae quas consequatur, aliquid libero.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At nisi ut non ad, distinctio excepturi cupiditate quaerat quis suscipit hic esse aspernatur quia doloremque ducimus, recusandae quas consequatur, aliquid libero.</p>
            </Col>
        </Row>
    )
}