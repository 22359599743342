import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useParams } from 'react-router';
import { useStore } from '../../app/stores/store';
import { NoteTypes } from '../../models/note';
import NotesListComponent from '../notes/NotesListComponent';
import PolicyHeaderListItem from '../policy-header/PolicyHeaderListItem';
import { AxiosError } from "axios";
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@progress/kendo-react-indicators';
import agent from '../../app/api/agent';
import fileDownload from 'js-file-download'
import { Helmet } from "react-helmet"

interface GroupMasterUrlParams {
    groupMasterGuid: string
}

const GroupMasterDetailsComponent = () => {
    const {userStore, groupMasterStore, subMenuStore} = useStore();
    const {userClaims, groupMasterId} = userStore;
    const {resetPolicyHeader} = subMenuStore;
    const history = useHistory();
    const {groupMaster, getGroupMasterDetails, setGroupMaster} = groupMasterStore
    const {groupMasterGuid} = useParams<GroupMasterUrlParams>();

    // Layout swiitches to full width 
    const isPortalAdmin = userClaims?.role.includes('PortalAdmin');

    const downloadMembersReport = () => {
        agent.DownloadReports.groupMembersReport(groupMasterGuid).then((blob: Blob) => {
            fileDownload(blob, `${groupMaster?.groupMasterInfo.name}-members-report.xlsx`);
        });
    }

    const downloadClaimsReport = () => {
        agent.DownloadReports.groupClaimsReport(groupMasterGuid).then((blob: Blob) => {
            fileDownload(blob, `${groupMaster?.groupMasterInfo.name}-claims-report.xlsx`);
        });
    }

    useEffect(() => {
        resetPolicyHeader();
        getGroupMasterDetails(groupMasterGuid).catch((err: AxiosError) => {
            if(err.response?.status === 400) history.push("/error/not-found");
            if(err.response?.status === 403) history.push("/error/forbidden");
        })

        return () => {
            setGroupMaster(undefined);
        }
    }, [setGroupMaster, getGroupMasterDetails, groupMasterGuid, history, resetPolicyHeader])

    return (
        <>
            <Helmet title={`Group - ${groupMaster?.groupMasterInfo.name} `}/>
            <Row>
                <Col >
                    <h1 className='header-title'>
                        {!groupMaster &&
                            <Skeleton shape={"text"} style={{ width: "60%", height: "90px" }} />
                        }
                        {groupMaster?.groupMasterInfo.name}
                    </h1>
                    <small className='text-muted'>
                        {!groupMaster?.groupMasterInfo.advisor &&
                            <Skeleton shape={"text"} style={{ width: "20%" }} />
                        }
                        { groupMaster?.groupMasterInfo.advisor && `Advisor: ${groupMaster?.groupMasterInfo.advisor}`}
                        </small>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    {groupMasterId === '0' && <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={downloadClaimsReport}> <i className='icon icon-download' /> Claims report</Button>}
                    {groupMasterId === '0' && <Button className='me-2 btn-outline-black-border-yellow-bg' onClick={downloadMembersReport}> <i className='icon icon-download' /> Members report</Button>}
                </Col>
            </Row>
            <Row className='mt-4 gx-5'>
                <Col className={classNames({
                                        'col-12': !isPortalAdmin,
                                        'col-8': isPortalAdmin
                                    })}>
                    
                    { groupMaster &&  groupMaster?.activePolicyHeaders?.length > 0 &&
                        <Row className='mt-3'>
                            <Col>
                                <h2>In force</h2>
                            </Col>
                        </Row>
                    }
                    { !groupMaster && <PolicyHeaderListItem /> }
                    { groupMaster?.activePolicyHeaders.map(p => <PolicyHeaderListItem policyHeader={p} key={p.id} />) }
                
                    { groupMaster && groupMaster?.inactivePolicyHeaders?.length > 0 && 
                        <Row className='mt-5'>
                            <Col>
                                <h2 className='text-muted'>Previous</h2>
                            </Col>
                        </Row>
                    }
                    { !groupMaster && <PolicyHeaderListItem /> }
                    { groupMaster?.inactivePolicyHeaders.map(p => <PolicyHeaderListItem policyHeader={p} key={p.id}  />) }

                </Col>
                {isPortalAdmin &&
                <Col className={'col-4'}>
                    
                    <NotesListComponent 
                        listTitle={'Group notes'} 
                        notesType={NoteTypes.GroupMaster} 
                        notesOf={groupMasterGuid} />
                    
                </Col>
                }
            </Row>
        </>
    )
}

export default observer(GroupMasterDetailsComponent)