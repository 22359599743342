import { createContext, useContext } from "react";
import ClaimsStore from "./claimsStore";
import PolicyStore from "./policyStore";
import NoticeStore from "./noticeStore";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import MemberStore from "./memberStore";
import SubMenuStore from "./subMenuStore";
import StateTestStore from "./stateTestStore";
import NotesStore from "./notesStore";
import GroupMasterStore from "./groupMasterStore";
import policyHeaderStore from "./policyHeaderStore";
import SearchMemberStore from "./searchMemberStore";
import SearchPolicyStore from "./searchPolicyStore";
import SearchClaimStore from "./searchClaimStore";
import FileStore from "./fileStore";
import PreSelectStore from "./PreSelectStore";
import CampaignStore from "./campaignStore";


interface Store {
    subMenuStore: SubMenuStore,
    userStore: UserStore
    claimsStore: ClaimsStore
    policyStore: PolicyStore
    noticeStore: NoticeStore
    commonStore: CommonStore
    memberStore: MemberStore,
    notesStore: NotesStore,
    stateTestStore: StateTestStore,
    groupMasterStore: GroupMasterStore,
    policyHeaderStore: policyHeaderStore,
    searchMemberStore: SearchMemberStore,
    searchPolicyStore: SearchPolicyStore,
    searchClaimStore: SearchClaimStore,
    fileStore: FileStore,
    preSelectStore: PreSelectStore,
    campaignStore: CampaignStore
}

export const store: Store = {
    subMenuStore: new SubMenuStore(),
    userStore: new UserStore(),
    claimsStore: new ClaimsStore(),
    policyStore: new PolicyStore(),
    noticeStore: new NoticeStore(),
    commonStore: new CommonStore(),
    memberStore: new MemberStore(),
    notesStore: new NotesStore(),
    stateTestStore: new StateTestStore(),
    groupMasterStore: new GroupMasterStore(),
    policyHeaderStore: new policyHeaderStore(),
    searchMemberStore: new SearchMemberStore(),
    searchPolicyStore: new SearchPolicyStore(),
    searchClaimStore: new SearchClaimStore(),
    fileStore: new FileStore(),
    preSelectStore: new PreSelectStore(),
    campaignStore: new CampaignStore()
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}