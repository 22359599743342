import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import Footer from '../../app/layout/Footer';
import NavSubMenuComponent from '../../app/layout/NavSubMenuComponent';
import { ReactComponent as LogoSvG } from '../../assets/img/svg/logo.svg'
import fileDownload from 'js-file-download'
import { useStore } from '../../app/stores/store';
import { useParams } from 'react-router-dom';
import mime from 'mime-types'
import { AxiosError } from 'axios';

interface DownloadUWStatementParams {
    token: string
}

const DownloadUWStatement = () => {
    const {campaignStore} = useStore();
    const {token} = useParams<DownloadUWStatementParams>();
    const {downloadCampaignStatement} = campaignStore;
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>();
    
    const downloadFile = useCallback( (token: string) => {
        downloadCampaignStatement(token).then( (blob: Blob) => {
            fileDownload(blob, `MemberStatement.${mime.extension(blob.type)}`);
        }).catch((e : AxiosError) => {
            setShowErrorMessage(true)
        })
      },
      [downloadCampaignStatement],
    )

    useEffect(() => {
        downloadFile(token);
    }, [token, downloadFile])

    const handleDownloadFile = () => {
        downloadFile(token);
    } 
    
    return (
        <>
            <div className='d-flex flex-column min-vh-100 justify-content-between'>
                <div>
                    <header className='mb-4'>
                        <Navbar expand="lg">
                            <Container>
                                <Navbar.Brand className='pt-4 pb-4'>
                                    <LogoSvG className='logo' />
                                </Navbar.Brand>
                            </Container>
                        </Navbar>
                        <NavSubMenuComponent />
                    </header>
            
                    <Container>
                        <Row>
                            <Col>
                                { (!showErrorMessage)
                                    ?   <>
                                            <h3>Downloading underwriting statement</h3>
                                            <small>If it doesn't automatically download, please click <a href='#download' onClick={handleDownloadFile}>here</a>.</small>
                                        </>
                                    :   <>
                                            <h3>Unable to download</h3>
                                            <small className='text-muted'>Those members with UW issues can contact Certe's email - <a href={`mailto:corporate.insurance@certe.com.au?subject=Unable%20to%20download%20UW%20statement&body=Link%20Token%3A%20${token}`}>corporate.insurance@certe.com.au</a></small>
                                        </>
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className='mt-5'>
                  <Footer />
                </footer>
            </div>
        </>
    )
}

export default DownloadUWStatement