import { makeAutoObservable } from "mobx";
import {Policy} from "../../models/policy";
import { PolicyHeader } from "../../models/policyHeader";
import agent from "../api/agent";

export default class PolicyStore {
    policies: Policy[] = [];
    policiesfil: Policy[] = [];
    policy: Policy | null = null;
    followUpPolicies: PolicyHeader[] = [];
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    private setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    };

    private setPolicies = (policies: Policy[]) => {
        this.policies = policies;
    };

    setPoliciesfil = (filter: string) => {
        if (filter.length === 0) {
            this.policiesfil = [...this.policies];
        } else {
            this.policiesfil = this.policies.filter((p) =>
                p.groupName.includes(filter)
            );
        }
    };

    private setPolicy = (policy: Policy | null) => {
        this.policy = policy;
    };

    private setFollowUpPolicies  = (policies: PolicyHeader[] | []) => {
        this.followUpPolicies = policies;
    }

    loadPolicies = async (id: string) => {
        this.setPolicy(null);
        this.setLoadingInitial(true);

        try {
            await agent.Policies.list(id).then((data) =>
                this.setPolicies(data)
            );
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };

    getPolicy = async (datasource: string, id: string) => {
        this.setPolicy(null);

        try {
            await agent.Policies.details(datasource, id).then((data) =>
                this.setPolicy(data)
            );
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };

    getFollowUpPolicyList = async () => {
        try {
            await agent.PolicyHeaders.followUpList().then((data: PolicyHeader[]) =>
                this.setFollowUpPolicies(data)
            );
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
}
