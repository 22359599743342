import { Button } from '@progress/kendo-react-buttons';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import { FormikFileDropzone, FormikInput, FormikMemberSelect, FormikPolicyHeaderCheckboxes, FormikSelect } from '../../../app/common/form/FormikForm';
import { useStore } from '../../../app/stores/store';
import * as Yup from 'yup';
import { FileUploadPayload, FileUploadType, PolicyFile } from '../../../models/files';
import { useCallback, useRef, useState } from 'react';
import { ExtToIcon, UserHasPermission } from '../../../app/utils/utils';
import fileDownload from 'js-file-download';
import { AxiosError } from 'axios';
import mime from 'mime-types';
import { Notice, NoticeStates } from '../../../app/stores/noticeStore';
import ConfirmationModal from '../../../app/common/form/ConfirmModal';
import pluralize from 'pluralize';
import { Skeleton } from '@progress/kendo-react-indicators';
import { PolicyHeaderListItem } from '../../../models/subMenu';
import React from 'react';
import {Helmet} from 'react-helmet'

interface PolicyHeaderFileUploadFormProps  {
    policyHeader: PolicyHeaderListItem,
    headerTitle: string,
    headerDescription: React.ReactNode,
    fileUploadType: FileUploadType,
    initialValues: any,
    enablePolicySelection?: boolean,
    enableReviewDateSelection?: boolean,
    policyHeaderOptions?: PolicyHeaderListItem[],
    enableSearchMember?: boolean,
    enableCustomFilename?: boolean,
    memberSearchLabel?: string,
    memberName?: string,
    memberGuid?: string,
    enableFileDownload?: boolean
}

const PolicyHeaderFileUploadForm = ({headerTitle, ...props}: PolicyHeaderFileUploadFormProps) => {
    const {subMenuStore, fileStore, noticeStore, userStore, searchMemberStore } = useStore();
    const {selectedMember, setSelectedMember} = searchMemberStore;
    const {userClaims, hasUserRole} = userStore;
    const {downloadFile, deleteFile} = fileStore;
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<PolicyFile[]|undefined>();

    const [selectedFile, setSelectedFile] = useState<PolicyFile>();
    const [modalShow, setModalShow] = useState(false);
    const [modalBodyMessage, setModalBodyMessage] = useState('Confirm?');

    const formRef = useRef<FormikProps<FileUploadPayload>>(null);
    const policyHeaders = subMenuStore.policyWithSameReviewDate;
    const optionsList = subMenuStore.policyReviewDates;

    const validationScheme =  Yup.object().shape({
        policyHeaderGuids: Yup.array().required().min(1,'Select a policy header'),
        policyHeaderReviewDate: Yup.string().min(10).max(10),
        file: Yup.mixed().required('Select a file'),
        requireMemberGuid: Yup.boolean(),
        memberGuid: Yup.string().when('requireMemberGuid', {
            is: true && !props.memberGuid,
            then: Yup.string().required("Select a member")
          }
        ),
        enableCustomFilename: Yup.boolean(),
        inputFileName: Yup.string().when('enableCustomFilename', {
            is: true,
            then: Yup.string().required("Filename required")
                .trim()
                .max(50, 'Max 50 letters')
                .matches(/^[A-Za-z0-9 _()-]*$/ , 'Supported characters are letters, numbers, spaces, and ( ) _ -')
          }
        )
    })

    const resetForm = useCallback(() => {
        formRef.current?.resetForm();
        formRef.current?.setSubmitting(false);
        formRef.current?.setFieldValue('memberGuid', selectedMember?.memberInfo.id || '')
        setUploadedFiles(undefined);
        setSelectedMember(undefined);
    },[selectedMember?.memberInfo.id, setSelectedMember])
    
    const handleSubmit = (values: FileUploadPayload) => {
        noticeStore.setNotice(null);
        if(props.memberGuid) values.memberGuid=props.memberGuid;
        fileStore.uploadFile(values).then((response) => {
            setSubmitted(true);
            setUploadedFiles(response);
        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to upload file: ` + err.response?.data.message,
                statusCode: err.response?.data.status,
            };

            noticeStore.setNotice(notice);
            fileStore.setUploadProgress(0);
            formRef.current?.setSubmitting(false);
            setSubmitted(false);     
        })
    }

    const handleDownload = (file: PolicyFile) => {
        downloadFile(file.policyHeaderGuid!, file.id!).then((blob) => {
            fileDownload(blob, `${file.title}.${mime.extension(blob.type)}`);
        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to download file. Please try again.`,
                statusCode: 500,
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
        })
    }

    const confirmDelete = (file: PolicyFile) => {
        if(!hasUserRole('PortalAdmin')) return;
        setModalBodyMessage(`Please confirm you like to delete "${file.title}"?`);
        setSelectedFile(file);
        setModalShow(true);
    }

    const handleCancel = () => {
        setModalShow(false);
        setSelectedFile(undefined);
    }

    const handleDelete = () => {
        deleteFile(selectedFile?.policyHeaderGuid!, selectedFile?.id!).then(() => {
            // remove file from list
            const fileList = uploadedFiles?.filter(f => f.id !== selectedFile?.id!);
            setUploadedFiles(fileList);
            setModalShow(false);

            if(fileList?.length === 0) resetForm();

        }).catch((err: AxiosError) => {
            const notice: Notice = {
                noticeState: NoticeStates.error,
                message: `Unable to delete file. Please try again.`,
                statusCode: 500
            };

            noticeStore.setNotice(notice);
            window.scrollTo(0, 0);
        })
    }

    const policyHeaderIdToPolicyNo = (guid: string) => {
        return (props.policyHeader.isCurrent)
                ? policyHeaders?.find(x => x.id === guid)?.policyNumber
                : props.policyHeader.policyNumber
    }

    if(!policyHeaders) {
        return (
            <>
            <Row>
                <Col className='col-10'>
                    <Skeleton shape={"text"} style={{ width: "100%", height: "75px" }} />
                </Col>
            </Row>
            </>
        );
    }
    
    return (
        <>
            <Helmet title={`Upload file: ${headerTitle}`}/>
            <Row>
                <Col className='col-10'>
                    <h3>{headerTitle}</h3>
                    {props.headerDescription}
                </Col>
            </Row>

            <Formik
                key={`formik`}
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={props.initialValues}
                validationSchema={validationScheme}
                onSubmit={handleSubmit}>
                { formProps => {
                return (
                    <Form>
                        <Row>
                            <Col>
                                { props.enablePolicySelection && //headerTitle === 'Member movement data' &&
                                <>
                                    <FormikPolicyHeaderCheckboxes 
                                        disabled={formProps.isSubmitting}
                                        policyHeaders={policyHeaders}
                                        name={'policyHeaderGuids'} 
                                        selectedValues={[props.policyHeader.id]}
                                        label={`Please select the ${pluralize('policy', policyHeaders.length)} that this ${headerTitle.toLowerCase()} will apply to:`} 
                                        />
                                    <ErrorMessage name={'policyHeaderGuids'} render={ msg => 
                                        <small className="text-warning">{msg}</small>
                                    } /> 
                                </> 
                                }

                                { !props.enablePolicySelection &&
                                <>  
                                    <label className='text-bold mb-3' style={{fontWeight: 500}}>
                                    The {headerTitle.toLowerCase()}  you are uploading is for the policy:
                                    </label>    
                                        
                                    <div className='fw-bold mb-2'>
                                        { 
                                            (subMenuStore.policyHeader)
                                                ?  <>{subMenuStore.policyHeader?.groupName} - {subMenuStore.policyHeader?.productType} ({subMenuStore.policyHeader?.policyNumber})<small className='text-muted'></small></>
                                                : <Skeleton shape={"text"} style={{ width: "100%", height: "75px" }} />
                                        }
                                    </div>
                                </>
                                }

                                { props.enableReviewDateSelection &&
                                    <>
                                        <FormikSelect 
                                            id={'review-date-select'}
                                            name={'policyHeaderReviewDate'} 
                                            // value={reviewDate}
                                            options={optionsList}
                                            // onChange={(e: any) => setReviewDate(e.target.value)}
                                            label={`Please select the review date that this ${headerTitle.toLowerCase()} will apply to:`} 
                                            />
                                        <ErrorMessage name={'policyHeaderReviewDates'} render={ msg => 
                                            <small className="text-warning">{msg}</small>
                                        } /> 
                                </>
                                }
                            </Col>
                        </Row>
                                
                        { props.enableSearchMember &&
                        <Row className='pt-3 pb-3'>
                            <Col>
                               <FormikMemberSelect 
                                    label={props.memberSearchLabel}
                                    memberName={props.memberName}
                                    memberGuid={props.memberGuid}
                                    policyHeaderGuid={props.policyHeader.id}
                                    focusOnLoad
                                    suggestDob
                                    suggestDjp
                               />
                            </Col>
                        </Row>
                        }

                        { props.enableCustomFilename &&
                        <Row className='pt-3 pb-3'>
                            <Col>
                                <label className='mb-3'><strong>File display name</strong> (as see by your client)</label>
                               <FormikInput name={'inputFileName'} disabled={submitted && uploadedFiles}/>
                            </Col>
                        </Row>
                        }

                        <Row className='pt-3 pb-3'>
                            <Col>
                                <FormikFileDropzone name='file' disabled={formProps.isSubmitting} /> 
                            </Col>
                        </Row>
                        
                        { submitted && uploadedFiles &&  
                            <>
                                <Row className='mb-2'>
                                    <Col>
                                        <h4>Success, upload complete for {uploadedFiles.length} {pluralize('policy', uploadedFiles.length) }</h4>
                                    </Col>
                                </Row>
                                
                                { uploadedFiles?.map((file: PolicyFile, index) => (
                                    <React.Fragment key={index}>
                                        <Row className='m-0'>
                                            <Col className='p-3 border mb-3 rounded bg-light-yellow'>
                                                <div className='p-1 d-inline-block align-baseline'>
                                                    <i className={`icon ${ExtToIcon(file.ext)} me-3`} />
                                                    <strong>{file.title} </strong> 
                                                    {props.enablePolicySelection &&
                                                        <small className='text-muted'>({policyHeaderIdToPolicyNo(file.policyHeaderGuid)})</small>
                                                    }
                                                </div>
                                                { props.enableFileDownload &&
                                                 <button
                                                    className='k-button k-button-icon k-flat float-end' 
                                                    type='button' 
                                                    onClick={() => handleDownload(file)}> 
                                                    <i className='icon icon-download' /> 
                                                </button> 
                                                }
                                                { UserHasPermission(['PortalAdmin'], userClaims?.role) && file.deletable &&  
                                                    <button
                                                        className='k-button k-button-icon k-flat float-end ms-3' 
                                                        type='button' 
                                                        onClick={() => confirmDelete(file)}> 
                                                        <i className='icon icon-trash' /> 
                                                    </button>
                                                }
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                    ))
                                }

                            <Row>
                                <Col>
                                    <Button type='button' tabIndex={0} onClick={resetForm} className='ps-5 pe-5 upload-button'>UPLOAD MORE FILES</Button>
                                </Col>
                            </Row>
                            </>
                        }

                        { !uploadedFiles &&
                        <Row>
                            <Col>
                                <Button tabIndex={0} type='submit' primary disabled={formProps.isSubmitting} className='ps-5 pe-5 upload-button'>
                                    {(formProps.isSubmitting) ? 'UPLOADING...' : 'UPLOAD'}
                                </Button>
                            </Col>
                        </Row>
                        }

                    </Form>
                )}}
            </Formik>
            
            <ConfirmationModal 
                show={modalShow} 
                headerTitle='Delete file'
                bodyMessage={modalBodyMessage}
                onConfirm={handleDelete}
                onHide={handleCancel}/>
        </>
    )
}

export default observer(PolicyHeaderFileUploadForm)