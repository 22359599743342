import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { PolicySearchResult } from '../../models/policySearch';
import { Skeleton } from '@progress/kendo-react-indicators';

interface PolicySearchDataGridProps {
    results?: PolicySearchResult[],
    sort: SortDescriptor[],
    sortHandler: (e: GridSortChangeEvent) => void
}

const PolicySearchDataGrid = ({results, sort, sortHandler}: PolicySearchDataGridProps) => {
    const history = useHistory();

    const handleRowClick = (event: any) => {
        history.push(`/policy-headers/${event.dataItem.id}`);
    }

    // TODO: Implement columnInterface
    return (
        <>
            <Grid onRowClick={handleRowClick} 
                data={orderBy(results || [], sort) }
                sortable={true}
                sort={sort}
                onSortChange={sortHandler}>
                <Column field="policyNumber" title="Policy" width='150'/>
                <Column field="groupName" title="Group"  width='300'/>
                <Column field="productType" title="Type"/>
                <Column field="insurer" title="Insurer"/>
                <Column field="members" title="Members"/>
                <Column field="premium" title="Premium"  filter="numeric" format="{0:c}" />
                <Column field="reviewDate" title="Renewal" filter="date" format="{0:dd/MM/yyyy}" />
                <Column field="status" title="Status"/>
                <GridNoRecords >
                { (!results) 
                    ?   <div className='text-start'>
                            <Skeleton shape={"text"} style={{ width: "85%", display: 'inline-block' }} className='me-5' />  
                            
                        </div>
                    : 'No results found.' }
                </GridNoRecords>
            </Grid>
        </>
    );
}


export default observer(PolicySearchDataGrid)