import { makeAutoObservable, observable, reaction, runInAction } from "mobx";
import { PolicyDetails, PolicyHeaderCategories, PolicyHeaderDetails, ProductType } from "../../models/policyHeader";
import agent from "../api/agent";


export default class policyHeaderStore {
    policyHeader?: PolicyHeaderDetails;
    policyHeaderList?: PolicyHeaderDetails[];
    policies?: PolicyHeaderCategories;
    defaultPolicy?: PolicyDetails;
    isCurrent: boolean = false;
    isLoading: boolean = false;

    constructor(){
        makeAutoObservable(this,{
            policyHeaderList: observable,
            policyHeader: observable,
            isCurrent: observable,
            defaultPolicy: observable
        });

        reaction( 
            () => this.policyHeader,
            policyHeader => {
                this.setPolicies(policyHeader?.categories);
                this.setDefaultPolicyCategory(policyHeader)
                
            }
        )
    }

    setPolicyHeader = (policyHeader?: PolicyHeaderDetails) => {
        this.policyHeader = policyHeader;
    }

    setPolicyHeaderList = (policyHeaders?: PolicyHeaderDetails[]) => {
        this.policyHeaderList = policyHeaders;
    }

    setPolicies = (policies?: PolicyHeaderCategories) => {
        this.policies = policies;
    }

    setIsLoading = (state: boolean) => {
        // there must be a better way of handling this...
        // delay is to prevent loading state jitter animation.
        // we will remove this if the server response is too slow.
        setTimeout(() => {
            runInAction( () => {
                this.isLoading = state;
            });
        }, (state) ? 0 : 500);
    }

    /**
     * 
     * @param policyHeaderGuid 
     * @returns GroupHeaderPolicy
     */
    getPolicyHeaderDetails = async (policyHeaderGuid: string, startDate?: Date) => {
        return await agent.PolicyHeaders.details(policyHeaderGuid, startDate);
    }

    getFollowUpReminder = async () => {
        return await agent.PolicyHeaders.followUpReminder();
    }

    getFollowUpList = async () => {
        return await agent.PolicyHeaders.followUpList();
    }

    getUpcomingRerates = async () => {
        return await agent.PolicyHeaders.upcomingRerates();
    }

    getInsuredBenefits = async (policyHeaderGuid: string) => {
        return await agent.PolicyHeaders.insuredBenefits(policyHeaderGuid);
    }

    setDefaultPolicyCategory = (policyHeader?: PolicyHeaderDetails) => {
        if(policyHeader){
            if(policyHeader?.policyHeaderInfo.productType === ProductType.GSC){
                this.defaultPolicy = this.policies?.gsc[0]
            }else{
                this.defaultPolicy = ( this.policies && this.policies?.gl.length > 0) ? this.policies?.gl[0] : this.policies?.tpd[0]
            }
        }else{
            this.defaultPolicy = undefined;
        }
    }

    getFiles = async (policyHeaderGuid: string) => {
        return await agent.PolicyHeaders.files(policyHeaderGuid);
    }

    downloadFile = async (policyHeaderGuid: string, fileGuid: string) => {
        return await agent.PolicyHeaders.downloadFile(policyHeaderGuid, fileGuid);
    }

    deleteFile = async (policyHeaderGuid: string, fileGuid: string) => {
        return await agent.PolicyHeaders.deleteFile(policyHeaderGuid, fileGuid);
    }

    downloadAllPoliciesReport = async () => {
        return await agent.DownloadReports.allPoliciesReport();
    }
}