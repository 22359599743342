import { makeAutoObservable } from "mobx";

export default class StateTestStore {
    
    uriParam: string = '';
    details: string = '';
    testList: string[] = [];

    constructor(){
        makeAutoObservable(this);
    }

    setUriParam = (param?: string) => {
        this.uriParam =  'param set to: ' + param;
        this.testList.push( this.uriParam)
    }

    setDetails = (details?: string) => {
        this.details =  'details set to: ' + details;
        this.testList.push(this.details);
    }
}