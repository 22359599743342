import { makeAutoObservable } from "mobx";
import { CampaignMemberItem, CampaignPayload } from "../../models/campaign";
import agent from "../api/agent";
import { isEmailValid } from "../utils/utils";

export interface DataGridCheckboxState {
    [memberId: string]: boolean
}

export default class CampaignStore {
    members?: CampaignMemberItem[];
    dataGridCheckboxState: DataGridCheckboxState = {};
    campaignResults?: CampaignPayload; 

    constructor(){
        makeAutoObservable(this);
    }

    getQualifiedMembers = async (policyHeaderId: string) => { 
        return  agent.Campaign.getQualifiedMembers(policyHeaderId).then( (data: any) => {
            this.members = data;
        });
    }

    getSelectedMembers = () => {
        const membersWithoutEmail = this.members?.filter(x => !isEmailValid(x.email));
        const selectedMembers = Object.entries(this.dataGridCheckboxState).filter(([key, value]) => {
            return (!membersWithoutEmail?.find(x => x.id === key) && value === true)  });
        return selectedMembers;
    }

    selectableMembers = () => {
        return (this.members) ? this.members.filter(x => isEmailValid(x.email)) : [];
    }

    updateDatagridState = (state: DataGridCheckboxState) => {
        this.dataGridCheckboxState = state;
    }

    setCampaignResult = (response?: CampaignPayload) => {
        this.campaignResults = response;
    }
    
    getPolicyHeaderMembers = async (policyHeaderId: string) => {
        return await [];
    }

    sendCampaignEmail = async (policyHeader: string, payload: string[]) => {
        return await agent.Campaign.sendEmail(policyHeader, payload);
    }

    resetCampaign = () => {
        this.members = undefined
        this.dataGridCheckboxState = {};
        this.campaignResults = undefined;
    }

    downloadCampaignStatement = (token: string) => {
        return agent.Campaign.downloadStatement(token);
    }
}