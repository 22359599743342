import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import classNames from "classnames";
import NotesListComponent from "../notes/NotesListComponent";
import { NoteTypes } from "../../models/note";
import PolicyHeaderBar from "./PolicyHeaderBar";
import PolicyAccordion from "./PolicyAccordion";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";
import { IdTypes, SubLinks } from "../../app/stores/subMenuStore";
import { Helmet } from "react-helmet"

interface PolicyHeaderDetailsUrlParams {
    policyHeaderGuid: string
}

function PolicyHeaderDetailsComponent() {
    const {policyHeaderGuid} = useParams<PolicyHeaderDetailsUrlParams>();
    const {userStore, policyHeaderStore, noticeStore, subMenuStore} = useStore();
    const {initPolicyHeader, setActivelink} = subMenuStore
    const history = useHistory();
    const {userClaims} = userStore;

    const { 
            policyHeader, 
            policies, 
            defaultPolicy, 
            isLoading,
            setPolicyHeader, 
            getPolicyHeaderDetails, 
            setIsLoading,
        } = policyHeaderStore;
    
    useEffect(()=>{
        setIsLoading(true);
        setPolicyHeader(undefined);
        initPolicyHeader(IdTypes.policyHeaderId, policyHeaderGuid);
        setActivelink(SubLinks.policy);

        getPolicyHeaderDetails(policyHeaderGuid).then(policyHeader => {
            setPolicyHeader(policyHeader);
            setIsLoading(false);
        }).catch((err: AxiosError) => {
            noticeStore.setNotice(null);
            if(err.response?.status === 400) history.push("/error/not-found");
            if(err.response?.status === 403) history.push("/error/forbidden");
        });

        return () => {
            setPolicyHeader(undefined);
        }
    }, [policyHeaderGuid, setPolicyHeader, getPolicyHeaderDetails, setIsLoading, noticeStore, history, initPolicyHeader, setActivelink]);

    const isPortalAdmin = userClaims?.role.includes('PortalAdmin');

    return (
        <>
            <Helmet title={`Policy - ${subMenuStore.group?.name} ${subMenuStore.policyHeader?.insurer} ${subMenuStore.policyHeader?.productType} ${subMenuStore.policyHeader?.policyNumber}`}/>
            <Row>
                <Col >
                    <h1 className='header-title'>Policy</h1>
                </Col>
            </Row>
            <Row className='gx-5 mt-4'>
                <Col className={classNames({
                                        'col-12': !isPortalAdmin,
                                        'col-8': isPortalAdmin
                                    })}>
                                
                    <PolicyHeaderBar policyHeader={policyHeader}  />
                    <PolicyAccordion policies={policies} defaultPolicy={defaultPolicy} isLoading={isLoading} />
                </Col>
                { isPortalAdmin &&
                <Col className={'col-4'}>    
                    <NotesListComponent 
                        listTitle={'Policy notes'} 
                        notesType={NoteTypes.PolicyHeader} 
                        notesOf={policyHeaderGuid} />
                </Col>
                }
            </Row>
        </>
    );
}

export default observer(PolicyHeaderDetailsComponent);
