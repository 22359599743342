import React from 'react'
import { Alert, AlertProps } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface Props {
    message: string,
    link: string
}

const AlertComponent = ( {variant, message, link, ...props}: Props & AlertProps) => {
    return (
        <Alert variant={variant} className='d-inline-flex'>
            <Link to={link} className='alert-link align-items-center'>{message} <i className="k-icon k-i-arrow-chevron-right"></i></Link>
        </Alert>
    )
}

export default AlertComponent 