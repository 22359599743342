import { Skeleton } from '@progress/kendo-react-indicators'
import { Col, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { FormatDate } from '../../app/utils/utils'
import { CoverType, PolicyDetails } from '../../models/policyHeader'

interface PolicyCategoryDetailsProps {
    policy?: PolicyDetails
}

const PolicyCategoryDetails = ({policy}: PolicyCategoryDetailsProps) => {
    
    return (
        <>
            <Row className='border-bottom border-top-0'>
                <Col className='col-4 p-4 border-end'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                    <>
                        <small className='text-muted'>Type</small>
                        <div>{policy?.coverType}</div>
                    </>
                    }
                </Col>
                <Col className='col-8 p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "50%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>AAL</small>
                            <div>
                                <NumberFormat  
                                    value={policy?.aal}  
                                    thousandSeparator={true} 
                                    displayType={'text'}
                                    prefix={'$'} />
                            </div>
                        </>
                    }
                </Col>
            </Row>
            <Row className='border-bottom'>
                <Col className='p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Eligible members</small>
                            <div>{policy?.eligibleMembers}</div>
                        </>
                    }
                </Col>
            </Row>
            <Row className='border-bottom'>
                <Col className='p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Benefit design</small>
                            <div>{policy?.benefitDesign}</div>
                        </>
                    }
                    
                </Col>
            </Row>
            <Row className='border-bottom'>
                <Col className='p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Income definition</small>
                            <div>{policy?.incomeDefinition}</div>
                        </>
                    }
                </Col>
            </Row>
            <Row className='border-bottom'>
                <Col className='p-3 border-end'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                    <>
                        <small className='text-muted'>Maximum benefit</small>
                        <div>
                            
                            { (policy?.coverType === CoverType.GL) && 
                                (policy?.maxBenefit <= 0) && 
                                <div>Unlimited</div>
                                
                            }

                            { (policy?.coverType !== CoverType.GL) && 
                                <NumberFormat  
                                value={policy?.maxBenefit}  
                                thousandSeparator={true} 
                                displayType={'text'}
                                prefix={'$'} />                                
                            }
                        
                        </div>
                    </>
                    }
                </Col>
                <Col className='p-3 border-end'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "30%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Expiry age</small>
                            <div>{policy?.expiryAge}</div>
                        </>
                    }
                </Col>
                <Col className='p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "30%" }} /> 
                    }
                    { policy &&
                    <>
                        <small className='text-muted'>Rate expiry</small>
                        <div>{ FormatDate(policy?.rateExpiry) }</div>
                    </>
                    }
                    
                </Col>
            </Row>
            { policy?.coverType === CoverType.GSC && 
            <Row className='border-bottom-0'>
                <Col className='col-4 p-3  border-end'>
                    
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "50%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Waiting period</small>
                            <div>{policy.waitingPeriod}</div>
                        </>
                    }
                </Col>
                <Col className='col-8 p-3 '>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "50%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>Benefit period</small>
                            <div>{policy.benefitPeriod}</div>
                        </>
                    }
                    
                </Col>
            </Row>
            }
            { policy?.coverType === CoverType.TPD && 
            <Row className='border-bottom-0'>
                <Col className='col-4 p-3'>
                    { !policy && 
                        <Skeleton shape={"text"} style={{ width: "80%" }} /> 
                    }
                    { policy &&
                        <>
                            <small className='text-muted'>TPD definition</small>
                            <div>{policy.tpdDefinition}</div>
                        </>
                    }
                </Col>
            </Row>
            }
        </>
    )
}

export default PolicyCategoryDetails